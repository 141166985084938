import {Component, input, model} from '@angular/core';
import {PalletType} from '@model/pallet-type';
import {NgIf} from "@angular/common";
import {LanguageDirective} from "@directives/language.directive";
import {UnsupportedLanguageDirective} from "@directives/language.unsupported.directive";

@Component({
  selector: 'foodbank-pallet-type',
  templateUrl: './pallet-type.component.html',
  styleUrls: ['./pallet-type.component.scss'],
  imports: [
    NgIf,
    LanguageDirective,
    UnsupportedLanguageDirective
  ]
})
export class PalletTypeComponent {

  ngModel = model<PalletType>();
  showId = input(true);

}
