import {Component, computed, inject, model, Signal} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {Donation} from '@model/donation';
import {DonationService} from '@services/donation.service';
import { NgIf} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {InputTextModule} from 'primeng/inputtext';
import {InputSwitchModule} from 'primeng/inputswitch';
import {Button} from 'primeng/button';
import {MessageService} from "primeng/api";
import {MessageModule} from "primeng/message";
import {DropdownModule} from "primeng/dropdown";
import {DonorSelectionComponent} from "@components/donor/selection/donor-selection.component";
import {DonorSearch} from "@model/search/donor-search";
import {UserService} from "@services/user.service";


@Component({
  selector: 'foodbank-donation-edit',
  templateUrl: './donation-edit.component.html',
  styleUrls: ['./donation-edit.component.scss'],
  imports: [NgIf, FormsModule, InputTextModule, InputSwitchModule, Button, MessageModule, DropdownModule, DonorSelectionComponent]
})
export class DonationEditComponent {

  donation = model<Donation>();

  ref = inject(DynamicDialogRef);
  config = inject(DynamicDialogConfig<Donation>);
  #donationService = inject(DonationService);
  #messageService = inject(MessageService);
  #userService = inject(UserService);

  donorSearch: Signal<DonorSearch>;

  constructor() {
    this.donation.set(this.config.data);
    console.log( "editing donation:", this.donation());
    const currentUserCompany = this.#userService.getCurrentUserCompany();
    this.donorSearch = computed(() => ({
      company: currentUserCompany(),
    }));
  }

  save(donation: Donation) {


    this.#donationService.updateDonation(donation)
      .subscribe(donation => {
        this.donation.set(donation);
        this.ref.close(donation);
        this.#messageService.add({severity: 'success', summary: 'Success', detail:  `Donation for ${donation.donor.value()?.familyName} ${donation.donor.value()?.firstName} updated`});
      });
  }

  close() {
    this.ref.close({});
  }


}

