import {Component, inject, input, model} from '@angular/core';
import {LanguageService} from '@services/language.service';
import {Donor} from '@model/donor';
import {NgIf} from "@angular/common";

@Component({
  selector: 'foodbank-donor',
  templateUrl: './donor.component.html',
  styleUrls: ['./donor.component.scss'],
  imports: [
    NgIf
  ]
})
export class DonorComponent {

  ngModel = model<Donor>();
  showId = input(true);

  private languageService = inject(LanguageService);

  constructor() {
  }

}
