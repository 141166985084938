import {ChangeDetectionStrategy, Component, computed, effect, inject, Injector, linkedSignal, model, signal, Signal} from '@angular/core';
import {DEFAULT_ROWS_PER_PAGE} from '@services/pagination.service';
import {TransferRequestService} from "@services/transfer-request.service";
import {TransferRequest} from "@model/transfer-request";
import {derivedAsync} from "ngxtension/derived-async";
import {of} from "rxjs";
import {TransferRequestTargetListComponent} from "@components/transfer/request/request-target/transfer-request-target-list.component";
import {PanelModule} from "primeng/panel";
import {TransferRequestItemListComponent} from "@components/transfer/request/item/transfer-request-item-list.component";
import {TransferRequestTargetService} from "@services/transfer-request-target.service";
import {DragDropModule} from "primeng/dragdrop";
import {TabViewModule} from "primeng/tabview";
import {StockGroupListComponent} from "@components/stock/group/stock-group-list.component";
import {StockGroup} from "@model/stock-group";
import {StockGroupSearch} from "@model/search/stock-group-search";
import {Warehouse} from "@model/warehouse";
import {TooltipModule} from "primeng/tooltip";
import {Button} from "primeng/button";
import {InputSwitchModule} from "primeng/inputswitch";
import {InputTextModule} from "primeng/inputtext";
import {PaginatorModule} from "primeng/paginator";
import {UserComponent} from "@components/users/user.component";
import {CompanyComponent} from "@components/company/company.component";
import {CheckboxModule} from "primeng/checkbox";
import {WarehouseComponent} from "@components/warehouse/warehouse.component";
import {CalendarModule} from "primeng/calendar";
import {rxResource} from "@angular/core/rxjs-interop";
import {ParenthesizePipe} from "@util/parenthesize-pipe";

@Component({
  selector: 'foodbank-transfer-request-edit',
  templateUrl: './transfer-request-edit.component.html',
  imports: [
    TransferRequestTargetListComponent,
    PanelModule,
    TransferRequestItemListComponent,
    DragDropModule,
    TabViewModule,
    StockGroupListComponent,
    TooltipModule,
    Button,
    InputSwitchModule,
    InputTextModule,
    PaginatorModule,
    UserComponent,
    CompanyComponent,
    CheckboxModule,
    WarehouseComponent,
    CalendarModule,
    ParenthesizePipe
  ],
  styleUrls: ['./transfer-request-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TransferRequestEditComponent {

  protected readonly DEFAULT_ROWS_PER_PAGE = DEFAULT_ROWS_PER_PAGE;

  ngModel = model.required<TransferRequest>();
  draft = computed(() => this.ngModel().id === undefined);

  // nice counters to display on tabs (so it's easy to visualize transfers)
  targetWarehouseCount: Signal<number | undefined>;
  transferRequestItemCount = signal<number | undefined>(undefined);
  stockGroupCount = signal<number | undefined>(undefined);

  defaultActiveTabIndex = computed(() => this.draft() ? 0 : 3);

  activeTabIndex = linkedSignal(() => this.defaultActiveTabIndex());

  #draggedStockGroups = signal<StockGroup[]>([]);

  stockGroupSearch: Signal<StockGroupSearch>;
  #transferRequestService = inject(TransferRequestService);
  #transferRequestTargetService = inject(TransferRequestTargetService);
  #injector = inject(Injector);

  constructor() {
    // count target warehouses to display in caption of warehouse collapsable panel
    const transferRequestTargetPageForCount = derivedAsync(() => !this.ngModel() ? of() : this.#transferRequestTargetService.findTransferRequestTargets$({transferRequest: this.ngModel()}, {
      page: 0,
      size: 0
    }, this.#injector));
    this.targetWarehouseCount = computed(() => transferRequestTargetPageForCount()?.numberOfElements);

    this.stockGroupSearch = computed(() => {
      return this.#getStockGroupSearch();
    })
  }

  #getStockGroupSearch(): StockGroupSearch {
    const transferRequest = this.ngModel();
    const selectedWarehouse = transferRequest?.warehouse.value();
    const warehouses: Warehouse[] = !selectedWarehouse ? [] : [selectedWarehouse];
    return ({
      stockSearch: {
        warehouseSearch: {
          warehouses: warehouses,
        }
      },
      excludedTransferRequestSearch: {
        exactTransferRequest: transferRequest,
      }
    });
  }

  removeSelectedTransferRequestItems() {
  }

  createTransferRequestItemsFromDraggedStock() {
    const draggedStockGroups = this.#draggedStockGroups();
    const transferRequestAfterStockCreationResourceRef = rxResource({
      loader: () => {
        if (draggedStockGroups.length === 0) {
          return of(undefined);
        }
        return this.#transferRequestService.createTransferRequestItemsFromStockGroups(this.ngModel(), draggedStockGroups);
      },
      injector: this.#injector,
    });
    effect(() => {
      const transferRequestAfterStockCreation = transferRequestAfterStockCreationResourceRef.value();
      if (transferRequestAfterStockCreation) {
        // set new TransferRequest instance so all tabs get refreshed
        this.ngModel.set(transferRequestAfterStockCreation);
      }
    }, {
      injector: this.#injector,
    })
  }

  startStockGroupDrag(stockGroups: StockGroup[]) {
    this.#draggedStockGroups.set(stockGroups);
  }

  endStockGroupDrag() {
    this.#draggedStockGroups.set([]);
  }

  save(transferRequest: TransferRequest) {
    const savedTransferRequestResourceRef = rxResource({
      request: () => transferRequest,
      loader: () => {
        return this.#transferRequestService.saveTransferRequest$(transferRequest, this.#injector);
      },
      injector: this.#injector,
    });
    effect(() => {
      const savedTransferRequest = savedTransferRequestResourceRef.value();
      if (savedTransferRequest) {
        this.ngModel.set(savedTransferRequest);
      }
    }, {injector: this.#injector})
  }

  cancel() {

  }

}
