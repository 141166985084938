@let reception = ngModel();
@if (reception) {
  <i class="fa-solid fa-walkie-talkie"></i>
  @if (showId()) {
    #{{ reception.id }}
  }
  <small>
    {{ reception.date | date: 'dd/MM/yyyy' }}
  </small>
}
