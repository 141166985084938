@let palletTypes = palletTypePage.value()?.content ?? [];
<p-dropdown *foodbankLanguage="'fr'" [(ngModel)]="ngModel"
            [options]="palletTypes"
            optionLabel="id"
            [placeholder]="palletTypePage.isLoading() ? 'Chargement...' : 'None'"
            [filter]="true"
            filterMatchMode="contains"
            filterBy="id,nameFr"
            (onClear)="clear()"
            [showClear]="true"
            [disabled]="disabled()"
            dataKey="id"
            appendTo="body"
            class="w-12">
  <ng-template pTemplate="selectedItem">
    <foodbank-pallet-type ngDefaultControl [ngModel]="ngModel()"/>
  </ng-template>
  <ng-template let-palletType pTemplate="item">
    <foodbank-pallet-type ngDefaultControl [ngModel]="palletType"/>
  </ng-template>
</p-dropdown>
<p-dropdown *foodbankLanguage="'nl'" [(ngModel)]="ngModel"
            [options]="palletTypes"
            optionLabel="id"
            [placeholder]="palletTypePage.isLoading() ? 'Bezig met laden...' : 'None'"
            [filter]="true"
            filterMatchMode="contains"
            filterBy="id,nameNl"
            (onClear)="clear()"
            [showClear]="true"
            [disabled]="disabled()"
            dataKey="id"
            appendTo="body"
            class="w-12">
  <ng-template pTemplate="selectedItem">
    <ng-container *ngIf="ngModel">
      <foodbank-pallet-type ngDefaultControl [ngModel]="ngModel()"/>
    </ng-container>
  </ng-template>
  <ng-template let-palletType pTemplate="item">
    <foodbank-pallet-type ngDefaultControl [ngModel]="palletType"/>
  </ng-template>
</p-dropdown>
