@let transferRequest = ngModel();
@if (transferRequest) {
  <p-tabView [(activeIndex)]="activeTabIndex">

    <p-tabPanel>
      <ng-template pTemplate="header">
        <span class="fa-solid fa-truck-fast tab-icon mr-1"></span>Transfer request
      </ng-template>
      <div class="card p-fluid">
        <div class="field grid">
          <label for="id" class="col-12 mb-2 md:col-2 md:mb-0" i18n>#</label>
          @if (!draft()) {
            <div class="col-12 md:col-1">
              <input id="id" type="text" pInputText [(ngModel)]="transferRequest.id" [disabled]="true">
            </div>
          } @else {
            <div class="col-12 md:col-2">
              <em>(new transfer request)</em>
            </div>
          }
        </div>
        <div class="field grid">
          <label for="closed" class="col-12 mb-2 md:col-2 md:mb-0" i18n>Closed</label>
          <div class="col-12 md:col-10">
            <p-checkbox inputId="closed" [ngModel]="transferRequest.closed"/>
          </div>
        </div>
        <div class="field grid">
          <span class="col-12 mb-2 md:col-2 md:mb-0" i18n>Company</span>
          <div class="col-12 md:col-10">
            <foodbank-company ngDefaultControl [ngModel]="transferRequest.company.value()"/>
          </div>
        </div>
        <div class="field grid">
          <span class="col-12 mb-2 md:col-2 md:mb-0" i18n>User</span>
          <div class="col-12 md:col-10">
            <foodbank-user ngDefaultControl [ngModel]="transferRequest.user.value()" [showId]="true"/>
          </div>
        </div>
        <div class="field grid">
          <span class="col-12 mb-2 md:col-2 md:mb-0" i18n>Source warehouse</span>
          <div class="col-12 md:col-10">
            <foodbank-warehouse inputId="warehouse" ngDefaultControl [ngModel]="transferRequest.warehouse.value()" [showId]="true"/>
          </div>
        </div>
        <div class="field grid">
          <label for="comment" class="col-12 mb-2 md:col-2 md:mb-0" i18n>Comment</label>
          <div class="col-12 md:col-10">
            <input id="comment" type="text" pInputText [(ngModel)]="transferRequest.comment">
          </div>
        </div>
        <div class="field grid">
          <label for="date" class="col-12 mb-2 md:col-2 md:mb-0" i18n>Date</label>
          <div class="col-12 md:col-1">
            <p-calendar inputId="date"
                        type="text"
                        pInputText
                        [(ngModel)]="transferRequest.date"
                        [showButtonBar]="true"
            />
          </div>
        </div>
        <div class="field grid">
          <div class="col-offset-2 col-1">
            <p-button label="Save" icon="pi pi-check" styleClass="p-button-primary" (onClick)="save(transferRequest)"/>
          </div>
        </div>
      </div>
    </p-tabPanel>

    @if (!draft()) {
      <p-tabPanel>
        <ng-template pTemplate="header">
        <span pDroppable (onDrop)="removeSelectedTransferRequestItems()">
          <span class="fa-solid fa-warehouse tab-icon mr-1"></span>
          <span class="mr-1">Warehouses {{ targetWarehouseCount() | parenthesize }}</span>
        </span>
        </ng-template>
        <foodbank-transfer-request-target-list [transferRequest]="transferRequest"/>
      </p-tabPanel>
    }

    @if (!draft()) {
      <p-tabPanel>
        <ng-template pTemplate="header">
        <span pDroppable (onDrop)="removeSelectedTransferRequestItems()" pTooltip="Stock groups">
          <span class="fa-solid fa-boxes-stacked tab-icon mr-1"></span>
          <span class="mr-1">Stock {{ stockGroupCount() | parenthesize }}</span>
        </span>
        </ng-template>
        @let sourceWarehouse = transferRequest.warehouse.value();
        @if (sourceWarehouse) {
          <foodbank-stock-group-list
            [stockGroupSearch]="stockGroupSearch()"
            [embedded]="true"
            [unrestrictedCompany]="true"
            (onStockGroupDragStart)="startStockGroupDrag($event)"
            (onStockGroupDragEnd)="endStockGroupDrag()"
            (stockGroupCount)="stockGroupCount.set($event)"
          />
        }
      </p-tabPanel>
    }

    @if (!draft()) {
      <p-tabPanel>
        <ng-template pTemplate="header">
        <span pDroppable (onDrop)="createTransferRequestItemsFromDraggedStock()">
          <span class="fa-solid fa-truck-fast tab-icon mr-1"></span>
          <span class="mr-1">Transfer request items {{ transferRequestItemCount() | parenthesize }}</span>
        </span>
        </ng-template>

        <foodbank-transfer-request-item-list [(transferRequest)]="ngModel" (transferRequestItemCount)="transferRequestItemCount.set($event)"/>
      </p-tabPanel>
    }
  </p-tabView>
}
