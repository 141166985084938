<div class="card p-fluid" *ngIf="member() as member">
  <form #memberForm="ngForm" style="padding: 1em">
    <div class="field grid">
      <label for="member-familyName" class="col-12 mb-2 md:col-2 md:mb-0" i18n> FamilyName</label>
      <div class="col-12 md:col-10">
        <input id="member-familyName" name="familyName" type="text" pInputText [(ngModel)]="member.familyName" ngDefaultControl>
      </div>
    </div>
    <div class="field grid">
      <label for="member-firstName" class="col-12 mb-2 md:col-2 md:mb-0" i18n>First Name</label>
      <div class="col-12 md:col-10">
        <input id="member-firstName" name="firstName" type="text" pInputText [(ngModel)]="member.firstName" ngDefaultControl>
      </div>
    </div>
    <div class="field grid">
      <label for="organization" class="col-12 mb-2 md:col-2 md:mb-0" i18n>Organization</label>
      <div class="col-12 md:col-10">
        <foodbank-organization-selection name="organization" ngDefaultControl id="organization" [(ngModel)]="member.organization.value"/>
      </div>
    </div>
    <div class="field grid">
      <label for="warehouse" class="col-12 mb-2 md:col-2 md:mb-0" i18n>Warehouse</label>
      <div class="col-12 md:col-10">
        <foodbank-warehouse-selection name="warehouse" ngDefaultControl id="warehouse"
                                      [(ngModel)]="member.warehouse.value"
                                      *ngIf="warehouseSearch$ | async as warehouseSearch"
                                      [warehouseSearch]="warehouseSearch"
        />
      </div>
    </div>
    <div class="field grid">
      <label for="role" class="col-12 mb-2 md:col-2 md:mb-0" i18n>Role</label>
      <div class="col-12 md:col-10">
        <foodbank-role-selection name="role" ngDefaultControl id="role" [(ngModel)]="member.role.value"/>
      </div>
    </div>
    <div class="field grid">
      <label for="member-address" class="col-12 mb-2 md:col-2 md:mb-0" i18n>Address</label>
      <div class="col-12 md:col-10">
        <input id="member-address" type="text" name="address" pInputText [(ngModel)]="member.address" ngDefaultControl>
      </div>
    </div>
    <div class="field grid">
      <label for="member-zip" class="col-12 mb-2 md:col-2 md:mb-0" i18n>Zip</label>
      <div class="col-12 md:col-10">
        <input id="member-zip" type="text" name="zip" pInputText [(ngModel)]="member.zip" ngDefaultControl>
      </div>
    </div>
    <div class="field grid">
      <label for="member-city" class="col-12 mb-2 md:col-2 md:mb-0" i18n>City</label>
      <div class="col-12 md:col-10">
        <input id="member-city" type="text" name="city" pInputText [(ngModel)]="member.city" ngDefaultControl>
      </div>
    </div>
    <div class="field grid">
      <label for="member-email" class="col-12 mb-2 md:col-2 md:mb-0" i18n>Email</label>
      <div class="col-12 md:col-10">
        <input id="member-email" type="text" name="email" pInputText [(ngModel)]="member.email" ngDefaultControl>
      </div>
    </div>
    <div class="field grid">
      <label for="member-phone" class="col-12 mb-2 md:col-2 md:mb-0" i18n>Phone</label>
      <div class="col-12 md:col-10">
        <input id="member-phone" type="text" pInputText name="phone" [(ngModel)]="member.phone" ngDefaultControl>
      </div>
    </div>
    <div class="field grid">
      <label for="member-mobile" class="col-12 mb-2 md:col-2 md:mb-0" i18n>Mobile</label>
      <div class="col-12 md:col-10">
        <input id="member-mobile" type="text" pInputText name="mobile" [(ngModel)]="member.mobile" ngDefaultControl>
      </div>
    </div>
    <div class="field grid">
      <label i18n="@@Gender" for="civilite">Gender</label>
      <div class="col-12 md:col-10">
        <p-dropdown [options]="genders" id="civilite" name="civilite" [(ngModel)]="member.civilite"></p-dropdown>
      </div>
    </div>
    <div class="field grid">
      <label for="member-language" class="col-12 mb-2 md:col-2 md:mb-0" i18n>Language</label>
      <div class="col-12 md:col-10">
        <p-dropdown [options]="languages" id="member-language" optionLabel="language" optionValue="language" name="language" [(ngModel)]="selectedLanguage" ngDefaultControl></p-dropdown>
      </div>
    </div>
    <div class="field grid">
      <label for="member-remarks" class="col-12 mb-2 md:col-2 md:mb-0" i18n>Remarks</label>
      <div class="col-12 md:col-10">
        <input id="member-remarks" type="text" pInputText name="remarks" [(ngModel)]="member.remarks" ngDefaultControl>
      </div>
    </div>
    <div class="field grid">
      <label for="member-nnat" class="col-12 mb-2 md:col-2 md:mb-0" i18n>National Number</label>
      <div class="col-12 md:col-10">
        <input id="member-nnat" type="text" pInputText name="nnat" [(ngModel)]="member.nnat" ngDefaultControl>
      </div>
    </div>
    <div class="field grid">
      <label i18n="@@MemberBirthDate" for="birthDate" class="col-12 mb-2 md:col-2 md:mb-0">Birth Date</label>
      <div class="col-12 md:col-10">
        <input #birthDate="ngModel" pInputText id="birthDate" name="birthDate" pattern="^(0?[1-9]|[12][0-9]|3[01])[\/](0?[1-9]|1[012])[\/\-]\d{4}$" placeholder="dd/mm/yyyy" [(ngModel)]="member.birthDate"/>
        <p-message i18n-text="@@BirthDateMustBeValid" *ngIf="birthDate.invalid && memberForm.touched" severity="error" text="Birth date must in format dd/mm/yyyy"></p-message>
      </div>
    </div>
    <div class="field grid">
      <label for="member-veh" class="col-12 mb-2 md:col-2 md:mb-0" i18n>Vehicle</label>
      <div class="col-12 md:col-10">
        <input id="member-veh" type="text" pInputText name="veh" [(ngModel)]="member.veh" ngDefaultControl>
      </div>
    </div>
    <div class="field grid">
      <label for="member-vehPlate" class="col-12 mb-2 md:col-2 md:mb-0" i18n>Vehicle Plate</label>
      <div class="col-12 md:col-10">
        <input id="member-vehPlate" type="text" name="vehPlate" pInputText [(ngModel)]="member.vehPlate" ngDefaultControl>
      </div>
    </div>
    <div class="field grid">
      <label for="member-vehType" class="col-12 mb-2 md:col-2 md:mb-0" i18n>Vehicle Type</label>
      <div class="col-12 md:col-10">
        <input id="member-vehType" type="text" pInputText name="vehType" [(ngModel)]="member.vehType" ngDefaultControl>
      </div>
    </div>
    <div class="field grid">
      <label i18n="@@MgtComittee" for="ca" class="col-12 mb-2 md:col-2 md:mb-0">Mgt Comittee</label>
      <div class="col-12 md:col-10">
        <p-inputSwitch id="ca" name="ca" [(ngModel)]="member.ca"></p-inputSwitch>
      </div>
    </div>
    <div class="field grid">
      <label i18n="@@GenAssembly" for="ag" class="col-12 mb-2 md:col-2 md:mb-0">Gen Assembly</label>
      <div class="col-12 md:col-10">
        <p-inputSwitch id="ag" name="ag" [(ngModel)]="member.ag"></p-inputSwitch>
      </div>
    </div>
    <div class="field grid">
      <label i18n="@@SteeringComittee" for="cg" class="col-12 mb-2 md:col-2 md:mb-0">Steering Comittee</label>
      <div class="col-12 md:col-10">
        <p-inputSwitch id="cg" name="cg" [(ngModel)]="member.cg"></p-inputSwitch>
      </div>
    </div>
    <div class="field grid">
      <label i18n="@@Active" for="active" class="col-12 mb-2 md:col-2 md:mb-0">Active</label>
      <div class="col-12 md:col-10">
        <p-inputSwitch id="active" name="active" [(ngModel)]="member.active"></p-inputSwitch>
      </div>
    </div>
    <div class="field grid">
      <label i18n="@@MemberStartDate" for="startDate" class="col-12 mb-2 md:col-2 md:mb-0">Start Date</label>
      <div class="col-12 md:col-10">
        <input #startDate="ngModel" pInputText id="startDate" name="startDate" pattern="^(0?[1-9]|[12][0-9]|3[01])[\/](0?[1-9]|1[012])[\/\-]\d{4}$" placeholder="dd/mm/yyyy" [(ngModel)]="member.startDate"/>
        <p-message i18n-text="@@startDateInvalid" *ngIf="startDate.invalid && memberForm.touched" severity="error" text="Start Date must in format dd/mm/yyyy"></p-message>
      </div>
    </div>
    <div class="field grid">
      <label i18n="@@MemberEndDate" for="endDate" class="col-12 mb-2 md:col-2 md:mb-0">End Date</label>
      <div class="col-12 md:col-10">
        <input #datfin="ngModel" pInputText id="endDate" name="endDate" pattern="^(0?[1-9]|[12][0-9]|3[01])[\/](0?[1-9]|1[012])[\/\-]\d{4}$" placeholder="dd/mm/yyyy" [(ngModel)]="member.endDate"/>
        <p-message i18n-text="@@EndDateInvalid" *ngIf="datfin.invalid && memberForm.touched" severity="error" text="End Date must in format dd/mm/yyyy"></p-message>
      </div>
    </div>

    <div class="field grid">
      <div class="col-offset-3 col-3">
        <p-button label="Save" icon="pi pi-check" styleClass="p-button-primary" (onClick)="save(member)"/>
      </div>
      <div class="col-3">
        <p-button label="Cancel" icon="pi pi-times" styleClass="p-button-secondary" (onClick)="close()"/>
      </div>
    </div>
  </form>
</div>

