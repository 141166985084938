<div class="card p-fluid" *ngIf="organization() as organization">
  <form #organizationForm="ngForm" style="padding: 1em">
    <p-tabView>
      <p-tabPanel i18n="@@CompanyInfo" header="Company Info">
        <div class="field grid">
          <label for="organization-name" class="col-12 mb-2 md:col-2 md:mb-0"> Name</label>
          <div class="col-12 md:col-10">
            <input id="organization-name" type="text" name="name" pInputText [(ngModel)]="organization.name" ngDefaultControl>
          </div>
        </div>
        <div class="field grid">
          <label for="organization-address" class="col-12 mb-2 md:col-2 md:mb-0">Address</label>
          <div class="col-12 md:col-10">
            <input id="organization-address" type="text" name="address" pInputText [(ngModel)]="organization.address" ngDefaultControl>
          </div>
        </div>
        <div class="field grid">
          <label for="organization-zip" class="col-12 mb-2 md:col-2 md:mb-0">Zip</label>
          <div class="col-12 md:col-10">
            <input id="organization-zip" type="text" pInputText name="zip" [(ngModel)]="organization.zip" ngDefaultControl>
          </div>
        </div>
        <div class="field grid">
          <label for="organization-city" class="col-12 mb-2 md:col-2 md:mb-0">City</label>
          <div class="col-12 md:col-10">
            <input id="organization-city" type="text" pInputText name="city" [(ngModel)]="organization.city" ngDefaultControl>
          </div>
        </div>
        <div class="field grid">
          <label for="organization-email" class="col-12 mb-2 md:col-2 md:mb-0">Email</label>
          <div class="col-12 md:col-10">
            <input id="organization-email" type="text" pInputText name="email" [(ngModel)]="organization.email" ngDefaultControl>
          </div>
        </div>
        <div class="field grid">
          <label for="organization-phone" class="col-12 mb-2 md:col-2 md:mb-0">Phone</label>
          <div class="col-12 md:col-10">
            <input id="organization-phone" type="text" pInputText name="phone" [(ngModel)]="organization.phone" ngDefaultControl>
          </div>
        </div>
        <div class="field grid">
          <label for="organization-mobile" class="col-12 mb-2 md:col-2 md:mb-0">Mobile</label>
          <div class="col-12 md:col-10">
            <input id="organization-mobile" type="text" pInputText name="mobile" [(ngModel)]="organization.mobile" ngDefaultControl>
          </div>
        </div>
        <div class="field grid">
          <label for="organization-vatnumber" class="col-12 mb-2 md:col-2 md:mb-0">VAT Number</label>
          <div class="col-12 md:col-10">
            <input id="organization-vatnumber" type="text" pInputText name="vatnumber" [(ngModel)]="organization.vatNumber" ngDefaultControl>
          </div>
        </div>
        <div class="field grid">
          <label for="organization-iban" class="col-12 mb-2 md:col-2 md:mb-0">IBAN</label>
          <div class="col-12 md:col-10">
            <input id="organization-iban" type="text" pInputText name="iban" [(ngModel)]="organization.iban" ngDefaultControl>
          </div>
        </div>
        <div class="field grid">
          <label for="organization-bic" class="col-12 mb-2 md:col-2 md:mb-0">BIC</label>
          <div class="col-12 md:col-10">
            <input id="organization-bic" type="text" pInputText name="bic" [(ngModel)]="organization.bic" ngDefaultControl>
          </div>
        </div>
        <div class="field grid">
          <label for="organization-website" class="col-12 mb-2 md:col-2 md:mb-0">Website</label>
          <div class="col-12 md:col-10">
            <input id="organization-website" type="text" pInputText name="website" [(ngModel)]="organization.website" ngDefaultControl>
          </div>
        </div>
      </p-tabPanel>
      <p-tabPanel i18n="@@Management" header="Management">
        <div class="field grid">
          <label for="presidentTitle" class="col-12 mb-2 md:col-2 md:mb-0">President Gender</label>
          <div class="col-12 md:col-10">
            <p-dropdown [options]="genders" id="presidentTitle" name="presidentTitle" [(ngModel)]="organization.presidentTitle"></p-dropdown>
          </div>
        </div>
        <div class="field grid">
          <label for="presidentFirstName" class="col-12 mb-2 md:col-2 md:mb-0">President First Name</label>
          <div class="col-12 md-10">
            <input id="presidentFirstName" type="text" pInputText name="presidentFirstName" [(ngModel)]="organization.presidentFirstName" ngDefaultControl>
          </div>
        </div>
        <div class="field grid">
          <label for="presidentFamilyName" class="col-12 mb-2 md:col-2 md:mb-0">President Family Name</label>
          <div class="col-12 md-10">
            <input id="presidentFamilyName" type="text" pInputText name="presidentFamilyName" [(ngModel)]="organization.presidentFamilyName" ngDefaultControl>
          </div>
        </div>
        <div class="field grid">
          <label for="presidentEmail" class="col-12 mb-2 md:col-2 md:mb-0">President Email</label>
          <div class="col-12 md-10">
            <input id="presidentEmail" type="text" pInputText name="presidentEmail" [(ngModel)]="organization.presidentEmail" ngDefaultControl>
          </div>
        </div>
        <div class="field grid">
          <label for="presidentPhone" class="col-12 mb-2 md:col-2 md:mb-0">President Phone</label>
          <div class="col-12 md-10">
            <input id="presidentPhone" type="text" pInputText name="presidentPhone" [(ngModel)]="organization.presidentPhone" ngDefaultControl>
          </div>
        </div>
        <div class="field grid">
          <label for="vicePresidentTitle" class="col-12 mb-2 md:col-2 md:mb-0">Vice President Gender</label>
          <div class="col-12 md:col-10">
            <p-dropdown [options]="genders" id="vicePresidentTitle" name="vicePresidentTitle" [(ngModel)]="organization.vicePresidentTitle"></p-dropdown>
          </div>
        </div>
        <div class="field grid">
          <label for="vicePresidentFirstName" class="col-12 mb-2 md:col-2 md:mb-0">Vice President First Name</label>
          <div class="col-12 md-10">
            <input id="vicePresidentFirstName" type="text" pInputText name="vicePresidentFirstName" [(ngModel)]="organization.vicePresidentFirstName" ngDefaultControl>
          </div>
        </div>
        <div class="field grid">
          <label for="vicePresidentFamilyName" class="col-12 mb-2 md:col-2 md:mb-0">Vice President Family Name</label>
          <div class="col-12 md-10">
            <input id="vicePresidentFamilyName" type="text" pInputText name="vicePresidentFamilyName" [(ngModel)]="organization.vicePresidentFamilyName" ngDefaultControl>
          </div>
        </div>
        <div class="field grid">
          <label for="vicePresidentEmail" class="col-12 mb-2 md:col-2 md:mb-0">Vice President Email</label>
          <div class="col-12 md-10">
            <input id="vicePresidentEmail" type="text" pInputText name="vicePresidentEmail" [(ngModel)]="organization.vicePresidentEmail" ngDefaultControl>
          </div>
        </div>
        <div class="field grid">
          <label for="vicePresidentPhone" class="col-12 mb-2 md:col-2 md:mb-0">Vice President Phone</label>
          <div class="col-12 md-10">
            <input id="vicePresidentPhone" type="text" pInputText name="vicePresidentPhone" [(ngModel)]="organization.vicePresidentPhone" ngDefaultControl>
          </div>
        </div>
        <div class="field grid">
          <label for="vicePresidentMobile" class="col-12 mb-2 md:col-2 md:mb-0">Vice President Mobile</label>
          <div class="col-12 md-10">
            <input id="vicePresidentMobile" type="text" pInputText name="vicePresidentMobile" [(ngModel)]="organization.vicePresidentMobile" ngDefaultControl>
          </div>
        </div>
        <div class="field grid">
          <label for="secretaryTitle" class="col-12 mb-2 md:col-2 md:mb-0">Secretary Gender</label>
          <div class="col-12 md:col-10">
            <p-dropdown [options]="genders" id="secretaryTitle" name="secretaryTitle" [(ngModel)]="organization.secretaryTitle"></p-dropdown>
          </div>
        </div>
        <div class="field grid">
          <label for="secretaryFirstName" class="col-12 mb-2 md:col-2 md:mb-0">Secretary First Name</label>
          <div class="col-12 md-10">
            <input id="secretaryFirstName" type="text" pInputText name="secretaryFirstName" [(ngModel)]="organization.secretaryFirstName" ngDefaultControl>
          </div>
        </div>
        <div class="field grid">
          <label for="secretaryFamilyName" class="col-12 mb-2 md:col-2 md:mb-0">Secretary Family Name</label>
          <div class="col-12 md-10">
            <input id="secretaryFamilyName" type="text" pInputText name="secretaryFamilyname" [(ngModel)]="organization.secretaryFamilyName" ngDefaultControl>
          </div>
        </div>
        <div class="field grid">
          <label for="secretaryEmail" class="col-12 mb-2 md:col-2 md:mb-0">Secretary Email</label>
          <div class="col-12 md-10">
            <input id="secretaryEmail" type="text" pInputText name="secretaryEmail" [(ngModel)]="organization.secretaryEmail" ngDefaultControl>
          </div>
        </div>
        <div class="field grid">
          <label for="secretaryPhone" class="col-12 mb-2 md:col-2 md:mb-0">Secretary Phone</label>
          <div class="col-12 md-10">
            <input id="secretaryPhone" type="text" pInputText name="secretaryPhone" [(ngModel)]="organization.secretaryPhone" ngDefaultControl>
          </div>
        </div>
        <div class="field grid">
          <label for="secretaryMobile" class="col-12 mb-2 md:col-2 md:mb-0">Secretary Mobile</label>
          <div class="col-12 md-10">
            <input id="secretaryMobile" type="text" pInputText name="secretaryMobile" [(ngModel)]="organization.secretaryMobile" ngDefaultControl>
          </div>
        </div>
        <div class="field grid">
          <label for="treasurerTitle" class="col-12 mb-2 md:col-2 md:mb-0">Treasurer Gender</label>
          <div class="col-12 md:col-10">
            <p-dropdown [options]="genders" id="treasurerTitle" name="treasurerTitle" [(ngModel)]="organization.treasurerTitle"></p-dropdown>
          </div>
        </div>
        <div class="field grid">
          <label for="treasurerFirstName" class="col-12 mb-2 md:col-2 md:mb-0">Treasurer First Name</label>
          <div class="col-12 md-10">
            <input id="treasurerFirstName" type="text" pInputText name="treasurerFirstName" [(ngModel)]="organization.treasurerFirstName" ngDefaultControl>
          </div>
        </div>
        <div class="field grid">
          <label for="treasurerFamilyName" class="col-12 mb-2 md:col-2 md:mb-0">Treasurer Family Name</label>
          <div class="col-12 md-10">
            <input id="treasurerFamilyName" type="text" pInputText name="treasurerFamilyName" [(ngModel)]="organization.treasurerFamilyName" ngDefaultControl>
          </div>
        </div>
        <div class="field grid">
          <label for="treasurerEmail" class="col-12 mb-2 md:col-2 md:mb-0">Treasurer Email</label>
          <div class="col-12 md-10">
            <input id="treasurerEmail" type="text" pInputText name="treasurerEmail" [(ngModel)]="organization.treasurerEmail" ngDefaultControl>
          </div>
        </div>
        <div class="field grid">
          <label for="treasurerPhone" class="col-12 mb-2 md:col-2 md:mb-0">Treasurer Phone</label>
          <div class="col-12 md-10">
            <input id="treasurerPhone" type="text" pInputText name="treasurerPhone" [(ngModel)]="organization.treasurerPhone" ngDefaultControl>
          </div>
        </div>
        <div class="field grid">
          <label for="treasurerMobile" class="col-12 mb-2 md:col-2 md:mb-0">Treasurer Mobile</label>
          <div class="col-12 md-10">
            <input id="treasurerMobile" type="text" pInputText name="treasurerMobile" [(ngModel)]="organization.treasurerMobile" ngDefaultControl>
          </div>
        </div>
      </p-tabPanel>
      <p-tabPanel i18n="@@Relationships" header="Relationships">
        <div class="field grid">
          <label for="warehouse" class="col-12 mb-2 md:col-2 md:mb-0">Warehouse</label>
          <div class="col-12 md-10">
            <ng-container *ngIf="warehouseSearch() as warehouseSearch">
              <foodbank-warehouse-selection
                ngDefaultControl
                id="warehouse"
                name="warehouse"
                [(ngModel)]="organization.warehouse.value"
                [warehouseSearch]="warehouseSearch"
              />
            </ng-container>
          </div>
        </div>
      </p-tabPanel>
      <p-tabPanel i18n="@@Beneficiaries" header="Beneficiaries">
      </p-tabPanel>
    </p-tabView>
    <div class="field grid">
      <div class="col-offset-3 col-3">
        <p-button label="Save" icon="pi pi-check" styleClass="p-button-primary" (onClick)="save(organization)"/>
      </div>
      <div class="col-3">
        <p-button label="Cancel" icon="pi pi-times" styleClass="p-button-secondary" (onClick)="close()"/>
      </div>
    </div>
  </form>
</div>

