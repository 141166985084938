import {inject, Injectable, Injector} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {map, Observable} from 'rxjs';
import {Page} from '@typedefs/page';
import {environment} from '@environments/environment';
import {Pagination} from './pagination';
import {TransferRequestDto, TransferRequestItemGroupDto, TransferRequestSearchDto} from '@typedefs/stock-rest';
import {TransferRequest} from "@model/transfer-request";
import {TransferRequestSearch} from "@model/search/transfer-request-search";
import {copyCommonFields} from "@model/mapping-utils";
import {StockGroup} from "@model/stock-group";
import {FoodbankCacheFactory} from "@services/foodabank-cache-factory";
import {FoodbankCache} from "@services/foodabank-cache";

@Injectable({
  providedIn: 'root'
})
export class TransferRequestService {

  #httpClient = inject(HttpClient);
  #foodbankCacheFactory = inject(FoodbankCacheFactory)

  public getTransferRequest$(transferRequestId: number, injector: Injector, cache = this.#foodbankCacheFactory.create(injector)): Observable<TransferRequest> {
    return this.#httpClient.get<TransferRequestDto>(`${environment.apiUrl}/transfer/request/${transferRequestId}`)
      .pipe(
        map(transferRequestDto => this.mapToTransferRequest(transferRequestDto, cache)),
      );
  }

  public findTransferRequests$(transferRequestSearch: TransferRequestSearch, pagination: Pagination, injector: Injector, cache = this.#foodbankCacheFactory.create(injector)): Observable<Page<TransferRequest> | undefined> {
    const transferRequestSearchDto = this.mapToTransferRequestSearchDto(transferRequestSearch);

    return this.#httpClient.post<Page<TransferRequestDto>>(`${environment.apiUrl}/transfer/request/search`, transferRequestSearchDto, {params: pagination})
      .pipe(map(transferRequestDtoPage => {
        const transferRequests: TransferRequest[] = transferRequestDtoPage.content.map(transferRequestDto => this.mapToTransferRequest(transferRequestDto, cache));
        return {
          ...transferRequestDtoPage,
          content: transferRequests
        }
      }));
  }

  saveTransferRequest$(transferRequest: TransferRequest, injector: Injector, cache = this.#foodbankCacheFactory.create(injector)) {
    const transferRequestDto = this.mapToTransferRequestDto(transferRequest);
    return this.#httpClient.post<TransferRequestDto>(`${environment.apiUrl}/transfer/request`, transferRequestDto, {})
      .pipe(
        map(transferRequestDto => this.mapToTransferRequest(transferRequestDto, cache)),
      );
  }

  public createTransferRequestItemsFromStockGroups(transferRequest: TransferRequest, stockGroups: StockGroup[]): Observable<TransferRequest> {
    return this.#httpClient.post<Page<TransferRequestItemGroupDto[]>>(`${environment.apiUrl}/stocks/groups/transfer/request/${transferRequest.id}/item`, stockGroups.map(stockGroup => stockGroup.id), {})
      .pipe(map(_ => ({...transferRequest})));
  }

  public mapToTransferRequest(transferRequestDto: TransferRequestDto, cache: FoodbankCache): TransferRequest {
    const commonFields: TransferRequestDto | TransferRequest = copyCommonFields(transferRequestDto, ['userId', 'warehouseId', 'companyId']);

    return {
      ...commonFields,
      company: cache.companyCache.get(transferRequestDto.companyId),
      warehouse: cache.warehouseCache.get(transferRequestDto.warehouseId),
      user: cache.userCache.get(transferRequestDto.userId),
    };
  }

  public mapToTransferRequestDto(transferRequest: TransferRequest): TransferRequestDto {
    // we assume every signal or id resolves at this point, hence !
    const companyId = transferRequest.company.value()!.id;
    const warehouseId = transferRequest.warehouse.value()!.id;
    const userId = transferRequest.user.value()!.id;

    return {
      ...transferRequest,
      companyId: companyId,
      warehouseId: warehouseId,
      userId: userId,
    }
  }

  public mapToTransferRequestSearchDto(transferRequestSearch: TransferRequestSearch): TransferRequestSearchDto {
    const commonFields: TransferRequestSearchDto | TransferRequestSearch = copyCommonFields(transferRequestSearch, ['company', 'sourceWarehouse', 'exactTransferRequest']);
    return {
      ...commonFields,
      companyId: transferRequestSearch.company?.id,
      sourceWarehouseId: transferRequestSearch.sourceWarehouse?.id,
      exactTransferRequestId: transferRequestSearch.exactTransferRequest?.id,
    };
  }

}
