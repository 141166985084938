import {Component, computed, effect, inject, Injector, input, model, output, Signal, signal, WritableSignal} from '@angular/core';
import {debounceTime, mergeMap, pipe} from "rxjs";
import {TableLazyLoadEvent, TableModule} from "primeng/table";
import {Page} from '@typedefs/page';
import {ArticleSearch} from '@model/search/article-search';
import {Article} from '@model/article';
import {ArticleCategory} from '@model/article-category';
import {DEFAULT_ROWS_PER_PAGE, PaginationService} from '@services/pagination.service';
import {Pagination} from '@services/pagination';
import {ArticleStorageCondition} from '@model/article-storage-condition';
import {ArticleCategorySearch} from '@model/search/article-category-search';
import {toObservable, toSignal} from "@angular/core/rxjs-interop";
import {pipeSignal} from "@util/foodbanks-signal-rxjs-interop";
import {PanelModule} from "primeng/panel";
import {ToggleButtonModule} from "primeng/togglebutton";
import {PaginatorModule} from "primeng/paginator";
import {InputTextModule} from "primeng/inputtext";
import {DragDropModule} from "primeng/dragdrop";
import {StockGroupSearch} from "@model/search/stock-group-search";
import {StockGroup} from "@model/stock-group";
import {StockGroupService} from "@services/stock-group.service";
import {TableSizeComponent} from "@components/table-size/table-size.component";
import {ArticleCategoryComponent} from "@components/article-category/article-category.component";
import {ArticleComponent} from "@components/article/article.component";
import {AsyncPipe, DatePipe, NgIf} from "@angular/common";
import {ArticleCategorySelectionComponent} from "@components/article-category/selection/multi/article-category-selection.component";
import {ArticleMultiSelectionComponent} from "@components/article/selection/multi/article-multi-selection.component";
import {PalletTypeComponent} from "@components/pallet-type/pallet-type.component";
import {Supplier} from "@model/supplier";
import {SupplierSearch} from "@model/search/supplier-search";
import {SupplierComponent} from "@components/supplier/supplier.component";
import {SupplierSelectionComponent} from "@components/supplier/selection/single/supplier-selection.component";
import {StockGroupSortField} from "@typedefs/stock-rest";
import {StockGroupWithSummary} from "@model/stock-group-with-summary";
import {NumberComponent} from "@components/number/number.component";
import {Reception} from "@model/reception";
import {PalletType} from "@model/pallet-type";
import {DateSearch} from "@model/search/date-search";
import {PalletTypeSingleSelectionComponent} from "@components/pallet-type/selection/single/pallet-type-single-selection.component";
import {injectLocalStorage} from "ngxtension/inject-local-storage";
import {stockGroupTableSizeOption} from "@util/preferences";
import {ReceptionSelectionComponent} from "@components/reception/reception-selection/single/reception-selection.component";
import {ReceptionSearch} from "@model/search/reception-search";
import {ReceptionComponent} from "@components/reception/reception-selection/display/reception.component";
import {PalletTypeSearch} from "@model/search/pallet-type-search";
import {ColumnLabels} from "@components/columnLabels";
import {ChipsModule} from "primeng/chips";
import {MultiSelectModule} from "primeng/multiselect";

enum StockGroupColumn {
  category = 'category',
  article = 'article',
  supplier = 'supplier',
  reception = 'reception',
  palletType = 'palletType',
  pallets = 'pallets',
  totalWeight = 'totalWeight',
  deliverBeforeDate = 'deliverBeforeDate',
  fullDescription = 'fullDescription',
}

const COLUMN_LABELS: ColumnLabels<StockGroupColumn> = {
  category: 'Category',
  article: 'Article',
  supplier: 'Supplier',
  reception: 'Reception',
  palletType: 'Pallet type',
  pallets: 'Pallets 🥞',
  totalWeight: 'Total weight 🏋️',
  deliverBeforeDate: 'DLD 🗓️',
  fullDescription: 'Full description',
}

const COLUMN_PREFERENCE_KEY = 'preference_stock_group_list_columns';
const ALL_COLUMNS: StockGroupColumn[] = Object.values(StockGroupColumn) as StockGroupColumn[];
const DEFAULT_COLUMNS: StockGroupColumn[] = [StockGroupColumn.category, StockGroupColumn.article, StockGroupColumn.supplier, StockGroupColumn.reception, StockGroupColumn.palletType, StockGroupColumn.pallets, StockGroupColumn.totalWeight, StockGroupColumn.deliverBeforeDate, StockGroupColumn.fullDescription,];

@Component({
  selector: 'foodbank-stock-group-list',
  templateUrl: './stock-group-list.component.html',
  styleUrls: ['./stock-group-list.component.scss'],
  imports: [
    TableModule,
    PanelModule,
    ToggleButtonModule,
    PaginatorModule,
    InputTextModule,
    DragDropModule,
    TableSizeComponent,
    ArticleCategoryComponent,
    ArticleComponent,
    NgIf,
    ArticleCategorySelectionComponent,
    ArticleMultiSelectionComponent,
    DatePipe,
    AsyncPipe,
    PalletTypeComponent,
    SupplierComponent,
    SupplierSelectionComponent,
    NumberComponent,
    PalletTypeSingleSelectionComponent,
    ReceptionSelectionComponent,
    ReceptionComponent,
    ChipsModule,
    MultiSelectModule,

  ]
})
export class StockGroupListComponent {

  protected readonly DEFAULT_ROWS_PER_PAGE = DEFAULT_ROWS_PER_PAGE;

  stockGroupSearch = model<StockGroupSearch>();
  emptyMessage = input("");

  tableSizeStyleClass: WritableSignal<string> = injectLocalStorage(stockGroupTableSizeOption, {defaultValue: ''});
  pagination: WritableSignal<Pagination>;

  selectedStockGroups = model<StockGroup[]>([]);

  embedded = input(false);
  unrestrictedCompany = input(false);

  // searches for stock group page
  selectedArticlesFilter = model<Article[]>([]);
  selectedSupplierFilter = model<Supplier>();
  selectedReceptionFilter = model<Reception>();
  selectedPalletTypeFilter = model<PalletType>();
  selectedDeliverBeforeDateSearchFilter = model<DateSearch>();
  selectedArticleCategoriesFilter = model<ArticleCategory[]>([]);
  selectedArticleStorageConditionsFilter = model<ArticleStorageCondition[]>([]);

  onStockGroupDragStart = output<StockGroup[]>();
  onStockGroupDragEnd = output<void>();

  actualStockGroupSearch: Signal<StockGroupSearch>;

  stockGroupWithSummaryPage: Signal<Page<StockGroupWithSummary> | undefined>;

  stockGroupCount = output<number>();

  // searches for filters
  articleCategorySearchForFilter: Signal<ArticleCategorySearch>;
  articleSearchForFilter: Signal<ArticleSearch>;
  supplierSearchForFilter: Signal<SupplierSearch>;
  receptionSearchForFilter: Signal<ReceptionSearch>;
  palletTypeSearchForFilter: Signal<PalletTypeSearch>;

  fullDescriptionContains = signal<string | undefined>(undefined);

  // columns view selection
  showColumnSelector = input(true);
  displayedColumns = injectLocalStorage<StockGroupColumn[]>(COLUMN_PREFERENCE_KEY, {storageSync: true, defaultValue: DEFAULT_COLUMNS});

  #stockGroupService = inject(StockGroupService);
  #paginationService = inject(PaginationService);

  #injector = inject(Injector);

  constructor() {
    this.pagination = this.#paginationService.getDefaultPaginationSignal();

    const fullDescriptionContains$ = toObservable(this.fullDescriptionContains);
    const debouncedFullDescriptionContains$ = fullDescriptionContains$.pipe(debounceTime(500));
    const debouncedFullDescriptionContains = toSignal(debouncedFullDescriptionContains$);

    this.actualStockGroupSearch = computed((): StockGroupSearch => ({
        ...this.stockGroupSearch(),
        articleSearch: {
          articles: this.selectedArticlesFilter(),
          articleCategorySearch: {
            articleCategories: this.selectedArticleCategoriesFilter(),
          },
          articleStorageConditionSearch: {
            articleStorageConditions: this.selectedArticleStorageConditionsFilter(),
          }
        },
        fullDescriptionContains: debouncedFullDescriptionContains(),
        supplier: this.selectedSupplierFilter(),
        reception: this.selectedReceptionFilter(),
        palletType: this.selectedPalletTypeFilter(),
        deliverBeforeDateSearch: this.selectedDeliverBeforeDateSearchFilter(),
      })
    );

    this.articleCategorySearchForFilter = computed((): ArticleCategorySearch => ({
        stockGroupSearch: {
          ...this.actualStockGroupSearch(),
          articleSearch: {
            ...this.actualStockGroupSearch().articleSearch,
            articleCategorySearch: {
              articleCategories: undefined,
            },
          },
        },
      })
    );

    // for column filters, we use the main StockGroupSearch, but undefining the column we are searching for to get all values
    // (e.g. article: undefined for article filter column so we see all possible articles with all other column filters and StockGroupSearch restrictions)
    this.articleSearchForFilter = computed((): ArticleSearch => ({
        stockGroupSearch: {
          ...this.actualStockGroupSearch(),
          articleSearch: {
            ...this.actualStockGroupSearch().articleSearch,
            articles: undefined,
          },
        }
      })
    );

    this.supplierSearchForFilter = computed((): SupplierSearch => ({
        stockGroupSearch: {
          ...this.actualStockGroupSearch(),
          supplier: undefined,
        }
      })
    );

    this.receptionSearchForFilter = computed((): ReceptionSearch => ({
      stockGroupSearch: {
        ...this.actualStockGroupSearch(),
        reception: undefined,
      }
    }));

    this.palletTypeSearchForFilter = computed((): PalletTypeSearch => ({
      stockGroupSearch: {
        ...this.actualStockGroupSearch(),
        palletType: undefined,
      }
    }));

    const stockGroupPageParams: Signal<[StockGroupSearch, Pagination]> = computed(() => [this.actualStockGroupSearch(), this.pagination()])

    this.stockGroupWithSummaryPage = pipeSignal(stockGroupPageParams, pipe(
        mergeMap(([stockGroupSearch, pagination]) => this.#stockGroupService.findStockGroupWithSummaryPage$(stockGroupSearch, pagination, this.#injector)),
      )
    );

    // count number of total items
    effect(() => {
      const totalElements = this.stockGroupWithSummaryPage/*.value*/()?.totalElements;
      if (totalElements) {
        this.stockGroupCount.emit(totalElements);
      }
    });

  }

  loadStockGroupList(event: TableLazyLoadEvent) {
    const pagination = this.#paginationService.getTablePagination(event);
    this.pagination.set(pagination);
  }

  typing(stockGroupWithSummary: StockGroupWithSummary): StockGroupWithSummary {
    return stockGroupWithSummary;
  }

  notifyStockGroupDragStart(dragEvent: DragEvent, stockGroup: StockGroup) {
    let selectedStockGroups = this.#getStockGroupsForDragAndDrop(stockGroup);
    this.onStockGroupDragStart.emit(selectedStockGroups);
  }

  notifyStockGroupDragEnd(event: DragEvent) {
    if (event.dataTransfer && event.dataTransfer.dropEffect !== 'none') {
      this.selectedStockGroups.set([]);
    }
    this.onStockGroupDragEnd.emit();
  }

  #getStockGroupsForDragAndDrop(stockGroup: StockGroup) {
    let selectedStockGroups = this.selectedStockGroups();
    if (selectedStockGroups.indexOf(stockGroup) < 0) {
      selectedStockGroups = [...selectedStockGroups, stockGroup];
    }
    return selectedStockGroups;
  }

  sortFieldName(sortField: StockGroupSortField): StockGroupSortField {
    return sortField;
  }


  getColumnLabel(column: StockGroupColumn): string {
    return COLUMN_LABELS[column];
  }

  protected readonly ALL_COLUMNS = ALL_COLUMNS;
  protected readonly COLUMN_LABELS = COLUMN_LABELS;
  protected readonly StockGroupColumn = StockGroupColumn;
}
