import {Component, computed, inject, input, model, Resource, Signal, WritableSignal} from '@angular/core';
import {ReceptionSearch} from '@model/search/reception-search';
import {Reception} from '@model/reception';
import {ReceptionService} from '@services/reception.service';
import {PaginationService} from "@services/pagination.service";
import {Pagination} from "@services/pagination";
import {DropdownModule} from "primeng/dropdown";
import {FormsModule} from "@angular/forms";
import {InputTextModule} from "primeng/inputtext";
import {rxResource} from "@angular/core/rxjs-interop";
import {Page} from "@typedefs/page";
import {ReceptionComponent} from "@components/reception/reception-selection/display/reception.component";

@Component({
  selector: 'foodbank-reception-selection',
  exportAs: 'receptionSelection',
  templateUrl: './reception-selection.component.html',
  styleUrls: ['./reception-selection.component.scss'],
  standalone: true,
  imports: [
    DropdownModule,
    FormsModule,
    InputTextModule,
    ReceptionComponent
  ]
})
export class ReceptionSelectionComponent {

  ngModel = model<Reception>();
  required = input(false);
  receptionSearch = input<ReceptionSearch>({}); // you could for example create a reception filter to get receptions of a specific user

  receptionId = model<number>();

  receptionPage: Resource<Page<Reception>>;
  pagination: WritableSignal<Pagination>;

  #receptionService = inject(ReceptionService);
  #paginationService = inject(PaginationService);

  constructor() {
    this.pagination = this.#paginationService.getDefaultComponentPaginationSignal();

    const actualReceptionSearch: Signal<ReceptionSearch> = computed((): ReceptionSearch => ({
      ...this.receptionSearch(),
      receptionId: this.receptionId(),
    }));

    this.receptionPage = rxResource({
      request: () => actualReceptionSearch(),
      loader: params => this.#receptionService.findReceptions$(params.request, this.pagination()),
    });
  }

  identity(reception: Reception): Reception {
    return reception;
  }
}
