import {Component, computed, inject, Injector, input, model, Signal, signal, WritableSignal} from '@angular/core';
import {DonorSearch} from '@model/search/donor-search';
import {Donor} from '@model/donor';
import {DonorService} from '@services/donor.service';
import {PaginationService} from "@services/pagination.service";
import {Pagination} from "@services/pagination";
import {loadSignalFromObservable} from "@util/foodbanks-signal-rxjs-interop";
import {DropdownModule} from "primeng/dropdown";
import {FormsModule} from "@angular/forms";
import {InputTextModule} from "primeng/inputtext";
import {AutoFocus} from "primeng/autofocus";
import {DonorComponent} from "@components/donor/donor.component";
import {SupplierComponent} from "@components/supplier/supplier.component";

@Component({
  selector: 'foodbank-donor-selection',
  exportAs: 'donorSelection',
  templateUrl: './donor-selection.component.html',
  styleUrls: ['./donor-selection.component.scss'],
  standalone: true,
  imports: [
    DropdownModule,
    FormsModule,
    InputTextModule,
    AutoFocus,
    DonorComponent
  ]
})
export class DonorSelectionComponent {

  ngModel = model<Donor>();
  required = input(false);
  donorSearch = input<DonorSearch>({});

  familyNameFilter = signal('');

  donors: Signal<Donor[] | undefined>;
  pagination: WritableSignal<Pagination>;
  loading = signal(true);

  #donorService = inject(DonorService);
  #paginationService = inject(PaginationService);
  #injector = inject(Injector);

  constructor() {
    this.pagination = this.#paginationService.getDefaultComponentPaginationSignal();

    const actualDonorSearch: Signal<DonorSearch> = computed(() => ({
      ...this.donorSearch(),
      familyNameContains: this.familyNameFilter(),
    }));

    const donorPage = loadSignalFromObservable(() => this.#donorService.findDonors$(actualDonorSearch(), this.#injector, this.pagination()), this.loading);

    this.donors = computed(() => donorPage()?.content);
  }

  clear() {
    this.ngModel.set(undefined);
  }

}
