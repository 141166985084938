import {Component, computed, inject, Injector, input, model, Resource, Signal, WritableSignal} from '@angular/core'
import {Member} from '@model/member';
import {MemberService} from '@services/member.service';
import {UserService} from "@services/user.service";
import {MemberSearch} from "@model/search/member-search";
import {Pagination} from "@services/pagination";
import {DEFAULT_ROWS_PER_PAGE, PaginationService} from '@services/pagination.service';
import {Page} from "@typedefs/page";
import {DialogService, DynamicDialogRef} from "primeng/dynamicdialog";
import {TableLazyLoadEvent, TableModule} from "primeng/table";
import {RippleModule} from "primeng/ripple";
import {PrimeTemplate} from "primeng/api";
import {InputTextModule} from "primeng/inputtext";
import {FormsModule} from "@angular/forms";
import {derivedAsync} from "ngxtension/derived-async";
import {RoleComponent} from "@components/role/role.component";
import {MemberEditComponent} from "@components/member/member-edit/member-edit.component";
import {ButtonModule} from "primeng/button";
import {OrganizationSingleSelectionComponent} from "@components/organization/selection/single/organization-single-selection.component";
import {Organization} from "@model/organization";
import {OrganizationComponent} from "@components/organization/organization.component";
import {OrganizationSearch} from "@model/search/organization-search";
import {MultiSelectModule} from "primeng/multiselect";
import {joinWhenPresent} from "@util/string-utils";
import {injectLocalStorage} from "ngxtension/inject-local-storage";
import {ColumnLabels} from "@components/columnLabels";
import {ChipModule} from "primeng/chip";
import {ChipsModule} from "primeng/chips";
import {rxResource} from "@angular/core/rxjs-interop";

enum MemberColumn {
  firstName = 'firstName',
  familyName = 'familyName',
  organization = 'organization',
  address = 'address',
  phone = 'phone',
  email = 'email',
  role = 'role',
  zip = 'zip',
  city = 'city',
}

const COLUMN_LABELS: ColumnLabels<MemberColumn> = {
  firstName: 'First Name',
  familyName: 'Family Name',
  organization: 'Organization',
  address: 'Address',
  phone: 'Phone',
  email: 'Email',
  role: 'Role',
  zip: 'Zip',
  city: 'City'
}

const COLUMN_PREFERENCE_KEY = 'preference_members_list_columns';

const ALL_COLUMNS: MemberColumn[] = Object.values(MemberColumn) as MemberColumn[];
const DEFAULT_COLUMNS: MemberColumn[] = [MemberColumn.firstName, MemberColumn.familyName, MemberColumn.organization, MemberColumn.phone, MemberColumn.email];

@Component({
  selector: 'foodbank-members',
  templateUrl: './member-list.component.html',
  styleUrls: ['./member-list.component.scss'],
  providers: [DialogService],
  imports: [TableModule, PrimeTemplate, InputTextModule, FormsModule,  ButtonModule, RippleModule,  MultiSelectModule, ChipModule, ChipsModule,OrganizationSingleSelectionComponent, OrganizationComponent,]
})
export class MembersComponent {

  // selected search filters for this view
  filterFirstNameContains = model('');
  filterFamilyNameContains = model('');
  filterZipContains = model('');
  filterCityContains = model('');
  filterOrganization = model<Organization>();

  // searches for filters (e.g. organization)
  organizationSearch: Signal<OrganizationSearch>;

  // this view search
  memberSearch: Signal<MemberSearch>;
  pagination: WritableSignal<Pagination>;

  // results
  memberPage: Resource<Page<Member> | undefined>;

  // internal state
  #dialogRef?: DynamicDialogRef;

  // services
  #memberService = inject(MemberService);
  #paginationService = inject(PaginationService);
  #userService = inject(UserService);
  #dialogService = inject(DialogService);
  #injector = inject(Injector);

  // columns view selection
  showColumnSelector = input(true);
  displayedColumns = injectLocalStorage<MemberColumn[]>(COLUMN_PREFERENCE_KEY, {storageSync: true, defaultValue: DEFAULT_COLUMNS});

  constructor() {
    const currentUserCompany = this.#userService.getCurrentUserCompany();

    this.organizationSearch = computed(() => ({
      company: currentUserCompany(),
    }));

    this.memberSearch = computed(() => ({
      company: currentUserCompany(),
      organization: this.filterOrganization(),
      familyNameContains: this.filterFamilyNameContains(),
      firstNameContains: this.filterFirstNameContains(),
      zipContains: this.filterZipContains(),
      cityContains: this.filterCityContains(),
    }));

    this.pagination = this.#paginationService.getDefaultPaginationSignal(DEFAULT_ROWS_PER_PAGE);

    this.memberPage = rxResource({
      request: () => ({
        memberSearch: this.memberSearch(),
        pagination: this.pagination(),
      }),
      loader: param => this.#memberService.findMembers$(this.memberSearch(), this.#injector, this.pagination())
    });
  }

  handleLazyLoad(event: TableLazyLoadEvent) {
    const pagination = this.#paginationService.getTablePagination(event);
    this.pagination.set(pagination);
  }

  protected readonly DEFAULT_ROWS_PER_PAGE = DEFAULT_ROWS_PER_PAGE;

  identity(member: Member): Member {
    return member;
  }

  generateTelGsm(member: Member) {
    let telgsm = "";
    if (member.phone && member.phone != "") {
      telgsm += member.phone.trim() + " ";
    }
    if (member.mobile && member.mobile != "") {
      telgsm += member.mobile.trim() + " ";
    }
    return telgsm;

  }

  openEditDialog(member: Member) {
    this.#dialogRef = this.#dialogService.open(MemberEditComponent, {
      header: 'Edit Member',
      width: '40%',
      data: {...member}
    });
    this.#dialogRef?.onClose.subscribe(() => {
      this.memberPage.reload()
    })
  }

  getAddress(member: Member) {
    const location = joinWhenPresent(" ", [member.zip, member.city]);
    return joinWhenPresent(" ,", [member.address, location]);
  }

  getColumnLabel(column: MemberColumn): string {
    return COLUMN_LABELS[column];
  }

  protected readonly ALL_COLUMNS = ALL_COLUMNS;
  protected readonly MemberColumn = MemberColumn;
}


