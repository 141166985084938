import { Component, computed, effect, inject, Injector, OnInit, signal, Signal, untracked, WritableSignal } from "@angular/core";
import { FileUploadModule } from "primeng/fileupload";
import { NgIf } from "@angular/common";
import { TableModule } from "primeng/table";
import { ArticleService } from "@services/article.service";
import { TooltipModule } from "primeng/tooltip";
import { rxResource, toObservable } from "@angular/core/rxjs-interop";
import { debounceTime, filter, firstValueFrom, Observable, of, Subject, take } from "rxjs";
import { CheckboxModule } from "primeng/checkbox";
import { FormsModule } from "@angular/forms";
import { InputTextModule } from "primeng/inputtext";
import { InputNumberModule } from "primeng/inputnumber";
import { UserService } from "@services/user.service";
import { User } from "@model/user";
import { ButtonModule } from "primeng/button";
import { BulkFoodImportService, ImportRow } from "@services/bulk-food-import.service";
import { Article } from "@model/article";
import { NumberComponent } from "@components/number/number.component";
import { ArticleComponent } from "@components/article/article.component";
import { SupplierService } from "@services/supplier.service";
import { Supplier } from "@model/supplier";
import { SupplierComponent } from "@components/supplier/supplier.component";
import { MovementService } from '@services/movement.service';
import { MovementTypeService } from '@services/movement-type.service';
import { Movement } from '@model/movement';
import { MessageService } from 'primeng/api';
import { Language } from '@typedefs/stock-rest';
import { Warehouse } from '@model/warehouse';
import { StockPallet } from '@model/stock-pallet';
import { PalletType } from '@model/pallet-type';
import { Company } from '@model/company';
import { Stock } from "@model/stock";

// Constants
const STORAGE_KEY = 'FOODBANK_BULK_IMPORT_STATE';
const DEBOUNCE_SAVE_MS = 500;
const DEBOUNCE_FOOD_ID_MS = 300;

interface BulkFoodImportPreferences {
  data: Omit<ImportRow, 'articleRef' | 'supplierRef' | 'stockRef' | 'recMovement'>[];
  showOnlyProblems: boolean;
}

@Component({
  selector: 'foodbank-bulk-food-import',
  templateUrl: './bulk-food-import.component.html',
  styleUrl: './bulk-food-import.component.scss',
  imports: [
    FileUploadModule,
    NgIf,
    TableModule,
    TooltipModule,
    CheckboxModule,
    FormsModule,
    InputTextModule,
    InputNumberModule,
    ButtonModule,
    NumberComponent,
    ArticleComponent,
    SupplierComponent
  ]
})
export class BulkFoodImportComponent implements OnInit {
  // Injected Services
  readonly #articleService = inject(ArticleService);
  readonly #userService = inject(UserService);
  readonly #bulkFoodImportService = inject(BulkFoodImportService);
  readonly #supplierService = inject(SupplierService);
  readonly #injector = inject(Injector);
  readonly #movementService = inject(MovementService);
  readonly #movementTypeService = inject(MovementTypeService);
  readonly #messageService = inject(MessageService);

  // State
  readonly data: WritableSignal<ImportRow[]> = signal([]);
  readonly currentUser: Signal<User | undefined>;
  readonly defaultWarehouse: Signal<Warehouse | undefined>;
  readonly showOnlyProblems = signal(false);
  readonly selectedRows = signal<ImportRow[]>([]);
  readonly isSaving = signal(false);
  readonly saveProgress = signal<{current: number, total: number} | null>(null);

  // Subjects for debounced operations
  readonly #saveStateSubject = new Subject<void>();
  readonly #foodIdChangeSubject = new Subject<{row: ImportRow, newId: string}>();
  readonly #supplierIdChangeSubject = new Subject<{row: ImportRow, newId: string}>();

  // Computed Properties
  readonly hasData = computed(() => this.data().length > 0);
  readonly problemRows = computed(() => {
    return this.data().filter(row => {
      const isValid = this.isValid(row)();
      return isValid === false;
    });
  });
  readonly modifiedRows = computed(() => {
    return this.data().filter(row => row.isModified);
  });
  readonly statusMessage = computed(() => {
    const problemCount = this.problemRows().length;
    const modifiedCount = this.modifiedRows().length;
    const messages: string[] = [];

    if (problemCount > 0) {
      messages.push(`There are ${problemCount} rows with problems`);
    }
    if (modifiedCount > 0) {
      messages.push(`${modifiedCount} unsaved changes`);
    }
    if (messages.length === 0) {
      return 'All rows are valid';
    }
    return messages.join(', ');
  });
  readonly filteredData = computed(() => {
    const rows = this.data();
    if (!this.showOnlyProblems()) return rows;
    return rows.filter(row => row.articleRef.isLoading() || !untracked(this.isValid(row)));
  });

  currentUserCompany$: Observable<Company>;
  defaultWarehouse$: Observable<Warehouse | undefined>;

  constructor() {
    this.currentUser = this.#userService.getCurrentUser();
    this.defaultWarehouse = this.#userService.getDefaultWarehouse();
    this.#initializeStateFromPreferences();
    this.#setupDebouncing();
    this.#setupEffects();
    this.currentUserCompany$ = this.#userService.getCurrentUserCompany$();
    this.defaultWarehouse$ = this.#userService.getDefaultWarehouse$();
  }

  ngOnInit() {}

  // Public Methods
  #cleanString(str: string | undefined): string {
    if (!str) return '';
    if (typeof str !== 'string') return String(str);
    // Replace all whitespace characters (spaces, tabs, newlines, carriage returns) with a single space and trim
    return str.replace(/[\s\r\n]+/g, ' ').trim();
  }

  onUpload(event: { files: File[] }): void {
    const uploadedFile = event.files[0];
    this.#userService.getDefaultWarehouse$().pipe(take(1)).subscribe(warehouse => {
      if (!warehouse) {
        this.#messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'No default warehouse found'
        });
        return;
      }

      this.#bulkFoodImportService.importExcelFile(uploadedFile, warehouse, this.#injector)
        .then(importRows => {
          // Clean all string values
          const cleanedRows = importRows.map(row => ({
          ...row,
          productId: this.#cleanString(row.productId),
          productCode: this.#cleanString(row.productCode),
          article: this.#cleanString(row.article),
          unit: this.#cleanString(row.unit),
          yearMonth: this.#cleanString(row.yearMonth),
          supplierId: this.#cleanString(row.supplierId),
          supplierName: this.#cleanString(row.supplierName),
          postalCode: this.#cleanString(row.postalCode),
          city: this.#cleanString(row.city),
        }));
        this.data.set(cleanedRows);
        this.#saveState();
      });
    });
  }

  onFoodIdChange(row: ImportRow, newId: string): void {
    row.productId = newId;
    row.isModified = true;

    // Update stockRef
    row.stockRef = rxResource<Stock | undefined, Error>({
      loader: () => this.#bulkFoodImportService.loadStock$(newId, this.#injector),
      injector: this.#injector
    });

    // Update recMovement
    row.recMovement = rxResource<Movement | undefined, Error>({
      loader: () => this.#bulkFoodImportService.findRecMovement$(this.defaultWarehouse(), row.yearMonth, newId, row.supplierId, row.productCode, this.#injector),
      injector: this.#injector
    });

    this.#foodIdChangeSubject.next({row, newId});
  }

  onSupplierIdChange(row: ImportRow, newId: string): void {
    row.supplierId = newId;
    row.isModified = true;
    this.#supplierIdChangeSubject.next({row, newId});
  }

  onArticleChange(row: ImportRow, newArticle: string): void {
    row.article = newArticle;
    row.isModified = true;
    this.#debouncedSave();
  }

  onQuantityChange(row: ImportRow, newQuantity: number): void {
    row.quantity = newQuantity;
    row.isModified = true;
    this.#debouncedSave();
  }

  clearData(): void {
    this.data.set([]);
    const user = this.currentUser();
    if (user) {
      this.#userService.setUserPreferences(user, STORAGE_KEY, null);
    }
  }

  isValid = (row: ImportRow) => computed(() => {
    const articleRef = row.articleRef;
    const supplierRef = row.supplierRef;

    if (articleRef.isLoading() || supplierRef.isLoading()) {
      return undefined;
    }

    return articleRef.value() !== undefined && supplierRef.value() !== undefined;
  });

  hasValidRows(): boolean {
    const rowsToCheck = this.selectedRows().length > 0 ? this.selectedRows() : this.data();
    return rowsToCheck.some(row => row.articleRef.value() && row.productId);
  }

  saveMovements() {
    this.saveMovementsAsync().catch(error => {
      console.error(error);
    });
  }

  async saveMovementsAsync() {
    this.isSaving.set(true);
    this.saveProgress.set({ current: 0, total: 0 });

    try {
      const movementType = await firstValueFrom(this.#movementTypeService.getMovementType$('RECEIPT'));
      const currentUserCompany = await firstValueFrom(this.currentUserCompany$);
      const defaultWarehouse = await firstValueFrom(this.#userService.getDefaultWarehouse$());
      const currentUser = await firstValueFrom(this.#userService.getCurrentUser$());

      if (!defaultWarehouse) {
        this.#messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'No default warehouse found'
        });
        return;
      }

      if (!currentUserCompany) {
        this.#messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'No company found'
        });
        return;
      }

      const rowsToProcess = this.selectedRows().length > 0 ? this.selectedRows() : this.data();
      const validRows = rowsToProcess.filter(row => (row.articleRef.value() && row.productId) && (row.isModified || !row.recMovement.value()));
      this.saveProgress.set({ current: 0, total: validRows.length });

      // Reset counters
      let createdCount = 0;
      let updatedCount = 0;

      for (const [index, row] of validRows.entries()) {
        const article = row.articleRef.value();
        const supplier = row.supplierRef.value();
        const stock = row.stockRef.value();
        const existingMovement = row.recMovement.value();

        if (!article || !supplier) continue;

        const emptyPalletType: PalletType = {
          id: '0',
          languageNameMap: { FRENCH: '', DUTCH: '' },
          weight: 0
        };

        const emptyStockPallet: StockPallet = {
          id: 0,
          palletType$: of(emptyPalletType)
        };

        const description = `[${row.productCode}] ${row.article}`;

        const movement: Movement = {
          ...(existingMovement || {}),
          quantity: row.quantity,
          dateTime: existingMovement?.dateTime || new Date(),
          date: existingMovement?.date || new Date(),
          timestamp: new Date(),
          location: 'RAMASSE',
          internalBatchName: existingMovement?.internalBatchName || '',
          supplierBatchName: `RAMASSE ${row.yearMonth}`,
          comment: description,
          fullDescription: description,
          deliverBeforeDate: existingMovement?.deliverBeforeDate || new Date(),
          unitWeight: existingMovement?.unitWeight || 0,
          preparation: existingMovement?.preparation || 0,
          unitGrossWeight: existingMovement?.unitGrossWeight || 0,
          unitsPerParcel: existingMovement?.unitsPerParcel || 0,
          demand: existingMovement?.demand || 0,
          movementType$: of(movementType),
          article$: of(article),
          company$: of(currentUserCompany),
          warehouse$: of(defaultWarehouse),
          stockPallet$: stock ? stock.pallet$ : of(emptyStockPallet),
          user$: of(currentUser),
          reception$: of(undefined),
          supplier$: of(supplier),
          organization$: of(undefined),
          stock$: stock ? of(stock) : of(undefined)
        };

        if (row.quantity === 0) {
          continue;
        }

        try {
          const savedMovement = await firstValueFrom(this.#movementService.saveMovement(movement, this.#injector));
          row.recMovement.value.set(savedMovement);
          row.isModified = false;
          // Update the data signal to trigger UI refresh after each save
          this.data.set([...this.data()]);
          if (existingMovement) {
            updatedCount++;
          } else {
            createdCount++;
          }
          this.saveProgress.set({ current: index, total: validRows.length });
        } catch (error) {
          console.log(error);
          this.#messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: `Failed to save movement for article ${article.id}`
          });
        }
      }

      // Save state to persist modified flags
      this.#saveState();

      this.#messageService.add({
        severity: 'success',
        summary: 'Success',
        detail: `Saved ${createdCount + updatedCount} movements`
      });
    } finally {
      this.isSaving.set(false);
      this.saveProgress.set(null);
    }
  }

  async onCreateSupplier(row: ImportRow): Promise<void> {
    const currentUser = await firstValueFrom(this.#userService.getCurrentUser$());
    const language: Language = currentUser.language === 'fr' ? 'FRENCH' : 'DUTCH';

    const supplierDto = {
      id: row.supplierId,
      name: row.supplierName,
      language: language,
      city: row.city,
      zip: row.postalCode,
      fead: false
    };

    const supplier = await firstValueFrom(this.#supplierService.createSupplier$(supplierDto));
    row.supplierRef.value.set(supplier);
  }

  onSelectionChange(selection: ImportRow[]): void {
    this.selectedRows.set(selection);
  }

  // Private Methods
  #initializeStateFromPreferences(): void {
    toObservable(this.#userService.getCurrentUserPreferences<BulkFoodImportPreferences | undefined>(STORAGE_KEY, undefined))
      .pipe(
        filter(preferences => !!preferences),
        take(1)
      )
      .subscribe(preferences => {
        this.#restoreState(this.currentUser(), this.defaultWarehouse(), preferences);
      });
  }

  #setupDebouncing(): void {
    this.#saveStateSubject.pipe(
      debounceTime(DEBOUNCE_SAVE_MS)
    ).subscribe(() => {
      this.#saveState();
    });

    this.#foodIdChangeSubject.pipe(
      debounceTime(DEBOUNCE_FOOD_ID_MS)
    ).subscribe(({row, newId}) => {
      this.#updateArticleRef(row, newId);
      this.#debouncedSave();
    });

    this.#supplierIdChangeSubject.pipe(
      debounceTime(DEBOUNCE_FOOD_ID_MS)
    ).subscribe(({row, newId}) => {
      this.#updateSupplierRef(row, newId);
      this.#debouncedSave();
    });
  }

  #setupEffects(): void {
    effect(() => {
      this.showOnlyProblems();
      this.#debouncedSave();
    });
  }

  #updateArticleRef(row: ImportRow, newId: string): void {
    if (!newId?.trim()) {
      row.articleRef.value.set(undefined);
      return;
    }

    this.#articleService.getArticle$(newId)
      .pipe(take(1))
      .subscribe({
        next: article => row.articleRef.value.set(article),
        error: () => row.articleRef.value.set(undefined)
      });
  }

  #updateSupplierRef(row: ImportRow, newId: string): void {
    if (!newId?.trim()) {
      row.supplierRef.value.set(undefined);
      return;
    }

    this.#supplierService.getSupplier$(newId)
      .pipe(take(1))
      .subscribe({
        next: supplier => row.supplierRef.value.set(supplier),
        error: () => row.supplierRef.value.set(undefined)
      });
  }

  #debouncedSave(): void {
    this.#saveStateSubject.next();
  }

  #saveState(): void {
    const user = this.currentUser();
    if (!user) return;

    const state: BulkFoodImportPreferences = {
      data: this.data().map(({ articleRef, supplierRef, stockRef, recMovement, ...rest }) => rest),
      showOnlyProblems: this.showOnlyProblems()
    };

    this.#userService.setUserPreferences(user, STORAGE_KEY, state);
  }

  #restoreState(currentUser: User | undefined, defaultWarehouse: Warehouse | undefined, preferences: BulkFoodImportPreferences | null): void {
    if (!preferences?.data?.length) return;


    try {
      const restoredData: ImportRow[] = preferences.data.map((item) => ({
        ...item,
        articleRef: rxResource<Article | undefined, Error>({
          loader: () => item.productId ? this.#articleService.getArticle$(item.productId) : of(undefined),
          injector: this.#injector
        }),
        supplierRef: rxResource<Supplier | undefined, Error>({
          loader: () => item.supplierId ? this.#supplierService.getSupplier$(item.supplierId) : of(undefined),
          injector: this.#injector
        }),
        stockRef: rxResource<Stock | undefined, Error>({
          loader: () => item.productId ? this.#bulkFoodImportService.loadStock$(item.productId, this.#injector) : of(undefined),
          injector: this.#injector
        }),
        recMovement: rxResource<Movement | undefined, Error>({
          loader: () => this.#bulkFoodImportService.findRecMovement$(defaultWarehouse, item.yearMonth, item.productId, item.supplierId, item.productCode, this.#injector),
          injector: this.#injector
        })
      }));
      this.data.set(restoredData);

      if (typeof preferences.showOnlyProblems === 'boolean') {
        this.showOnlyProblems.set(preferences.showOnlyProblems);
      }
    } catch (e) {
      console.error('Failed to restore state:', e);
      if (currentUser) {
        this.#userService.setUserPreferences(currentUser, STORAGE_KEY, null);
      }
    }
  }
}
