import {Component, model} from '@angular/core';
import {Reception} from '@model/reception';
import {DropdownModule} from "primeng/dropdown";
import {FormsModule} from "@angular/forms";
import {InputTextModule} from "primeng/inputtext";
import {DatePipe} from "@angular/common";

@Component({
  selector: 'foodbank-reception',
  exportAs: 'receptionSelection',
  templateUrl: './reception.component.html',
  styleUrls: ['./reception.component.scss'],
  standalone: true,
  imports: [
    DropdownModule,
    FormsModule,
    InputTextModule,
    DatePipe
  ]
})
export class ReceptionComponent {

  ngModel = model<Reception>();
  showId = model(true);

}
