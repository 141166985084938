import {Component, computed, inject, model, Resource, Signal} from '@angular/core';
import {ArticleCategorySearch} from '@model/search/article-category-search';
import {ArticleCategory} from '@model/article-category';
import {ArticleCategoryService} from '@services/article-category.service';
import {MultiSelectModule} from "primeng/multiselect";
import {FormsModule} from "@angular/forms";
import {NgForOf, NgIf} from "@angular/common";
import {ArticleCategoryComponent} from "@components/article-category/article-category.component";
import {DropdownModule} from "primeng/dropdown";
import {rxResource} from "@angular/core/rxjs-interop";
import {Page} from "@typedefs/page";

@Component({
  selector: 'foodbank-article-category-selection',
  templateUrl: './article-category-selection.component.html',
  styleUrls: ['./article-category-selection.component.scss'],
  imports: [
    MultiSelectModule,
    FormsModule,
    ArticleCategoryComponent,
    DropdownModule,
    NgIf,
    NgForOf
  ]
})
export class ArticleCategorySelectionComponent {

  selectedArticleCategories = model<ArticleCategory[]>([]);
  selectedArticleCategory: Signal<ArticleCategory | undefined>;

  articleCategoryPage: Resource<Page<ArticleCategory>>;
  articleCategorySearch = model<ArticleCategorySearch>({});

  #articleCategoryService = inject(ArticleCategoryService);

  constructor() {
    this.articleCategoryPage = rxResource({
      request: () => this.articleCategorySearch(),
      loader: params => this.#articleCategoryService.findArticleCategories$(this.articleCategorySearch(), {page: 0, size: 1000})
    });
    this.selectedArticleCategory = computed(() => {
      const selectedArticleCategories = this.selectedArticleCategories();
      if (selectedArticleCategories.length == 1) {
        return selectedArticleCategories[0];
      }
      return undefined;
    });
  }

}
