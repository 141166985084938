<p-table #dt
         [value]="(cpasPage()?.content) || []"
         responsiveLayout="scroll"
         [rows]="DEFAULT_ROWS_PER_PAGE"
         [totalRecords]="cpasPage()?.totalElements || 0"
         [globalFilterFields]="['depot']"
         [paginator]="true"
         [rowsPerPageOptions]="[15,25,50,100]"
         [showCurrentPageReport]="true"
         currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
         [lazy]="true"
         (onLazyLoad)="handleLazyLoad($event)"
         [rowHover]="true"
         [resizableColumns]="true"
         columnResizeMode="expand"
         [autoLayout]="true"
         sortMode="multiple"
         dataKey="id">
  <ng-template pTemplate="caption">
    <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
       <span class="flex">
        <h2 class="m-0">Social Organizations</h2>
        <p-multiSelect
          ngDefaultControl
          [options]="ALL_COLUMNS"
          [(ngModel)]="displayedColumns"
          placeholder="Select columns"
        >
          <ng-template let-selectedColumns pTemplate="selectedItems">
            <p-chips [ngModel]="selectedColumns" [disabled]="true">
              <ng-template let-selectedColumn pTemplate="item">
                {{ getColumnLabel(selectedColumn) }}
              </ng-template>
            </p-chips>
          </ng-template>
          <ng-template let-column pTemplate="item">
                {{ getColumnLabel(column) }}
          </ng-template>
          <ng-template let-country pTemplate="footer">
            <div class="py-2 px-3">
                <strong>{{ displayedColumns().length }} item(s) selected.</strong>
            </div>
          </ng-template>
        </p-multiSelect>
      </span>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr style="vertical-align: bottom">
      @for (column of displayedColumns(); track column) {
        @switch (column) {
          @case ('name') {
            <th pSortableColumn="name">
              <span i18n>{{ getColumnLabel(CpasColumn.name) }}</span>
              <p-sortIcon field="name"/>
            </th>
          }
          @case ('zip') {
            <th pSortableColumn="zip">
              <span i18n>{{ getColumnLabel(CpasColumn.zip) }}</span>
              <p-sortIcon field="zip"/>
            </th>
          }
          @case ('street') {
            <th>
              <span i18n>{{ getColumnLabel(CpasColumn.street) }}</span>
            </th>
          }
          @case ('mail') {
            <th>
              <span i18n>{{ getColumnLabel(CpasColumn.mail) }}</span>
              <p-sortIcon field="email"/>
            </th>
          }
          @case ('phone') {
            <th>
              <span i18n>{{ getColumnLabel(CpasColumn.phone) }}</span>
            </th>
          }
          @case ('mobile') {
            <th>
              <span i18n>{{ getColumnLabel(CpasColumn.mobile) }}</span>
            </th>
          }
          @case ('contactName') {
            <th>
              <span i18n>{{ getColumnLabel(CpasColumn.contactName) }}</span>
            </th>
          }
          @case ('contactSurname') {
            <th>
              <span i18n>{{ getColumnLabel(CpasColumn.contactSurname) }}</span>
            </th>
          }
          @case ('contactTitle') {
            <th>
              <span i18n>{{ getColumnLabel(CpasColumn.contactTitle) }}</span>
            </th>
          }
          @case ('remarks') {
            <th>
              <span i18n>{{ getColumnLabel(CpasColumn.remarks) }}</span>
            </th>
          }
        }
      }
      <!-- actions -->
      <th></th>
    </tr>
    <tr>
      @for (column of displayedColumns(); track column) {
        @switch (column) {
          @case ('name') {
            <th>
              <input pInputText type="text" [placeholder]="'Search...'" [(ngModel)]="filterNameContains" ngDefaultControl/>
            </th>
          }
          @case ('zip') {
            <th>
              <input pInputText type="text" [placeholder]="'Search...'" [(ngModel)]="filterZipContains" ngDefaultControl/>
            </th>
          }
        }
      }
      <!-- actions -->
      <th></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-row>
    @let cpas = identity(row);
    <tr>
      @for (column of displayedColumns(); track column) {
        @switch (column) {
          @case ('name') {
            <td>
              {{ cpas.name }}
            </td>
          }
          @case ('zip') {
            <td>
              {{ cpas.zip }}
            </td>
          }
          @case ('street') {
            <td>
              {{ cpas.street }}
            </td>
          }
          @case ('mail') {
            <td>
              {{ cpas.mail }}
            </td>
          }
          @case ('phone') {
            <td>
              {{ cpas.phone }}
            </td>
          }
          @case ('mobile') {
            <td>
              {{ cpas.mobile }}
            </td>
          }
          @case ('contactName') {
            <td>
              {{ cpas.contactName }}
            </td>
          }
          @case ('contactSurname') {
            <td>
              {{ cpas.contactSurname }}
            </td>
          }
          @case ('contactTitle') {
            <td>
              {{ cpas.contactTitle }}
            </td>
          }
          @case ('remarks') {
            <td>
              {{ cpas.remarks }}
            </td>
          }
        }
      }
      <td>
        <button pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-button-primary mr-2" (click)="openEditDialog(cpas)"></button>
      </td>
    </tr>
  </ng-template>
</p-table>

