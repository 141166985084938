import {Component, OnInit} from '@angular/core';
import {LayoutService} from './service/app.layout.service';
import {ReceptionService} from '@services/reception.service';
import {UserService} from '@services/user.service';
import {concat, filter, map, of, switchMap, tap} from 'rxjs';
import {MenuItem} from "primeng/api";
import {MenuService} from "@components/layout/app.menu.service";
import {MenuChangeEvent} from "@components/layout/api/menuchangeevent";
import { NgFor, NgIf } from '@angular/common';
import { AppMenuitemComponent } from './app.menuitem.component';

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html',
    imports: [NgFor, NgIf, AppMenuitemComponent]
})
export class AppMenuComponent implements OnInit {

  model: MenuItem[] = [];

  constructor(public layoutService: LayoutService,
              public menuService: MenuService,
              private receptionService: ReceptionService,
              private userService: UserService) {
  }

  ngOnInit() {
    const company$ = this.userService.getCurrentUserCompany$();
    const interWarehouseReceptionCount$ = company$.pipe(
      switchMap(company => this.receptionService.getInterWarehouseReceptionCount$(company.id)),
    );

    this.model = [
      {
        label: 'Home',
        icon: 'pi pi-home',
        items: [
          {
            label: 'Administration', icon: 'fa-solid fa-toolbox',
            items: [
              {label: 'Users', icon: 'fa-solid fa-users', routerLink: ['/users/list']},
              {label: 'Members', icon: 'fa-solid fa-person', routerLink: ['/members/list']},
              {label: 'Roles', icon: 'fa-solid fa-people-carry-box', routerLink: ['/roles/list']},
              {label: 'Organizations', icon: 'fa-solid fa-people-roof', routerLink: ['/organizations/list']},
              {label: 'Regions', icon: 'fa-regular fa-square', routerLink: ['/regions/list']},
              {label: 'SocialOrgs', icon: 'fa-solid fa-people-arrows', routerLink: ['/cpass/list']},
              {label: 'Donors', icon: 'fa-solid fa-person', routerLink: ['/donors/list']},
              {label: 'Donations', icon: 'fa-solid fa-award', routerLink: ['/donations/list']},
            ]
          },
          {
            label: 'Stock', icon: 'fa-solid fa-toolbox',
            items: [

              {label: 'Suppliers', icon: 'fa-solid fa-industry', routerLink: ['/suppliers/list']},
              {label: 'Stock', icon: 'fa-solid fa-box', routerLink: ['/stocks/list']},
              {label: 'Stock groups', icon: 'fa-solid fa-boxes-stacked', routerLink: ['/stocks/groups/list']},
              {label: 'Articles', icon: 'fa-solid fa-barcode', routerLink: ['/articles/list']},
              {label: 'Movements', icon: 'fa-solid fa-truck', routerLink: ['/movements/list']},
              {label: 'Transfer requests', icon: 'fa-solid fa-truck-fast', routerLink: ['/transfers/requests']},
            ]
          },
          {
            label: 'ESF+', icon: 'fa-solid fa-hand-holding-heart',
            items: [
              {label: 'Planning by article', icon: 'fa-solid fa-barcode', routerLink: ['/fead/planning/article']},
              {label: 'Planning by organization', icon: 'fa-solid fa-building', routerLink: ['/fead/planning/organization']},
              {label: 'Planning summary', icon: 'fa-solid fa-clipboard-list', routerLink: ['/fead/planning/summary']},
              {label: 'Preparation', icon: 'fa-solid fa-dolly', routerLink: ['/fead/preparation/list']},
              {label: 'Expedition', icon: 'fa-solid fa-truck', routerLink: ['/fead/expedition/list']},
            ]
          },
          {
            label: 'Day-to-day', icon: 'fa-solid fa-user-tie', badgeSeverity: 'warning',
            showBadge$: interWarehouseReceptionCount$.pipe(map(count => count > 0)), badge$: interWarehouseReceptionCount$, badgeTooltip: 'Inter-warehouses receptions in waiting',
            items: [
              {label: 'Reception', icon: 'fa-solid fa-people-carry-box', routerLink: ['/receptions/manual']},
              {
                label: 'Inter-warehouses reception', icon: 'fa-solid fa-cubes-stacked', routerLink: ['/receptions/inter-warehouse'], badgeSeverity: 'warning',
                showBadge$: interWarehouseReceptionCount$.pipe(map(count => count > 0)), badge$: interWarehouseReceptionCount$, badgeTooltip: 'Inter-warehouses receptions in waiting'
              },
              {
                label: 'Preparation', icon: 'fa-solid fa-dolly', routerLink: ['/preparation/list'],
                items: [
                  {label: 'Preparation setup', routerLink: ['/preparation/new'], visible: false},
                ]
              },
            ]
          },
          {
            label: 'Bulk movements', icon: 'fa-solid fa-cubes-stacked',
            items: [
              {label: 'Inter-warehouse transfer', icon: 'pi pi-cloud-upload', routerLink: ['/stocks/bulk/interwarehouse-transfer'],},
              {label: 'Bulk food (Ramasse)', icon: 'pi pi-list', routerLink: ['/articles/bulk/bulkfood'],},
              {label: 'Bulk food import', icon: 'pi pi-cloud', routerLink: ['/movements/bulk/bulkfoodimport'],},
            ],
          },
        ]
      },
      //     {
      //         label: 'UI Components',
      //         items: [
      //             { label: 'Form Layout', icon: 'pi pi-fw pi-id-card', routerLink: ['/uikit/formlayout'] },
      //             { label: 'Input', icon: 'pi pi-fw pi-check-square', routerLink: ['/uikit/input'] },
      //             { label: 'Float Label', icon: 'pi pi-fw pi-bookmark', routerLink: ['/uikit/floatlabel'] },
      //             { label: 'Invalid State', icon: 'pi pi-fw pi-exclamation-circle', routerLink: ['/uikit/invalidstate'] },
      //             { label: 'Button', icon: 'pi pi-fw pi-box', routerLink: ['/uikit/button'] },
      //             { label: 'Table', icon: 'pi pi-fw pi-table', routerLink: ['/uikit/table'] },
      //             { label: 'List', icon: 'pi pi-fw pi-list', routerLink: ['/uikit/list'] },
      //             { label: 'Tree', icon: 'pi pi-fw pi-share-alt', routerLink: ['/uikit/tree'] },
      //             { label: 'Panel', icon: 'pi pi-fw pi-tablet', routerLink: ['/uikit/panel'] },
      //             { label: 'Overlay', icon: 'pi pi-fw pi-clone', routerLink: ['/uikit/overlay'] },
      //             { label: 'Media', icon: 'pi pi-fw pi-image', routerLink: ['/uikit/media'] },
      //             { label: 'Menu', icon: 'pi pi-fw pi-bars', routerLink: ['/uikit/menu'], routerLinkActiveOptions: { paths: 'subset', queryParams: 'ignored', matrixParams: 'ignored', fragment: 'ignored' } },
      //             { label: 'Message', icon: 'pi pi-fw pi-comment', routerLink: ['/uikit/message'] },
      //             { label: 'File', icon: 'pi pi-fw pi-file', routerLink: ['/uikit/file'] },
      //             { label: 'Chart', icon: 'pi pi-fw pi-chart-bar', routerLink: ['/uikit/charts'] },
      //             { label: 'Misc', icon: 'pi pi-fw pi-circle', routerLink: ['/uikit/misc'] }
      //         ]
      //     },
      //     {
      //         label: 'Prime Blocks',
      //         items: [
      //             { label: 'Free Blocks', icon: 'pi pi-fw pi-eye', routerLink: ['/blocks'], badge: 'NEW' },
      //             { label: 'All Blocks', icon: 'pi pi-fw pi-globe', url: ['https://www.primefaces.org/primeblocks-ng'], target: '_blank' },
      //         ]
      //     },
      //     {
      //         label: 'Utilities',
      //         items: [
      //             { label: 'PrimeIcons', icon: 'pi pi-fw pi-prime', routerLink: ['/utilities/icons'] },
      //             { label: 'PrimeFlex', icon: 'pi pi-fw pi-desktop', url: ['https://www.primefaces.org/primeflex/'], target: '_blank' },
      //         ]
      //     },
      //     {
      //         label: 'Pages',
      //         icon: 'pi pi-fw pi-briefcase',
      //         items: [
      //             {
      //                 label: 'Landing',
      //                 icon: 'pi pi-fw pi-globe',
      //                 routerLink: ['/landing']
      //             },
      //             {
      //                 label: 'Auth',
      //                 icon: 'pi pi-fw pi-user',
      //                 items: [
      //                     {
      //                         label: 'Login',
      //                         icon: 'pi pi-fw pi-sign-in',
      //                         routerLink: ['/auth/login']
      //                     },
      //                     {
      //                         label: 'Error',
      //                         icon: 'pi pi-fw pi-times-circle',
      //                         routerLink: ['/auth/error']
      //                     },
      //                     {
      //                         label: 'Access Denied',
      //                         icon: 'pi pi-fw pi-lock',
      //                         routerLink: ['/auth/access']
      //                     }
      //                 ]
      //             },
      //             {
      //                 label: 'Crud',
      //                 icon: 'pi pi-fw pi-pencil',
      //                 routerLink: ['/pages/crud']
      //             },
      //             {
      //                 label: 'Timeline',
      //                 icon: 'pi pi-fw pi-calendar',
      //                 routerLink: ['/pages/timeline']
      //             },
      //             {
      //                 label: 'Not Found',
      //                 icon: 'pi pi-fw pi-exclamation-circle',
      //                 routerLink: ['/notfound']
      //             },
      //             {
      //                 label: 'Empty',
      //                 icon: 'pi pi-fw pi-circle-off',
      //                 routerLink: ['/pages/empty']
      //             },
      //         ]
      //     },
      //     {
      //         label: 'Hierarchy',
      //         items: [
      //             {
      //                 label: 'Submenu 1', icon: 'pi pi-fw pi-bookmark',
      //                 items: [
      //                     {
      //                         label: 'Submenu 1.1', icon: 'pi pi-fw pi-bookmark',
      //                         items: [
      //                             { label: 'Submenu 1.1.1', icon: 'pi pi-fw pi-bookmark' },
      //                             { label: 'Submenu 1.1.2', icon: 'pi pi-fw pi-bookmark' },
      //                             { label: 'Submenu 1.1.3', icon: 'pi pi-fw pi-bookmark' },
      //                         ]
      //                     },
      //                     {
      //                         label: 'Submenu 1.2', icon: 'pi pi-fw pi-bookmark',
      //                         items: [
      //                             { label: 'Submenu 1.2.1', icon: 'pi pi-fw pi-bookmark' }
      //                         ]
      //                     },
      //                 ]
      //             },
      //             {
      //                 label: 'Submenu 2', icon: 'pi pi-fw pi-bookmark',
      //                 items: [
      //                     {
      //                         label: 'Submenu 2.1', icon: 'pi pi-fw pi-bookmark',
      //                         items: [
      //                             { label: 'Submenu 2.1.1', icon: 'pi pi-fw pi-bookmark' },
      //                             { label: 'Submenu 2.1.2', icon: 'pi pi-fw pi-bookmark' },
      //                         ]
      //                     },
      //                     {
      //                         label: 'Submenu 2.2', icon: 'pi pi-fw pi-bookmark',
      //                         items: [
      //                             { label: 'Submenu 2.2.1', icon: 'pi pi-fw pi-bookmark' },
      //                         ]
      //                     },
      //                 ]
      //             }
      //         ]
      //     },
      //     {
      //         label: 'Get Started',
      //         items: [
      //             {
      //                 label: 'Documentation', icon: 'pi pi-fw pi-question', routerLink: ['/documentation']
      //             },
      //             {
      //                 label: 'View Source', icon: 'pi pi-fw pi-search', url: ['https://github.com/primefaces/sakai-ng'], target: '_blank'
      //             }
      //         ]
      //     }
    ];

    const initialMenu = of({key: 'home', routeEvent: true} as MenuChangeEvent);
    this.layoutService.breadCrumbItems$ = concat(initialMenu, this.menuService.menuSource$)
      .pipe(
        filter(menuChangeEvent => menuChangeEvent.routeEvent === true),
        map(menuChangeEvent => this.buildBreadCrumbs(menuChangeEvent)),
        tap(menuItems => console.log('Bread crumbs: ' + JSON.stringify(menuItems))),
      );
  }

  private buildBreadCrumbs(menuChangeEvent: MenuChangeEvent): MenuItem[] {
    const menuItems: MenuItem[] = [];
    let currentMenuItem = this.model[0];

    const menuKeyPath = menuChangeEvent.key.split('-');
    for (let i = 1; i < menuKeyPath.length; i++) {
      const index = parseInt(menuKeyPath[i]);
      currentMenuItem = currentMenuItem.items![index];
      menuItems.push(currentMenuItem);
    }

    return menuItems;
  }
}
