<p-table #dt
         [value]="(memberPage.value()?.content) || []"
         responsiveLayout="scroll"
         [rows]="DEFAULT_ROWS_PER_PAGE"
         [totalRecords]="memberPage.value()?.totalElements || 0"
         [globalFilterFields]="['depot']"
         [paginator]="true"
         [rowsPerPageOptions]="[15,25,50,100]"
         [showCurrentPageReport]="true"
         currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
         [lazy]="true"
         (onLazyLoad)="handleLazyLoad($event)"
         [rowHover]="true"
         [resizableColumns]="true"
         columnResizeMode="expand"
         [autoLayout]="true"
         sortMode="multiple"
         dataKey="id">
  <ng-template pTemplate="caption">
    <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
       <span class="flex">
        <h2 class="m-0">Members</h2>
        <p-multiSelect
          ngDefaultControl
          [options]="ALL_COLUMNS"
          [(ngModel)]="displayedColumns"
          placeholder="Select columns"
        >
          <ng-template let-selectedColumns pTemplate="selectedItems">
            <p-chips [ngModel]="selectedColumns" [disabled]="true">
              <ng-template let-selectedColumn pTemplate="item">
                {{ getColumnLabel(selectedColumn) }}
              </ng-template>
            </p-chips>
          </ng-template>
          <ng-template let-column pTemplate="item">
                {{ getColumnLabel(column) }}
          </ng-template>
          <ng-template let-country pTemplate="footer">
            <div class="py-2 px-3">
                <strong>{{ displayedColumns().length }} item(s) selected.</strong>
            </div>
          </ng-template>
        </p-multiSelect>
      </span>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr style="vertical-align: bottom">
      @for (column of displayedColumns(); track column) {
        @switch (column) {
          @case ('familyName') {
            <th pSortableColumn="familyName">
              <span i18n>{{ getColumnLabel(MemberColumn.familyName) }}</span>
              <p-sortIcon field="familyName"/>
            </th>
          }
          @case ('firstName') {
            <th pSortableColumn="firstName">
              <span i18n>{{ getColumnLabel(MemberColumn.firstName) }}</span>
              <p-sortIcon field="firstName"/>
            </th>
          }
          @case ('organization') {
            <th>
              <span i18n>{{ getColumnLabel(MemberColumn.organization) }}</span>
            </th>
          }
          @case ('email') {
            <th>
              <span i18n>{{ getColumnLabel(MemberColumn.email) }}</span>
              <p-sortIcon field="email"/>
            </th>
          }
          @case ('phone') {
            <th>
              <span i18n>{{ getColumnLabel(MemberColumn.phone) }}</span>
            </th>
          }
          @case ('address') {
            <th>
              <span i18n>{{ getColumnLabel(MemberColumn.address) }}</span>
            </th>
          }
          @case ('zip') {
            <th>
              <span i18n>{{ getColumnLabel(MemberColumn.zip) }}</span>
              <p-sortIcon field="zip"/>
            </th>
          }
          @case ('city') {
            <th>
              <span i18n>{{ getColumnLabel(MemberColumn.city) }}</span>
              <p-sortIcon field="city"/>
            </th>
          }
          @case ('role') {
            <th>
              <span i18n>{{ getColumnLabel(MemberColumn.role) }}</span>
            </th>
          }
        }
      }
      <!-- actions -->
      <th></th>
    </tr>
    <tr>
      @for (column of displayedColumns(); track column) {
        @switch (column) {
          @case ('familyName') {
            <th>
              <input pInputText type="text" [placeholder]="'Search...'" [(ngModel)]="filterFamilyNameContains" ngDefaultControl/>
            </th>
          }
          @case ('firstName') {
            <th>
              <input pInputText type="text" [placeholder]="'Search...'" [(ngModel)]="filterFirstNameContains" ngDefaultControl/>
            </th>
          }
          @case ('organization') {
            <th>
              <p-columnFilter field="organizations" matchMode="in" [showMenu]="false">
                <ng-template pTemplate="filter" let-organization>
                  <foodbank-organization-selection ngDefaultControl
                                                   [(ngModel)]="filterOrganization"
                                                   [organizationSearch]="organizationSearch()"
                  />
                </ng-template>
              </p-columnFilter>
            </th>
          }
          @case ('email') {
            <th pSortableColumn="email">

            </th>
          }
          @case ('phone') {
            <th>

            </th>
          }
          @case ('address') {
            <th>

            </th>
          }
          @case ('zip') {
            <th>
              <input pInputText type="text" [placeholder]="'Search...'" [(ngModel)]="filterZipContains" ngDefaultControl/>
            </th>
          }
          @case ('city') {
            <th>
              <input pInputText type="text" [placeholder]="'Search...'" [(ngModel)]="filterCityContains" ngDefaultControl/>
            </th>
          }
          @case ('role') {
            <th>
              <input pInputText type="text" [placeholder]="'Search...'" [(ngModel)]="filterCityContains" ngDefaultControl/>
            </th>
          }
        }
      }
      <!-- actions -->
      <th></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-row>
    @let member = identity(row);
    <tr>
      @for (column of displayedColumns(); track column) {
        @switch (column) {
          @case ('familyName') {
            <td>
              {{ member.familyName }}
            </td>
          }
          @case ('firstName') {
            <td>
              {{ member.firstName }}
            </td>
          }
          @case ('organization') {
            <td>
              <foodbank-organization ngDefaultControl [ngModel]="member.organization.value()" [showId]="false"/>
            </td>
          }
          @case ('email') {
            <td>
              {{ member.email }}
            </td>
          }
          @case ('phone') {
            <td>
              {{ generateTelGsm(member) }}
            </td>
          }
          @case ('address') {
            <td>
              @let completeAddress = getAddress(member);
              <a href="http://maps.google.com/maps?q={{ completeAddress }}" target="_blank">
                {{ completeAddress }}
              </a>
            </td>
          }
          @case ('zip') {
            <td>
              {{ member.zip }}
            </td>
          }
          @case ('city') {
            <td>
              {{ member.city }}
            </td>
          }
          @case ('role') {
            <td>
              <foodbank-role [ngModel]="member.role.value()" ngDefaultControl/>
            </td>
          }
        }
      }
      <td>
        <button pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-button-primary mr-2" (click)="openEditDialog(member)"></button>
      </td>
    </tr>
  </ng-template>
</p-table>
