<div class="card p-fluid" *ngIf="donation() as donation">
<form #donationForm="ngForm" style="padding: 1em">
  <div class="field grid">
    <label for="donation-donor" class="col-12 mb-2 md:col-2 md:mb-0" i18n>Donor</label>
    <div class="col-12 md:col-10">
      <foodbank-donor-selection  name="donation-donor" id="donation-donor" ngDefaultControl
                                 [(ngModel)]="donation.donor.value"
                                 [donorSearch]="donorSearch()"
      />
    </div>
  </div>
  <div class="field grid">
    <label for="donation-amount" class="col-12 mb-2 md:col-2 md:mb-0" i18n>Amount</label>
    <div class="col-12 md:col-10">
      <input id="donation-amount" type="number" name="amount" pInputText [(ngModel)]="donation.amount" ngDefaultControl>
    </div>
  </div>
  <div class="field grid">
    <label i18n="@@DonationDate" for="dateDonation" class="col-12 mb-2 md:col-2 md:mb-0">Birth Date</label>
    <div class="col-12 md:col-10">
      <input #dateDonation="ngModel" pInputText id="dateDonation" name="dateDonation" pattern="^(0?[1-9]|[12][0-9]|3[01])[\/](0?[1-9]|1[012])[\/\-]\d{4}$" placeholder="dd/mm/yyyy" [(ngModel)]="donation.dateDonation"/>
      <p-message i18n-text="@@DateDonationMustBeValid" *ngIf="dateDonation.invalid && donationForm.touched" severity="error" text="Donation date must in format dd/mm/yyyy"></p-message>
    </div>
  </div>
  <div class="field grid">
    <label for="donation-checked" class="col-12 mb-2 md:col-2 md:mb-0" i18n>Checked?</label>
    <div class="col-12 md:col-10">
      <p-inputSwitch id="donation-checked" name="donation-checked" [(ngModel)]="donation.checked"></p-inputSwitch>
    </div>
  </div>

  <div class="field grid">
    <div class="col-offset-3 col-3">
      <p-button label="Save" icon="pi pi-check" styleClass="p-button-primary" (onClick)="save(donation)"/>
    </div>
    <div class="col-3">
      <p-button label="Cancel" icon="pi pi-times" styleClass="p-button-secondary" (onClick)="close()"/>
    </div>
  </div>
</form>
</div>

