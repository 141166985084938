<p-table #dt
         [value]="(donorPage.value()?.content) || []"
         responsiveLayout="scroll"
         [rows]="DEFAULT_ROWS_PER_PAGE"
         [totalRecords]="donorPage.value()?.totalElements || 0"
         [globalFilterFields]="['depot']"
         [paginator]="true"
         [rowsPerPageOptions]="[15,25,50,100]"
         [showCurrentPageReport]="true"
         currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
         [lazy]="true"
         (onLazyLoad)="handleLazyLoad($event)"
         [rowHover]="true"
         [resizableColumns]="true"
         columnResizeMode="expand"
         [autoLayout]="true"
         sortMode="multiple"
         dataKey="id">
  <ng-template pTemplate="caption">
    <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
       <span class="flex">
        <h2 class="m-0">Donors</h2>
        <p-multiSelect
          ngDefaultControl
          [options]="ALL_COLUMNS"
          [(ngModel)]="displayedColumns"
          placeholder="Select columns"
        >
          <ng-template let-selectedColumns pTemplate="selectedItems">
            <p-chips [ngModel]="selectedColumns" [disabled]="true">
              <ng-template let-selectedColumn pTemplate="item">
                {{ getColumnLabel(selectedColumn) }}
              </ng-template>
            </p-chips>
          </ng-template>
          <ng-template let-column pTemplate="item">
                {{ getColumnLabel(column) }}
          </ng-template>
          <ng-template let-country pTemplate="footer">
            <div class="py-2 px-3">
                <strong>{{ displayedColumns().length }} item(s) selected.</strong>
            </div>
          </ng-template>
        </p-multiSelect>
      </span>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr style="vertical-align: bottom">
      @for (column of displayedColumns(); track column) {
        @switch (column) {
          @case ('familyName') {
            <th pSortableColumn="familyName">
              <span i18n>{{ getColumnLabel(DonorColumn.familyName) }}</span>
              <p-sortIcon field="familyName"/>
            </th>
          }
          @case ('firstName') {
            <th pSortableColumn="firstName">
              <span i18n>{{ getColumnLabel(DonorColumn.firstName) }}</span>
              <p-sortIcon field="firstName"/>
            </th>
          }
          @case ('address') {
            <th>
              <span i18n>{{ getColumnLabel(DonorColumn.address) }}</span>
            </th>
          }
          @case ('zip') {
            <th>
              <span i18n>{{ getColumnLabel(DonorColumn.zip) }}</span>
              <p-sortIcon field="zip"/>
            </th>
          }
          @case ('city') {
            <th>
              <span i18n>{{ getColumnLabel(DonorColumn.city) }}</span>
              <p-sortIcon field="city"/>
            </th>
          }
          @case ('email') {
            <th>
              <span i18n>{{ getColumnLabel(DonorColumn.email) }}</span>
              <p-sortIcon field="email"/>
            </th>
          }
        }
      }
      <!-- actions -->
      <th></th>
    </tr>
    <tr>
      @for (column of displayedColumns(); track column) {
        @switch (column) {
          @case ('familyName') {
            <th>
              <input pInputText type="text" [placeholder]="'Search...'" [(ngModel)]="filterFamilyNameContains" ngDefaultControl/>
            </th>
          }
          @case ('firstName') {
            <th>
              <input pInputText type="text" [placeholder]="'Search...'" [(ngModel)]="filterFirstNameContains" ngDefaultControl/>

            </th>
          }
          @case ('address') {
            <th>

            </th>
          }
          @case ('zip') {
            <th>
              <input pInputText type="number" [placeholder]="'Search...'" [(ngModel)]="filterZipEquals" ngDefaultControl/>
            </th>
          }
          @case ('city') {
            <th>
              <input pInputText type="text" [placeholder]="'Search...'" [(ngModel)]="filterCityContains" ngDefaultControl/>
            </th>
          }
          @case ('email') {
            <th >

            </th>
          }

        }
      }
      <!-- actions -->
      <th></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-row>
    @let donor = identity(row);
    <tr>
      @for (column of displayedColumns(); track column) {
        @switch (column) {
          @case ('familyName') {
            <td>
              {{ donor.familyName }}
            </td>
          }
          @case ('firstName') {
            <td>
              {{ donor.firstName }}
            </td>
          }

          @case ('address') {
            <td>
              @let completeAddress = getAddress(donor);
              <a href="http://maps.google.com/maps?q={{ completeAddress }}" target="_blank">
                {{ completeAddress }}
              </a>
            </td>
          }
          @case ('zip') {
            <td>
              {{ donor.zip }}
            </td>
          }
          @case ('city') {
            <td>
              {{ donor.city }}
            </td>
          }
          @case ('email') {
            <td>
              {{ donor.email }}
            </td>
          }
        }
      }
      <td>
        <button pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-button-primary mr-2" (click)="openEditDialog(donor)"></button>
      </td>
    </tr>
  </ng-template>
</p-table>
