import { inject, Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { Pagination } from './pagination';
import { SupplierSearch } from '@model/search/supplier-search';
import { Page } from '@typedefs/page';
import { SupplierDto, SupplierSearchDto } from '@typedefs/stock-rest';
import { Supplier } from '@model/supplier';
import { SupplierCategoryService } from '@services/supplier-category.service';
import { copyCommonFields } from "@model/mapping-utils";
import { TransferRequestService } from "@services/transfer-request.service";
import { TransferRequestItemService } from "@services/transfer-request-item.service";
import { StockSearchMappingService } from "@services/stock-search-mapping.service";
import { FoodbankCacheFactory } from './foodabank-cache-factory';
import { FoodbankCache } from './foodabank-cache';

@Injectable({
  providedIn: 'root'
})
export class SupplierService {

  #httpClient = inject(HttpClient);
  #supplierCategoryService = inject(SupplierCategoryService);
  #injector = inject(Injector);
  #transferRequestService = inject(TransferRequestService);
  #foodbankCacheFactory = inject(FoodbankCacheFactory);

  public getSupplier$(id: string, injector = this.#injector, cache = this.#foodbankCacheFactory.create(injector)): Observable<Supplier> {
    
    return this.#httpClient.get<SupplierDto>(`${environment.apiUrl}/suppliers/${id}`)
      .pipe(map(supplierDto => this.loadSupplier(supplierDto, cache)));
  }

  public findSuppliers$(supplierSearch: SupplierSearch, pagination?: Pagination): Observable<Page<Supplier>> {
    const supplierSearchDto = this.convertToSearchDto(supplierSearch);
    return this.#httpClient.post<Page<SupplierDto>>(`${environment.apiUrl}/suppliers/search`, supplierSearchDto, {params: pagination})
      .pipe(map(supplierPage => this.loadSupplierPage(supplierPage)));
  }

  public convertToSearchDto(supplierSearch: SupplierSearch): SupplierSearchDto {
    const stockSearchMappingService = this.#injector.get(StockSearchMappingService);
    const transferRequestItemService = this.#injector.get(TransferRequestItemService);

    const commonFields: Partial<SupplierSearchDto> = copyCommonFields(supplierSearch, ['supplierCategorySearch', 'suppliers', 'company', 'stockGroupSearch', 'transferRequestSearch', 'transferRequestItemSearch', 'stockGroupSearch']);

    return {
      ...commonFields,
      supplierCategorySearchDto: supplierSearch.supplierCategorySearch && this.#supplierCategoryService.convertToSearchDto(supplierSearch.supplierCategorySearch),
      supplierIds: supplierSearch.suppliers?.map(supplier => supplier.id),
      companyId: supplierSearch.company?.id,
      transferRequestSearchDto: supplierSearch.transferRequestSearch ? this.#transferRequestService.mapToTransferRequestSearchDto(supplierSearch.transferRequestSearch) : undefined,
      transferRequestItemSearchDto: supplierSearch.transferRequestItemSearch ? transferRequestItemService.mapToTransferRequestItemSearchDto(supplierSearch.transferRequestItemSearch) : undefined,
      stockGroupSearchDto: supplierSearch.stockGroupSearch ? stockSearchMappingService.mapToStockGroupSearchDto(supplierSearch.stockGroupSearch) : undefined,
    };
  }

  private loadSupplierPage(supplierPage: Page<SupplierDto>, injector = this.#injector, cache = this.#foodbankCacheFactory.create(injector)): Page<Supplier> {
    return {
      ...supplierPage,
      content: supplierPage.content.map(supplier => this.loadSupplier(supplier, cache))
    };
  }

  public loadSupplier(supplierDto: SupplierDto, cache: FoodbankCache): Supplier {
    return {
      ...supplierDto,
      supplierCategory$: supplierDto.supplierCategoryId ? this.#supplierCategoryService.findSupplierCategory$(supplierDto.supplierCategoryId) : undefined
    };
  }

  public createSupplier$(supplierDto: Partial<SupplierDto>, injector = this.#injector, cache = this.#foodbankCacheFactory.create(injector)): Observable<Supplier> {
    return this.#httpClient.post<SupplierDto>(`${environment.apiUrl}/suppliers`, supplierDto)
      .pipe(map(supplierDto => this.loadSupplier(supplierDto, cache)));
  }
}
