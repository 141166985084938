import {Component, computed, inject, Signal, signal, WritableSignal} from '@angular/core'
import {Region} from '@model/region';
import {RegionService} from '@services/region.service';

import {RegionSearch} from "@model/search/region-search";
import {Pagination} from "@services/pagination";
import {DEFAULT_ROWS_PER_PAGE, PaginationService} from '@services/pagination.service';
import {Page} from "@typedefs/page";
import {DialogService} from "primeng/dynamicdialog";
import {TableLazyLoadEvent, TableModule} from "primeng/table";
import {PrimeTemplate} from "primeng/api";
import {InputTextModule} from "primeng/inputtext";
import {FormsModule} from "@angular/forms";
import {NgIf} from "@angular/common";
import {derivedAsync} from "ngxtension/derived-async";

@Component({
  selector: 'foodbank-region-list',
  templateUrl: './region-list.component.html',
  styleUrls: ['./region-list.component.scss'],
  providers: [DialogService],
  imports: [TableModule, PrimeTemplate, InputTextModule, FormsModule, NgIf]

})

export class RegionListComponent {
  regionPage: Signal<Page<Region> | undefined>;

  regionSearch: Signal<RegionSearch>;

  pagination: WritableSignal<Pagination>;
  private regionService = inject(RegionService);
  private paginationService = inject(PaginationService);

  regNameContains = signal('');

  constructor() {
    this.regionSearch = computed(() => ({
        regNameContains: this.regNameContains(),
      })
    );

    this.pagination = this.paginationService.getDefaultPaginationSignal(DEFAULT_ROWS_PER_PAGE);

    this.regionPage = derivedAsync(() => this.regionService.findRegions$(this.regionSearch(), this.pagination()));
  }

  handleLazyLoad(event: TableLazyLoadEvent) {
    const pagination = this.paginationService.getTablePagination(event);
    this.pagination.set(pagination);
  }

  protected readonly DEFAULT_ROWS_PER_PAGE = DEFAULT_ROWS_PER_PAGE;

  identity(region: Region): Region {
    return region;
  }



}



