<div class="card p-fluid" *ngIf="cpas() as cpas">
  <form #cpasForm="ngForm" style="padding: 1em">
    <div class="field grid">
      <label for="cpas-name" class="col-12 mb-2 md:col-2 md:mb-0" i18n>Name</label>
      <div class="col-12 md:col-10">
        <input id="cpas-name" type="text" name="name" pInputText [(ngModel)]="cpas.name" ngDefaultControl>
      </div>
    </div>
    <div class="field grid">
      <label for="cpas-zip" class="col-12 mb-2 md:col-2 md:mb-0" i18n>Zip</label>
      <div class="col-12 md:col-10">
        <input id="cpas-zip" type="text" name="zip" pInputText [(ngModel)]="cpas.zip" ngDefaultControl>
      </div>
    </div>
    <div class="field grid">
      <label for="cpas-street" class="col-12 mb-2 md:col-2 md:mb-0" i18n>Street</label>
      <div class="col-12 md:col-10">
        <input id="cpas-street" type="text" name="city" pInputText [(ngModel)]="cpas.street" ngDefaultControl>
      </div>
    </div>

    <div class="field grid">
      <label for="cpas-mail" class="col-12 mb-2 md:col-2 md:mb-0" i18n>Email</label>
      <div class="col-12 md:col-10">
        <input id="cpas-mail" type="text" name="email" pInputText [(ngModel)]="cpas.mail" ngDefaultControl>
      </div>
    </div>
    <div class="field grid">
      <label for="cpas-phone" class="col-12 mb-2 md:col-2 md:mb-0" i18n>Phone</label>
      <div class="col-12 md:col-10">
        <input id="cpas-phone" type="text" pInputText name="phone" [(ngModel)]="cpas.phone" ngDefaultControl>
      </div>
    </div>
    <div class="field grid">
      <label for="cpas-mobile" class="col-12 mb-2 md:col-2 md:mb-0" i18n>Mobile</label>
      <div class="col-12 md:col-10">
        <input id="cpas-mobile" type="text" pInputText name="mobile" [(ngModel)]="cpas.mobile" ngDefaultControl>
      </div>
    </div>
    <div class="field grid">
      <label i18n="@@GenderContact" for="contactTitle">Contact Gender</label>
      <div class="col-12 md:col-10">
        <p-dropdown [options]="genders" id="contactTitle" name="contactTitle" [(ngModel)]="cpas.contactTitle"></p-dropdown>
      </div>
    </div>
    <div class="field grid">
      <label for="cpas-contactName" class="col-12 mb-2 md:col-2 md:mb-0" i18n>Contact Family Name</label>
      <div class="col-12 md:col-10">
        <input id="cpas-contactName" type="text" pInputText name="contactName" [(ngModel)]="cpas.contactName" ngDefaultControl>
      </div>
    </div>
    <div class="field grid">
      <label for="cpas-contactSurname" class="col-12 mb-2 md:col-2 md:mb-0" i18n>Contact First Name</label>
      <div class="col-12 md:col-10">
        <input id="cpas-contactSurname" type="text" pInputText name="contactSurname" [(ngModel)]="cpas.contactSurname" ngDefaultControl>
      </div>
    </div>
    <div class="field grid">
      <label for="cpas-remarks" class="col-12 mb-2 md:col-2 md:mb-0" i18n>Remarks</label>
      <div class="col-12 md:col-10">
        <input id="cpas-remarks" type="text" pInputText name="remarks" [(ngModel)]="cpas.remarks" ngDefaultControl>
      </div>
    </div>

    <div class="field grid">
      <div class="col-offset-3 col-3">
        <p-button label="Save" icon="pi pi-check" styleClass="p-button-primary" (onClick)="save(cpas)"/>
      </div>
      <div class="col-3">
        <p-button label="Cancel" icon="pi pi-times" styleClass="p-button-secondary" (onClick)="close()"/>
      </div>
    </div>
  </form>
</div>


