<div class="card p-fluid" *ngIf="donor() as donor">
  <form #donorForm="ngForm" style="padding: 1em">
    <div class="field grid">
      <label for="donor-title" class="col-12 mb-2 md:col-2 md:mb-0" i18n>Title</label>
      <div class="col-12 md:col-10">
        <input id="donor-title" type="text" name="title" pInputText [(ngModel)]="donor.title" ngDefaultControl>
      </div>
    </div>
    <div class="field grid">
      <label for="donor-familyName" class="col-12 mb-2 md:col-2 md:mb-0" i18n>Family Name</label>
      <div class="col-12 md:col-10">
        <input id="donor-familyName" type="text" name="familyName" pInputText [(ngModel)]="donor.familyName" ngDefaultControl>
      </div>
    </div>
    <div class="field grid">
      <label for="donor-firstName" class="col-12 mb-2 md:col-2 md:mb-0" i18n>first Name</label>
      <div class="col-12 md:col-10">
        <input id="donor-firstName" type="text" name="firstName" pInputText [(ngModel)]="donor.firstName" ngDefaultControl>
      </div>
    </div>
    <div class="field grid">
      <label for="donor-address" class="col-12 mb-2 md:col-2 md:mb-0" i18n>Address</label>
      <div class="col-12 md:col-10">
        <input id="donor-address" type="text" name="address" pInputText [(ngModel)]="donor.address" ngDefaultControl>
      </div>
    </div>
    <div class="field grid">
      <label for="donor-zip" class="col-12 mb-2 md:col-2 md:mb-0" i18n>Zip</label>
      <div class="col-12 md:col-10">
        <input id="donor-zip" type="number" name="zip" pInputText [(ngModel)]="donor.zip" ngDefaultControl>
      </div>
    </div>
    <div class="field grid">
      <label for="donor-city" class="col-12 mb-2 md:col-2 md:mb-0" i18n>City</label>
      <div class="col-12 md:col-10">
        <input id="donor-city" type="text" name="city" pInputText [(ngModel)]="donor.city" ngDefaultControl>
      </div>
    </div>

    <div class="field grid">
      <label for="donor-email" class="col-12 mb-2 md:col-2 md:mb-0" i18n>Email</label>
      <div class="col-12 md:col-10">
        <input id="donor-email" type="text" name="email" pInputText [(ngModel)]="donor.email" ngDefaultControl>
      </div>
    </div>

    <div class="field grid">
      <div class="col-offset-3 col-3">
        <p-button label="Save" icon="pi pi-check" styleClass="p-button-primary" (onClick)="save(donor)"/>
      </div>
      <div class="col-3">
        <p-button label="Cancel" icon="pi pi-times" styleClass="p-button-secondary" (onClick)="close()"/>
      </div>
    </div>
  </form>
</div>



