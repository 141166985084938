import {Component, inject, model, Resource} from '@angular/core';
import {PalletTypeService} from '@services/pallet-type.service';
import {PalletType} from '@model/pallet-type';
import {DropdownModule} from 'primeng/dropdown';
import {FormsModule} from '@angular/forms';
import {PrimeTemplate} from 'primeng/api';
import {PalletTypeComponent} from '../../pallet-type.component';
import {NgIf} from '@angular/common';
import {rxResource} from "@angular/core/rxjs-interop";
import {LanguageDirective} from "@directives/language.directive";
import {Page} from "@typedefs/page";
import {PaginationService} from "@services/pagination.service";
import {PalletTypeSearch} from "@model/search/pallet-type-search";

@Component({
  selector: 'foodbank-pallet-type-single-selection',
  templateUrl: './pallet-type-single-selection.component.html',
  styleUrl: './pallet-type-single-selection.component.scss',
  imports: [DropdownModule, FormsModule, PrimeTemplate, PalletTypeComponent, NgIf, LanguageDirective]
})
export class PalletTypeSingleSelectionComponent {

  ngModel = model<PalletType>();
  disabled = model(false);

  palletTypeSearch = model<PalletTypeSearch>({});

  palletTypePage: Resource<Page<PalletType>>;

  #palletTypeService = inject(PalletTypeService);
  #paginationService = inject(PaginationService);

  constructor() {
    const defaultComponentPagination = this.#paginationService.getDefaultComponentPaginationSignal();

    this.palletTypePage = rxResource({
      request: () => ({
        pagination: defaultComponentPagination(),
        palletTypeSearch: this.palletTypeSearch(),
      }),
      loader: params => this.#palletTypeService.findPalletTypes$(params.request.palletTypeSearch, params.request.pagination),
    });

  }

  clear() {
    this.ngModel.set(undefined);
  }
}
