import {Component, computed, inject, Injector, model, Resource, Signal, WritableSignal} from '@angular/core'
import {Donation} from '@model/donation';
import {DonationService} from '@services/donation.service';
import {UserService} from "@services/user.service";
import {DonationSearch} from "@model/search/donation-search";
import {Pagination} from "@services/pagination";
import {DEFAULT_ROWS_PER_PAGE, PaginationService} from '@services/pagination.service';
import {Page} from "@typedefs/page";
import {DialogService, DynamicDialogRef} from "primeng/dynamicdialog";
import {TableLazyLoadEvent, TableModule} from "primeng/table";
import {RippleModule} from "primeng/ripple";
import {InputTextModule} from "primeng/inputtext";
import {FormsModule} from "@angular/forms";
import {DonationEditComponent} from "@components/donation/donation-edit/donation-edit.component";
import {ButtonModule} from "primeng/button";
import {MultiSelectModule} from "primeng/multiselect";
import {rxResource} from "@angular/core/rxjs-interop";
import {Donor} from "@model/donor";
import {DonorSearch} from "@model/search/donor-search";
import {DonorSelectionComponent} from "@components/donor/selection/donor-selection.component";
import {DonorComponent} from "@components/donor/donor.component";
import {NgIf} from "@angular/common";

@Component({
  selector: 'foodbank-donations',
  templateUrl: './donation-list.component.html',
  styleUrls: ['./donation-list.component.scss'],
  providers: [DialogService],
  imports: [TableModule, InputTextModule, FormsModule, ButtonModule, RippleModule, MultiSelectModule, DonorSelectionComponent, DonorComponent, NgIf,]
})
export class DonationListComponent {

  // selected search filters for this view
  filterDonor = model<Donor>();

  // searches for filters (e.g. donor)
  donorSearch: Signal<DonorSearch>;
  // this view search
  donationSearch: Signal<DonationSearch>;
  pagination: WritableSignal<Pagination>;

  // results
  donationPage: Resource<Page<Donation> | undefined>;

  // internal state
  #dialogRef?: DynamicDialogRef;

  // services
  #donationService = inject(DonationService);
  #paginationService = inject(PaginationService);
  #userService = inject(UserService);
  #dialogService = inject(DialogService);
  #injector = inject(Injector);

  constructor() {
    const currentUserCompany = this.#userService.getCurrentUserCompany();
    this.donorSearch = computed(() => ({
      company: currentUserCompany(),
    }));

    this.donationSearch = computed(() => ({
      company: currentUserCompany(),
      donor: this.filterDonor(),
    }));

    this.pagination = this.#paginationService.getDefaultPaginationSignal(DEFAULT_ROWS_PER_PAGE);

    this.donationPage = rxResource({
      request: () => ({
        donationSearch: this.donationSearch(),
        pagination: this.pagination(),
      }),
      loader: param => this.#donationService.findDonations$(this.donationSearch(), this.#injector, this.pagination())
    });
  }

  handleLazyLoad(event: TableLazyLoadEvent) {
    const pagination = this.#paginationService.getTablePagination(event);
    this.pagination.set(pagination);
  }

  protected readonly DEFAULT_ROWS_PER_PAGE = DEFAULT_ROWS_PER_PAGE;

  identity(donation: Donation): Donation {
    return donation;
  }



  openEditDialog(donation: Donation) {
    this.#dialogRef = this.#dialogService.open(DonationEditComponent, {
      header: 'Edit Donation',
      width: '40%',
      data: {...donation}
    });
    this.#dialogRef?.onClose.subscribe(() => {
      this.donationPage.reload()
    })
  }




}




