import {Component, computed, inject, Injector, input, model, Resource, Signal, WritableSignal} from '@angular/core'
import {Donor} from '@model/donor';
import {DonorService} from '@services/donor.service';
import {UserService} from "@services/user.service";
import {DonorSearch} from "@model/search/donor-search";
import {Pagination} from "@services/pagination";
import {DEFAULT_ROWS_PER_PAGE, PaginationService} from '@services/pagination.service';
import {Page} from "@typedefs/page";
import {DialogService, DynamicDialogRef} from "primeng/dynamicdialog";
import {TableLazyLoadEvent, TableModule} from "primeng/table";
import {RippleModule} from "primeng/ripple";
import {PrimeTemplate} from "primeng/api";
import {InputTextModule} from "primeng/inputtext";
import {FormsModule} from "@angular/forms";
import {DonorEditComponent} from "@components/donor/donor-edit/donor-edit.component";
import {ButtonModule} from "primeng/button";
import {MultiSelectModule} from "primeng/multiselect";
import {joinWhenPresent} from "@util/string-utils";
import {injectLocalStorage} from "ngxtension/inject-local-storage";
import {ColumnLabels} from "@components/columnLabels";
import {ChipModule} from "primeng/chip";
import {ChipsModule} from "primeng/chips";
import {rxResource} from "@angular/core/rxjs-interop";

enum DonorColumn {
  familyName = 'familyName',
  firstName = 'firstName',
  address = 'address',
  zip = 'zip',
  city = 'city',
  email = 'email',
}

const COLUMN_LABELS: ColumnLabels<DonorColumn> = {
  familyName: 'Family Name',
  firstName: 'First Name',
  address: 'Address',
  zip: 'Zip',
  city: 'City',
  email: 'Email',
}

const FOODBANK_PREFERENCE_DONORS_LIST_COLUMNS = 'preference_donors_list_columns';

const ALL_COLUMNS: DonorColumn[] = Object.values(DonorColumn) as DonorColumn[];
const DEFAULT_COLUMNS: DonorColumn[] = [DonorColumn.familyName, DonorColumn.firstName, DonorColumn.address];

@Component({
  selector: 'foodbank-donors',
  templateUrl: './donor-list.component.html',
  styleUrls: ['./donor-list.component.scss'],
  providers: [DialogService],
  imports: [TableModule, PrimeTemplate, InputTextModule, FormsModule, ButtonModule, RippleModule, MultiSelectModule, ChipModule, ChipsModule,]
})
export class DonorListComponent {

  // selected search filters for this view
  filterFamilyNameContains = model('');
  filterFirstNameContains = model('');
  filterZipEquals = model(0);
  filterCityContains = model('');
  // this view search
  donorSearch: Signal<DonorSearch>;
  pagination: WritableSignal<Pagination>;

  // results
  donorPage: Resource<Page<Donor> | undefined>;

  // internal state
  #dialogRef?: DynamicDialogRef;

  // services
  #donorService = inject(DonorService);
  #paginationService = inject(PaginationService);
  #userService = inject(UserService);
  #dialogService = inject(DialogService);
  #injector = inject(Injector);
// columns view selection
  showColumnSelector = input(true);
  displayedColumns = injectLocalStorage<DonorColumn[]>(FOODBANK_PREFERENCE_DONORS_LIST_COLUMNS, {storageSync: true, defaultValue: DEFAULT_COLUMNS});

  constructor() {
    const currentUserCompany = this.#userService.getCurrentUserCompany();

    this.donorSearch = computed(() => ({
      company: currentUserCompany(),
      familyNameContains: this.filterFamilyNameContains(),
      firstNameContains: this.filterFirstNameContains(),
      zipEquals: this.filterZipEquals(),
      cityContains: this.filterCityContains(),
    }));

    this.pagination = this.#paginationService.getDefaultPaginationSignal(DEFAULT_ROWS_PER_PAGE);

    this.donorPage = rxResource({
      request: () => ({
        donorSearch: this.donorSearch(),
        pagination: this.pagination(),
      }),
      loader: param => this.#donorService.findDonors$(this.donorSearch(), this.#injector, this.pagination())
    });
  }

  handleLazyLoad(event: TableLazyLoadEvent) {
    const pagination = this.#paginationService.getTablePagination(event);
    this.pagination.set(pagination);
  }

  protected readonly DEFAULT_ROWS_PER_PAGE = DEFAULT_ROWS_PER_PAGE;

  identity(donor: Donor): Donor {
    return donor;
  }

  openEditDialog(donor: Donor) {
    this.#dialogRef = this.#dialogService.open(DonorEditComponent, {
      header: 'Edit Donor',
      width: '40%',
      data: {...donor}
    });
    this.#dialogRef?.onClose.subscribe(() => {
      this.donorPage.reload()
    })
  }

  getAddress(donor: Donor) {
    const location = joinWhenPresent(" ", [donor.zip.toString(), donor.city]);
    return joinWhenPresent(" ,", [donor.address, location]);
  }

  getColumnLabel(column: DonorColumn): string {
    return COLUMN_LABELS[column];
  }

  protected readonly ALL_COLUMNS = ALL_COLUMNS;
  protected readonly DonorColumn = DonorColumn;
}


