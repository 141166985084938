import { inject, Injectable, Injector } from '@angular/core';
import { rxResource } from '@angular/core/rxjs-interop';
import { Article } from '@model/article';
import { Supplier } from '@model/supplier';
import { Stock } from '@model/stock';
import { Observable, of } from 'rxjs';
import { read, utils } from 'xlsx';
import { ArticleService } from './article.service';
import { SupplierService } from './supplier.service';
import { StockService } from './stock.service';
import { switchMap, map } from 'rxjs/operators';
import { StockSearch } from '@model/search/stock-search';
import { FoodbankCacheFactory } from './foodabank-cache-factory';
import { MovementService } from './movement.service';
import { MovementType } from '@model/movement-type';
import { Movement } from '@model/movement';
import { Warehouse } from '@model/warehouse';

// Excel Column Mappings
const COLUMN_MAPPINGS = {
  yearMonth: 'JaarMaand',
  supplierName: 'Oorspong::Naam',
  supplierId: 'Oorsprong::FoodID',
  postalCode: 'Oorsprong::Postcode',
  city: 'Oorsprong::Gemeente',
  productCode: 'Productcode',
  productId: 'FoodID',
  article: 'Producten::Artikel',
  unit: 'Producten::Eenheid',
  quantity: 'R_AantalInop Productcode'
};

export interface ImportRow {
  rowNumber: number;
  yearMonth: string;
  supplierName: string;
  supplierId: string;
  postalCode: string;
  city: string;
  productCode: string;
  productId: string;
  article: string;
  unit: string;
  quantity: number;
  articleRef: ReturnType<typeof rxResource<Article | undefined, Error>>;
  supplierRef: ReturnType<typeof rxResource<Supplier | undefined, Error>>;
  stockRef: ReturnType<typeof rxResource<Stock | undefined, Error>>;
  recMovement: ReturnType<typeof rxResource<Movement | undefined, Error>>;
  isModified: boolean;
}

interface ExcelRow {
  __rowNum__: number;
  [key: string]: any;
}

@Injectable({
  providedIn: 'root'
})
export class BulkFoodImportService {
  #articleService = inject(ArticleService);
  #supplierService = inject(SupplierService);
  #stockService = inject(StockService);
  #foodbankCacheFactory = inject(FoodbankCacheFactory);
  #movementService = inject(MovementService);

  constructor() {}

  async importExcelFile(file: File, warehouse: Warehouse, injector: Injector): Promise<ImportRow[]> {
    const excelRows = await this.#readExcel(file);
    return this.#mapExcelToImportRows(excelRows, warehouse, injector);
  }

  async #readExcel(file: File): Promise<ExcelRow[]> {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);
      fileReader.onload = (e: ProgressEvent<FileReader>) => {
        try {
          const bufferArray = e.target?.result;
          const wb = read(bufferArray, {type: "buffer"});
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];

          const data = utils.sheet_to_json(ws);
          resolve(data.map((row: any, index: number) => ({ ...row, __rowNum__: index + 1 })));
        } catch (error) {
          reject(error);
        }
      };
    });
  }

  #mapExcelToImportRows(data: ExcelRow[], warehouse: Warehouse, injector: Injector, cache = this.#foodbankCacheFactory.create(injector)): ImportRow[] {
    return data.map(item => {
      const yearMonth = item[COLUMN_MAPPINGS.yearMonth];
      const productId = item[COLUMN_MAPPINGS.productId];
      const supplierId = item[COLUMN_MAPPINGS.supplierId];
      const productCode = item[COLUMN_MAPPINGS.productCode];

      return {
        rowNumber: item.__rowNum__,
        yearMonth,
        supplierName: item[COLUMN_MAPPINGS.supplierName],
        supplierId,
        postalCode: item[COLUMN_MAPPINGS.postalCode],
        city: item[COLUMN_MAPPINGS.city],
        productCode,
        productId,
        article: item[COLUMN_MAPPINGS.article],
        unit: item[COLUMN_MAPPINGS.unit],
        quantity: item[COLUMN_MAPPINGS.quantity],
        articleRef: cache.articleCache.getIfDefined(productId),
        supplierRef: cache.supplierCache.getIfDefined(supplierId),
        stockRef: rxResource<Stock | undefined, Error>({
          loader: () => this.loadStock$(productId, injector, cache),
          injector
        }),
        recMovement: rxResource<Movement | undefined, Error>({
          loader: () => this.findRecMovement$(warehouse, yearMonth, productId, supplierId, productCode, injector),
          injector
        }),
        isModified: false
      };
    });
  }

  loadStock$(productId: string, injector: Injector, cache = this.#foodbankCacheFactory.create(injector)): Observable<Stock | undefined> {
    if (!productId) return of(undefined);
      return this.#articleService.getArticle$(productId).pipe(
        switchMap(article => {
          if (!article) return of(undefined);
          const stockSearch: StockSearch = {
            articleSearch: {
              articles: [article]
            }
          };
          return this.#stockService.findStock(stockSearch, { page: 0, size: 1 }, injector).pipe(
            map(stockPage => stockPage.content[0])
          );
        })
      );
  }

  findRecMovement$(warehouse: Warehouse | undefined, yearMonth: string, productId: string, supplierId: string, productCode: string, injector: Injector): Observable<Movement | undefined> {
    if (!productId || !supplierId || !warehouse) return of(undefined);
    
    return this.#articleService.getArticle$(productId).pipe(
      switchMap(article => {
        if (!article) return of(undefined);
        return this.#supplierService.getSupplier$(supplierId).pipe(
          switchMap(supplier => {
            if (!supplier) return of(undefined);
            return this.#movementService.findMovements$({
              supplier: supplier,
              supplierBatchName: `RAMASSE ${yearMonth}`,
              warehouse: warehouse,
              commentContains: `[${productCode}]`,
              movementTypes: [{ movementKind: 'RECEIPT' } as MovementType]
            }, { page: 0, size: 1 }, injector).pipe(
              map(page => page.content[0])
            );
          })
        );
      })
    );
  }
} 