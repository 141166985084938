<p-table #dt
         [value]="(donationPage.value()?.content) || []"
         responsiveLayout="scroll"
         [rows]="DEFAULT_ROWS_PER_PAGE"
         [totalRecords]="donationPage.value()?.totalElements || 0"
         [globalFilterFields]="['depot']"
         [paginator]="true"
         [rowsPerPageOptions]="[15,25,50,100]"
         [showCurrentPageReport]="true"
         currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
         [lazy]="true"
         (onLazyLoad)="handleLazyLoad($event)"
         [rowHover]="true"
         [resizableColumns]="true"
         columnResizeMode="expand"
         [autoLayout]="true"
         sortMode="multiple"
         dataKey="id">

  <ng-template pTemplate="header">
    <tr style="vertical-align: bottom">
      <th pSortableColumn="donor.familyName">
        <span i18n>Donor</span>
        <p-sortIcon field="donor.familyName" />
      </th>

      <th pSortableColumn="amount">
        <span i18n>Amount</span>
        <p-sortIcon field="amount" />
      </th>
      <th pSortableColumn="dateDonation">
          <span i18n>Date Donation</span>
          <p-sortIcon field="dateDonation" />
      </th>

    </tr>
    <tr>
      <th>
        <p-columnFilter field="donors" matchMode="in" [showMenu]="false">
          <ng-template pTemplate="filter" let-donor>
            <foodbank-donor-selection ngDefaultControl
                                      [(ngModel)]="filterDonor"
                                      [donorSearch]="donorSearch()"
            />
          </ng-template>
        </p-columnFilter>
      </th>
      <th>

      </th>

      <th colspan="2">

      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-donation>
    <ng-container *ngIf="identity(donation) as donation">
      <tr>
        <td>
          <foodbank-donor ngDefaultControl [ngModel]="donation.donor.value()" [showId]="false"/>
        </td>
        <td>
          {{ donation.amount }}
        </td>
        <td>
          {{ donation.dateDonation }}
        </td>
        <td>
          <button pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-button-primary mr-2" (click)="openEditDialog(donation)"></button>
        </td>
      </tr>
    </ng-container>
  </ng-template>
</p-table>

