import { inject, Injectable } from "@angular/core";
import { StockGroupSearch } from "@model/search/stock-group-search";
import { InternalBatchSearchDto, StockGroupSearchDto, StockSearchDto, SupplierBatchSearchDto } from "@typedefs/stock-rest";
import { copyCommonFields } from "@model/mapping-utils";
import { ArticleService } from "@services/article.service";
import { TransferRequestService } from "@services/transfer-request.service";
import { DateService } from "@services/date.service";
import { StockSearch } from "@model/search/stock-search";
import { WarehouseService } from "@services/warehouse.service";
import { InternalBatchSearch } from "@model/search/internal-batch-search";
import { SupplierBatchSearch } from "@model/search/supplier-batch-search";

@Injectable({
  providedIn: 'root'
})
export class StockSearchMappingDependencyBreakerService {

  #articleService = inject(ArticleService);
  #warehouseService = inject(WarehouseService);
  #transferRequestService = inject(TransferRequestService);
  #dateSearchService = inject(DateService);

  mapToStockGroupSearchDto(stockGroupSearch: StockGroupSearch): StockGroupSearchDto {
    const commonFields: Partial<StockGroupSearchDto> = copyCommonFields(stockGroupSearch, ['stockGroup', 'article', 'articleSearch', 'palletType', 'reception', 'supplier', 'stockSearch', 'transferRequestSearch', 'excludedTransferRequestSearch', 'deliverBeforeDateSearch']);
    const stockSearch = stockGroupSearch.stockSearch;
    const stockSearchDto = stockSearch ? this.mapToStockSearchDto(stockSearch) : undefined;
    const transferRequestSearch = stockGroupSearch.transferRequestSearch;
    const excludedTransferRequestSearch = stockGroupSearch.excludedTransferRequestSearch;
    const deliverBeforeDateSearch = stockGroupSearch.deliverBeforeDateSearch;
    return {
      ...commonFields,
      articleId: stockGroupSearch.article?.id,
      articleSearchDto: !stockGroupSearch.articleSearch ? undefined : this.#articleService.mapToArticleSearchDto(stockGroupSearch.articleSearch),
      supplierId: stockGroupSearch.supplier?.id,
      receptionId: stockGroupSearch.reception?.id,
      palletTypeId: stockGroupSearch.palletType?.id,
      stockGroupId: stockGroupSearch.stockGroup?.id,
      stockSearchDto: stockSearchDto,
      transferRequestSearchDto: !transferRequestSearch ? undefined : this.#transferRequestService.mapToTransferRequestSearchDto(transferRequestSearch),
      excludedTransferRequestSearchDto: !excludedTransferRequestSearch ? undefined : this.#transferRequestService.mapToTransferRequestSearchDto(excludedTransferRequestSearch),
      deliverBeforeDateSearchDto: !deliverBeforeDateSearch ? undefined : this.#dateSearchService.mapToDateSearchDto(deliverBeforeDateSearch),
    }
  }

  mapToStockSearchDto(stockSearch: StockSearch): StockSearchDto {
    const commonFields: StockSearchDto | StockSearch = copyCommonFields(stockSearch, ['warehouseSearch', 'articleSearch', 'internalBatchSearch', 'supplierBatchSearch', 'palletSearch', 'preparationZone', 'excludedPreparation', 'stockGroupSearch']);

    return {
      ...commonFields,
      warehouseSearchDto: stockSearch.warehouseSearch && this.#warehouseService.mapToWarehouseSearchDto(stockSearch.warehouseSearch),
      articleSearchDto: stockSearch.articleSearch && this.#articleService.mapToArticleSearchDto(stockSearch.articleSearch),
      internalBatchSearchDto: stockSearch.internalBatchSearch && this.mapToInternalBatchSearchDto(stockSearch.internalBatchSearch),
      supplierBatchSearchDto: stockSearch.supplierBatchSearch && this.mapToSupplierBatchSearchDto(stockSearch.supplierBatchSearch),
      preparationZoneId: stockSearch.preparationZone?.location,
      excludedPreparationId: stockSearch.excludedPreparation?.id,
      stockGroupSearchDto: stockSearch.stockGroupSearch && this.mapToStockGroupSearchDto(stockSearch.stockGroupSearch),
    }
  }



  mapToInternalBatchSearchDto(internalBatchSearch: InternalBatchSearch): InternalBatchSearchDto {
    const commonFields: InternalBatchSearchDto | InternalBatchSearch = copyCommonFields(internalBatchSearch, ['stockSearch', 'preparation']);

    return {
      ...commonFields,
      stockSearchDto: internalBatchSearch.stockSearch && this.mapToStockSearchDto(internalBatchSearch.stockSearch),
      preparationId: internalBatchSearch?.preparation?.id
    }
  }

  mapToSupplierBatchSearchDto(supplierBatchSearch: SupplierBatchSearch): SupplierBatchSearchDto {
    return {
      ...supplierBatchSearch,
      stockSearchDto: supplierBatchSearch.stockSearch && this.mapToStockSearchDto(supplierBatchSearch.stockSearch)
    }
  }

}
