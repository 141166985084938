import {Component, inject, model, Resource} from '@angular/core';
import {ArticleSearch} from '@model/search/article-search';
import {ArticleService} from '@services/article.service';
import {Article} from '@model/article';
import {MultiSelectModule} from "primeng/multiselect";
import {FormsModule} from "@angular/forms";
import {NgForOf, NgIf} from "@angular/common";
import {ArticleComponent} from "@components/article/article.component";
import {Page} from "@typedefs/page";
import {rxResource} from "@angular/core/rxjs-interop";
import {PaginationService} from "@services/pagination.service";

@Component({
  selector: 'foodbank-article-multi-selection',
  templateUrl: './article-multi-selection.component.html',
  styleUrls: ['./article-multi-selection.component.scss'],
  imports: [
    MultiSelectModule,
    FormsModule,
    ArticleComponent,
    NgIf,
    NgForOf
  ]
})
export class ArticleMultiSelectionComponent {

  ngModel = model<Article[]>([]);
  articleSearch = model<ArticleSearch>({});

  $$articlePage: Resource<Page<Article>>;

  #articleService = inject(ArticleService);
  #paginationService = inject(PaginationService);

  constructor(articleService
              : ArticleService) {
    this.#articleService = articleService;

    const $pagination = this.#paginationService.getDefaultComponentPaginationSignal();

    this.$$articlePage = rxResource({
      request: () => ({
        pagination: $pagination(),
        search: this.articleSearch(),
      }),
      loader: params => this.#articleService.findArticles$(params.request.search, params.request.pagination),
    })
  }

  clear() {
    this.ngModel.set([]);
  }

}
