import {computed, inject, Injectable, Injector} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {map, Observable} from 'rxjs';
import {Page} from '@typedefs/page';
import {environment} from '@environments/environment';
import {Pagination} from './pagination';
import {TransferRequestItemDto, TransferRequestItemGroupDto, TransferRequestItemSearchDto} from '@typedefs/stock-rest';
import {copyCommonFields} from "@model/mapping-utils";
import {TransferRequestItem} from "@model/transfer-request-item";
import {TransferRequestItemSearch} from "@model/search/transfer-request-item-search";
import {TransferRequestItemGroup} from "@model/transfer-request-item.group";
import {TransferRequestTarget} from "@model/transfer-request-target";
import {Warehouse} from "@model/warehouse";
import {StockSearchMappingService} from "@services/stock-search-mapping.service";
import {FoodbankCacheFactory} from "@services/foodabank-cache-factory";
import {FoodbankCache} from "@services/foodabank-cache";

@Injectable({
  providedIn: 'root'
})
export class TransferRequestItemService {

  #httpClient = inject(HttpClient);
  #stockSearchMappingService = inject(StockSearchMappingService);
  #cacheFactory = inject(FoodbankCacheFactory);

  getTransferRequestItem$(transferRequestItemId: number, injector: Injector): Observable<TransferRequestItem> {
    const cache = this.#cacheFactory.create(injector);

    return this.#httpClient.get<TransferRequestItemDto>(`${environment.apiUrl}/transfer/request/item/${transferRequestItemId}`)
      .pipe(
        map(transferRequestItemDto => this.mapToTransferRequestItem(transferRequestItemDto, injector, cache)),
      );
  }

  findTransferRequestItems$(transferRequestItemSearch: TransferRequestItemSearch, pagination: Pagination, injector: Injector): Observable<Page<TransferRequestItem> | undefined> {
    const transferRequestItemSearchDto = this.mapToTransferRequestItemSearchDto(transferRequestItemSearch);

    const cache = this.#cacheFactory.create(injector);

    return this.#httpClient.post<Page<TransferRequestItemDto>>(`${environment.apiUrl}/transfer/request/item/search`, transferRequestItemSearchDto, {params: pagination})
      .pipe(map(transferRequestItemDtoPage => {
        const transferRequestItemDtos: TransferRequestItem[] = transferRequestItemDtoPage.content.map(transferRequestItemDto => this.mapToTransferRequestItem(transferRequestItemDto, injector, cache));
        return {
          ...transferRequestItemDtoPage,
          content: transferRequestItemDtos
        }
      }));
  }

  updateTransferRequestItem$(transferRequestItem: TransferRequestItem, injector: Injector): Observable<TransferRequestItem> {
    const cache = this.#cacheFactory.create(injector);
    const transferRequestItemDto = this.mapToTransferRequestItemDto(transferRequestItem);
    return this.#httpClient.post<TransferRequestItemDto>(`${environment.apiUrl}/transfer/request/item`, transferRequestItemDto)
      .pipe(
        map(transferRequestItemDto => this.mapToTransferRequestItem(transferRequestItemDto, injector, cache)),
      );
  }

  findTransferRequestItemGroups$(transferRequestItemSearch: TransferRequestItemSearch, pagination: Pagination, injector: Injector): Observable<Page<TransferRequestItemGroup>> {
    const transferRequestItemSearchDto = this.mapToTransferRequestItemSearchDto(transferRequestItemSearch);

    const cache = this.#cacheFactory.create(injector);

    // return of();
    //
    return this.#httpClient.post<Page<TransferRequestItemGroupDto>>(`${environment.apiUrl}/transfer/request/item/groups/search`, transferRequestItemSearchDto, {params: pagination})
      .pipe(map(transferRequestItemGroupDtoPage => {
        const transferRequestItemGroups: TransferRequestItemGroup[] = transferRequestItemGroupDtoPage.content.map(transferRequestItemGroupDto => this.mapToTransferRequestItemGroup(transferRequestItemGroupDto, injector, cache));
        return {
          ...transferRequestItemGroupDtoPage,
          content: transferRequestItemGroups
        }
      }));
  }

  mapToTransferRequestItemSearchDto(transferRequestItemSearch: TransferRequestItemSearch): TransferRequestItemSearchDto {
    const commonFields: TransferRequestItemSearchDto | TransferRequestItemSearch = copyCommonFields(transferRequestItemSearch, ['transferRequest', 'transferRequestTarget', 'stockGroupSearch']);
    const stockGroupSearch = transferRequestItemSearch.stockGroupSearch;
    const stockGroupSearchDto = !stockGroupSearch ? undefined : this.#stockSearchMappingService.mapToStockGroupSearchDto(stockGroupSearch);
    return {
      ...commonFields,
      transferRequestId: transferRequestItemSearch.transferRequest?.id,
      transferRequestTargetId: transferRequestItemSearch.transferRequestTarget?.id,
      stockGroupSearchDto: stockGroupSearchDto,
    };
  }

  mapToTransferRequestItemDto(transferRequestItem: TransferRequestItem,): TransferRequestItemDto | undefined {
    const commonFields: TransferRequestItemDto | TransferRequestItem = copyCommonFields(transferRequestItem, ['transferRequestTarget', 'stockGroup']);

    // we assume every signal or id resolves at this point, hence !
    const transferRequestTarget = transferRequestItem.transferRequestTarget.value()!;
    const stockGroup = transferRequestItem.stockGroup.value()!;

    return {
      ...commonFields,
      transferRequestTargetId: transferRequestTarget.id!,
      stockGroupId: stockGroup.id!,
    }
  }

  mapToTransferRequestItem(transferRequestItemDto: TransferRequestItemDto, injector: Injector, cache: FoodbankCache) {
    const commonFields: TransferRequestItemDto | TransferRequestItem = copyCommonFields(transferRequestItemDto, ['transferRequestTargetId', 'stockGroupId']);

    const transferRequestTarget = cache.transferRequestTargetCache.get(transferRequestItemDto.transferRequestTargetId);

    return {
      ...commonFields,
      stockGroup: cache.stockGroupCache.get(transferRequestItemDto.stockGroupId),
      transferRequestTarget: transferRequestTarget,
    }
  }

  mapToTransferRequestItemGroup(transferRequestItemGroupDto: TransferRequestItemGroupDto, injector: Injector, cache: FoodbankCache): TransferRequestItemGroup {
    const commonFields: TransferRequestItemGroupDto | TransferRequestItemGroup = copyCommonFields(transferRequestItemGroupDto, ['transferRequestId', 'stockGroupId', 'transferRequestItemDtos']);

    const warehouseTransferRequestItemMap = this.#getWarehouseIdTransferRequestItemMap(transferRequestItemGroupDto, injector, cache);

    return {
      ...commonFields,
      transferRequest: cache.transferRequestCache.get(transferRequestItemGroupDto.transferRequestId),
      stockGroup: cache.stockGroupCache.get(transferRequestItemGroupDto.stockGroupId),
      warehouseTransferRequestItemMap,
    }
  }

  #getWarehouseIdTransferRequestItemMap(transferRequestItemGroupDto: TransferRequestItemGroupDto, injector: Injector, cache: FoodbankCache) {
    const warehouseTransferRequestItems: TransferRequestItem[] = transferRequestItemGroupDto.transferRequestItemDtos.map(transferRequestItemDto => this.mapToTransferRequestItem(transferRequestItemDto, injector, cache));
    return computed(() => {
      const warehouseIdItemEntries: ([number, TransferRequestItem])[] = warehouseTransferRequestItems
        .map((item): [TransferRequestTarget | undefined, TransferRequestItem] => [item.transferRequestTarget.value(), item])
        .filter(([target]) => !!target)
        .map(([target, item]) => [target!.targetWarehouse.value(), item] as [Warehouse | undefined, TransferRequestItem])
        .filter(([warehouse]) => !!warehouse)
        .map(([warehouse, item]) => [warehouse!.id, item]);
      return new Map<number, TransferRequestItem>(warehouseIdItemEntries);
    });
  }

}
