import {Component, input, model} from '@angular/core';
import {User, UserBase} from "@model/user";
import {TooltipModule} from "primeng/tooltip";

@Component({
  selector: 'foodbank-user',
  templateUrl: './user.component.html',
  imports: [
    TooltipModule
  ],
  styleUrls: ['./user.component.scss']
})
export class UserComponent {
  ngModel = model<UserBase>();
  showId = input(false);
  showName = input(true);
}
