import {inject, Injectable, Injector} from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { map, Observable, shareReplay } from 'rxjs';
import { Page } from '@typedefs/page';
import { environment } from '@environments/environment';
import { Pagination } from './pagination';
import {PalletTypeDto, PalletTypeSearchDto, TransferRequestDto} from '@typedefs/stock-rest';
import { PalletType } from "@model/pallet-type";
import { PalletTypeSearch } from "@model/search/pallet-type-search";
import {TransferRequest} from "@model/transfer-request";
import {copyCommonFields} from "@model/mapping-utils";
import {StockGroupService} from "@services/stock-group.service";
import {StockSearchMappingService} from "@services/stock-search-mapping.service";

@Injectable({
  providedIn: 'root'
})
export class PalletTypeService {

  #httpClient = inject(HttpClient);
  #injector = inject(Injector);

  public getPalletTypes$(): Observable<PalletType[]> {
    return this.#httpClient.get<PalletType[]>(`${environment.apiUrl}/pallets/types`)
      .pipe(shareReplay());
  }

  public getPalletType$(id?: string): Observable<PalletType> {
    return this.#httpClient.get<PalletTypeDto>(`${environment.apiUrl}/pallets/types/${id}`)
      .pipe(
        map(palletTypeDto => this.mapToPalletType$(palletTypeDto)),
        shareReplay(),
      );
  }

  public findPalletTypes$(palletTypeSearch: PalletTypeSearch, pagination?: Pagination): Observable<Page<PalletType>> {
    const palletTypeSearchDto = this.mapToPalletTypeSearchDto(palletTypeSearch);
    return this.#httpClient.post<Page<PalletTypeDto>>(`${environment.apiUrl}/pallets/types/search`, palletTypeSearchDto, {params: pagination})
      .pipe(map(palletTypePage => this.loadPalletTypePage(palletTypePage)));
  }

  private loadPalletTypePage(palletTypeDtoPage: Page<PalletTypeDto>): Page<PalletType> {
    return {
      ...palletTypeDtoPage,
      content: this.loadPalletTypeDetailsList(palletTypeDtoPage.content)
    };
  }

  public loadPalletTypeDetailsList(palletTypeDtos: PalletTypeDto[]) {
    return palletTypeDtos.map(palletType => this.mapToPalletType$(palletType));
  }

  updatePalletType(palletType: PalletTypeDto): Observable<PalletTypeDto> {
    return this.#httpClient.put<PalletTypeDto>(`${environment.apiUrl}/pallets/types/${palletType.id}`, palletType)
  }

  public mapToPalletType$(palletTypeDto: PalletTypeDto): PalletType {
    return {
      ...palletTypeDto,
    };
  }

  mapToPalletTypeSearchDto(palletTypeSearch: PalletTypeSearch): PalletTypeSearchDto {
    const commonFields: PalletTypeSearchDto | PalletTypeSearch = copyCommonFields(palletTypeSearch, ['stockGroupSearch']);

    const stockSearchMappingService = this.#injector.get(StockSearchMappingService);

    const stockGroupSearch = palletTypeSearch.stockGroupSearch;

    return {
      ...commonFields,
      stockGroupSearchDto: stockGroupSearch ? stockSearchMappingService.mapToStockGroupSearchDto(stockGroupSearch) : undefined,
    }
  }

}
