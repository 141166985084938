import {inject, Injectable, Injector} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map, Observable} from 'rxjs';
import {environment} from '@environments/environment';
import {Pagination} from './pagination';
import {ArticleCategorySearch} from '@model/search/article-category-search';
import {Page} from '@typedefs/page';
import {ArticleCategoryDto, ArticleCategorySearchDto} from '@typedefs/stock-rest';
import {ArticleCategory} from '@model/article-category';
import {copyCommonFields} from "@model/mapping-utils";
import {StockSearchMappingService} from "@services/stock-search-mapping.service";

@Injectable({
  providedIn: 'root'
})
export class ArticleCategoryService {

  #httpClient = inject(HttpClient);
  #injector = inject(Injector);

  public findArticleCategory$(id: string): Observable<ArticleCategory> {
    return this.#httpClient.get<ArticleCategoryDto>(`${environment.apiUrl}/articles/categories/${id}`)
      .pipe(map(articleCategoryDto => this.loadArticleCategory(articleCategoryDto)));
  }

  public findArticleCategories$(articleCategorySearch: ArticleCategorySearch, pagination?: Pagination): Observable<Page<ArticleCategory>> {
    const articleCategorySearchDto = this.mapToArticleCategorySearchDto(articleCategorySearch);
    return this.#httpClient.post<Page<ArticleCategoryDto>>(`${environment.apiUrl}/articles/categories/search`, articleCategorySearchDto, {params: pagination})
      .pipe(map(articleCategoryPage => this.loadArticleCategoryPage(articleCategoryPage)));
  }

  private loadArticleCategoryPage(articleCategoryPage: Page<ArticleCategoryDto>): Page<ArticleCategory> {
    return {
      ...articleCategoryPage,
      content: articleCategoryPage.content.map(articleCategory => this.loadArticleCategory(articleCategory))
    };
  }

  public loadArticleCategory(articleCategoryDto: ArticleCategoryDto): ArticleCategory {
    return {
      ...articleCategoryDto
    };
  }

  public mapToArticleCategorySearchDto(articleCategorySearch: ArticleCategorySearch): ArticleCategorySearchDto {
    const commonFields: ArticleCategorySearchDto | ArticleCategorySearch = copyCommonFields(articleCategorySearch, ['articleCategories', 'stockGroupSearch']);

    const stockSearchMappingService = this.#injector.get(StockSearchMappingService);
    return {
      ...commonFields,
      articleCategoryIds: articleCategorySearch.articleCategories?.map(articleCategory => articleCategory.id),
      stockGroupSearchDto: articleCategorySearch.stockGroupSearch ? stockSearchMappingService.mapToStockGroupSearchDto(articleCategorySearch.stockGroupSearch) : undefined,
    };
  }
}
