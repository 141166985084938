import {inject, Injectable, Injector} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {map, Observable, shareReplay} from 'rxjs';
import {Page} from '@typedefs/page';
import {environment} from '@environments/environment';
import {Pagination} from './pagination';
import {MemberDto, MemberSearchDto} from '@typedefs/stock-rest';
import {Member} from "@model/member";
import {MemberSearch} from "@model/search/member-search";
import {copyCommonFields} from "@model/mapping-utils";
import {FoodbankCache} from "@services/foodabank-cache";
import {FoodbankCacheFactory} from "@services/foodabank-cache-factory";

@Injectable({
  providedIn: 'root'
})
export class MemberService {

  #httpClient = inject(HttpClient);
  #foodbankCacheFactory = inject(FoodbankCacheFactory);
  #injector = inject(Injector);

  public getMembers$(): Observable<Member[]> {
    return this.#httpClient.get<Member[]>(`${environment.apiUrl}/members`)
      .pipe(shareReplay());
  }

  public getMember$(id: string, cache = this.#foodbankCacheFactory.create(this.#injector)): Observable<Member> {
    return this.#httpClient.get<MemberDto>(`${environment.apiUrl}/members/${id}`)
      .pipe(
        map(memberDto => this.mapToMember(memberDto, cache)),
        shareReplay(),
      );
  }

  public findMembers$(memberSearch: MemberSearch, injector: Injector, pagination: Pagination, cache = this.#foodbankCacheFactory.create(injector)): Observable<Page<Member>> {
    const memberSearchDto = this.mapToMemberSearchDto(memberSearch);
    return this.#httpClient.post<Page<MemberDto>>(`${environment.apiUrl}/members/search`, memberSearchDto, {params: pagination})
      .pipe(map(memberPage => this.loadMemberPage(memberPage, cache)));
  }

  private loadMemberPage(memberDtoPage: Page<MemberDto>, cache: FoodbankCache): Page<Member> {
    return {
      ...memberDtoPage,
      content: this.loadMemberDetailsList(memberDtoPage.content, cache)
    };
  }

  public loadMemberDetailsList(memberDtos: MemberDto[], cache: FoodbankCache) {
    return memberDtos.map(member => this.mapToMember(member, cache));
  }

  updateMember(member: Member): Observable<Member> {
    const memberDto = this.mapToMemberDto(member);
    return this.#httpClient.put<MemberDto>(`${environment.apiUrl}/members/${member.id}`, memberDto)
      .pipe(map(memberDto => this.mapToMember(memberDto, this.#foodbankCacheFactory.create(this.#injector))));
  }

  public mapToMember(memberDto: MemberDto, cache: FoodbankCache): Member {
    const commonFields: Member | MemberDto = copyCommonFields(memberDto, ['companyId', 'roleId', 'organizationId', 'warehouseId']);
    return {
      ...commonFields,
      company: cache.companyCache.get(memberDto.companyId),
      organization: cache.organizationCache.getIfDefined(memberDto.organizationId),
      role: cache.roleCache.getIfDefined(memberDto.roleId),
      warehouse: cache.warehouseCache.getIfDefined(memberDto.warehouseId)
    }
  }

  mapToMemberDto(member: Member): MemberDto {
    const memberDto: MemberDto = copyCommonFields(member, ['company', 'role', 'organization','warehouse']) as MemberDto;

    return {
      ...memberDto,
      companyId: member.company.value()?.id!,
      roleId: member.role.value()?.id!,
      organizationId: member.organization.value()?.id!,
      warehouseId: member.warehouse.value()?.id!
    };
  }

  mapToMemberSearchDto(memberSearch: MemberSearch): MemberSearchDto {
    const memberSearchDto: MemberSearch | MemberSearchDto = copyCommonFields(memberSearch, ['company', 'organization']);
    return {
      ...memberSearchDto,
      companyId: memberSearch.company?.id,
      organizationId: memberSearch.organization?.id
    }
  }

}
