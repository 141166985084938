import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, inject, Injector, Output, ViewChild, signal, input, computed, Signal, WritableSignal, effect } from '@angular/core';
import { debounceTime, mergeMap, pipe } from 'rxjs';
import { Warehouse } from "@model/warehouse";
import { StockSearch } from "@model/search/stock-search";
import { PaginationService } from "@services/pagination.service";
import { StockService } from "@services/stock.service";
import { UserService } from "@services/user.service";
import { ArticleStorageConditionSearch } from "@model/search/article-storage-condition-search";
import { WarehouseSearch } from "@model/search/warehouse-search";
import { Article } from "@model/article";
import { ArticleStorageCondition } from "@model/article-storage-condition";
import { ArticleCategory } from "@model/article-category";
import { Page } from "@typedefs/page";
import { ArticleCategorySearch } from "@model/search/article-category-search";
import { ArticleSearch } from "@model/search/article-search";
import { Stock } from "@model/stock";
import { Pagination } from "@services/pagination";
import { TableLazyLoadEvent, TableModule } from "primeng/table";
import { createSetAndRemoveDragImage } from "@util/drag-and-drop";
import { Preparation } from "@model/preparation";
import { AsyncPipe, DatePipe, DecimalPipe, NgIf } from '@angular/common';
import { PrimeTemplate } from 'primeng/api';
import { ArticleStorageConditionSelectionComponent } from '../../../article-storage-condition/article-storage-condition-selection.component';
import { ArticleMultiSelectionComponent } from '../../../article/selection/multi/article-multi-selection.component';
import { DatePickerComponent } from '../../../date/date-picker/date-picker.component';
import { TooltipModule } from 'primeng/tooltip';
import { FormsModule } from '@angular/forms';
import { DragDropModule } from 'primeng/dragdrop';
import { ArticleStorageConditionComponent } from '../../../article-storage-condition/article-storage-condition.component';
import { ArticleComponent } from '../../../article/article.component';
import { PalletComponent } from '../../../pallet/pallet.component';
import { StockPallet } from "@model/stock-pallet";
import { PalletSingleSelectionComponent } from '@components/pallet/selection/single/pallet-single-selection.component';
import { toSignal } from '@angular/core/rxjs-interop';
import { overrideSignal } from "@util/foodbanks-signal-rxjs-interop";
import { InternalBatchMultiSelectionComponent } from '@components/internal-batch/selection/multi/internal-batch-multi-selection.component';
import { pipeSignal } from "@util/foodbanks-signal-rxjs-interop";
import { toObservable } from '@angular/core/rxjs-interop';
import { InputTextModule } from 'primeng/inputtext';

@Component({
    selector: 'foodbank-preparation-stock-preparation-table',
    templateUrl: './stock-preparation-table.component.html',
    styleUrl: './stock-preparation-table.component.scss',
    imports: [
      NgIf, 
      TableModule, 
      PrimeTemplate, 
      ArticleStorageConditionSelectionComponent, 
      ArticleMultiSelectionComponent, 
      DatePickerComponent, 
      TooltipModule, 
      FormsModule, 
      DragDropModule, 
      ArticleStorageConditionComponent, 
      ArticleComponent, 
      PalletComponent, 
      AsyncPipe, 
      DecimalPipe, 
      DatePipe, 
      PalletSingleSelectionComponent, 
      InternalBatchMultiSelectionComponent,
      InputTextModule
    ]
})
export class StockPreparationTableComponent implements AfterViewInit {

  protected readonly DEFAULT_ROWS_PER_PAGE = 15;

  warehouse = input<Warehouse | undefined>();
  stockFilter = input<StockSearch | undefined>();
  preparation = input<Preparation | undefined>();
  fead = input<boolean | undefined>(false);
  tableSizeStyleClass = input<string>(localStorage.getItem('FOODBANK_PREFERENCES_STOCK_TABLE_SIZE_OPTION') || '');
  @Output() onStockSelect = new EventEmitter<Stock[]>();
  @Output() onStockDragStart = new EventEmitter<Stock[]>();
  @Output() onStockDragEnd = new EventEmitter<void>();

  emptyMessage?: string;


  // searches for stock page
  selectedArticles = signal<Article[]>([]);
  overrideSelectedArticles = overrideSignal(this.selectedArticles);
  selectedArticleCategories = signal<ArticleCategory[]>([]);
  overrideSelectedArticleCategories = overrideSignal(this.selectedArticleCategories);
  selectedArticleStorageConditions = signal<ArticleStorageCondition[]>([]);
  overrideSelectedArticleStorageConditions = overrideSignal(this.selectedArticleStorageConditions);
  selectedBestBeforeDateRange = signal<Date | Date[] | undefined>(undefined);
  overrideSelectedBestBeforeDateRange = overrideSignal(this.selectedBestBeforeDateRange);

  stockSearch: Signal<StockSearch>;

  refreshTrigger = signal<void>(undefined);
  stockPage: Signal<Page<Stock> | undefined>;
  pagination: WritableSignal<Pagination>;

  // searches for filters
  filterWarehouseSearch: Signal<WarehouseSearch>;
  filterArticleSearch: Signal<ArticleSearch>;
  filterArticleCategorySearch = signal<ArticleCategorySearch>({});
  filterArticleStorageConditionSearch = signal<ArticleStorageConditionSearch>({});

  // distributionConfig$: BehaviorSubject<DistributionConfig | undefined>;
  selectedStocks: Stock[] = [];

  selectionEnabled = signal<boolean>(true);

  tableHeight!: string;
  @ViewChild('tableContainer')
  tableElementRef!: ElementRef;
  #injector = inject(Injector)

  selectedPallet = input<StockPallet | undefined>(undefined);
  overrideSelectedPallet = overrideSignal(this.selectedPallet);
  selectedInternalBatches = input<string[]>([]);
  overrideSelectedInternalBatches = overrideSignal(this.selectedInternalBatches);
  
  filterInternalBatchSearch = computed(() => ({
    stockSearch: {
      ...this.stockSearch(),
      internalBatchSearch: {}
    },
    preparation: this.preparation()
  }));

  filterPalletSearch;

  fullDescriptionContains = signal<string | undefined>(undefined);

  constructor(private paginationService: PaginationService,
              private stockService: StockService,
              private userService: UserService) {

    const currentUserCompany = toSignal(this.userService.getCurrentUserCompany$());
    this.pagination = this.paginationService.getDefaultPaginationSignal();

    this.filterWarehouseSearch = computed(() => ({
      company: currentUserCompany(),
      active: true
    }));

    const fullDescriptionContains$ = toObservable(this.fullDescriptionContains);
    const debouncedFullDescriptionContains$ = fullDescriptionContains$.pipe(debounceTime(500));
    const debouncedFullDescriptionContains = toSignal(debouncedFullDescriptionContains$);

    this.stockSearch = computed(() => {
      const selectedBestBeforeDateRange = this.overrideSelectedBestBeforeDateRange();
  
      return {
        ...(this.stockFilter() || {}),
        warehouseSearch: {
          warehouses: this.warehouse() ? [this.warehouse()!] : []
        },
        minBestBeforeDate: this.getMinBestBeforeDate(selectedBestBeforeDateRange),
        maxBestBeforeDate: this.getMaxBestBeforeDate(selectedBestBeforeDateRange),
        articleSearch: {
          articles: this.overrideSelectedArticles(),
          articleCategorySearch: {
            articleCategories: this.overrideSelectedArticleCategories()
          },
          articleStorageConditionSearch: {
            articleStorageConditions: this.overrideSelectedArticleStorageConditions()
          },
          fead: this.fead()
        },
        internalBatchSearch: {
          internalBatches: this.overrideSelectedInternalBatches()
        },
        palletSearch: {
          id: this.overrideSelectedPallet()?.id,
        },
        fullDescriptionContains: debouncedFullDescriptionContains(),
        excludedPreparation: this.preparation()
      } as StockSearch;
    });

    effect(() => {
      console.log('stockSearch', this.stockSearch());
    });

    this.filterArticleSearch = computed(() => ({
        articleCategorySearch: this.filterArticleCategorySearch(),
        articleStorageConditionSearch: this.filterArticleStorageConditionSearch(),
        stockSearch: this.stockSearch(),
        fead: this.fead()
      })
    );

    this.filterPalletSearch = computed(() => ({
      withStock: true,
      stockSearch: this.stockSearch()
    }));

    const stockPageParams: Signal<[StockSearch, Pagination, void]> = computed(() => [
      this.stockSearch(), 
      this.pagination(),
      this.refreshTrigger()
    ]);

    this.stockPage = pipeSignal(stockPageParams, pipe(
      debounceTime(10),
      mergeMap(([stockSearch, pagination, _]) => this.stockService.findStock(stockSearch, pagination, this.#injector))
    ));
  }

  ngAfterViewInit() {
    this.updateTableHeight();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.updateTableHeight();
  }

  updateTableHeight() {
    const tableOffsetTop = this.tableElementRef.nativeElement.getBoundingClientRect().top || 289;
    const windowHeight = window.innerHeight;
    const availableHeight = windowHeight - tableOffsetTop - 180; // Adjust for footer
    this.tableHeight = `${availableHeight}px`;
  }

  stockIdentity(stock: any): Stock {
    return stock;
  }

  loadStockList(event: TableLazyLoadEvent) {
    const pagination = this.paginationService.getTablePagination(event);
    this.pagination.set(pagination);
  }

  isStockDraggable(stock: Stock): boolean {
    if (!this.selectionEnabled()) {
      return false;
    }

    return !this.selectedStocks || this.selectedStocks.length === 0 || this.selectedStocks.includes(stock);
  }

  notifyStockDragStart(event: DragEvent, stock: Stock) {
    const value = this.selectedStocks.length === 0
      ? [stock]
      : this.selectedStocks;
    this.onStockDragStart.emit(value);

    const selectedPallets = this.selectedStocks.length === 0 ? 1 : this.selectedStocks.length
    const text = `Moving ${selectedPallets} pallet${selectedPallets > 1 ? 's' : ''}`;

    createSetAndRemoveDragImage(event, text);
  }

  notifyStockDragEnd(event: DragEvent) {
    if (event.dataTransfer && event.dataTransfer.dropEffect !== 'none') {
      this.selectedStocks = [];
    }
    this.onStockDragEnd.emit();
    this.refreshTrigger.set(undefined);
  }

  notifySelectionChange(stock: Stock[]) {
    this.selectedStocks = stock;
    this.onStockSelect.emit(stock);
  }


  getMinBestBeforeDate(selectedBestBeforeDateRange: Date | Date[] | undefined): Date | undefined {
    if (Array.isArray(selectedBestBeforeDateRange)) {
      return selectedBestBeforeDateRange[0];
    }

    return undefined;
  }

  getMaxBestBeforeDate(selectedBestBeforeDateRange: Date | Date[] | undefined): Date | undefined {
    if (Array.isArray(selectedBestBeforeDateRange)) {
      return selectedBestBeforeDateRange.length === 2 ? selectedBestBeforeDateRange[1] : undefined;
    }

    return selectedBestBeforeDateRange;
  }

}
