import {UserDto} from '@typedefs/stock-rest';
import {EMPTY, Observable} from 'rxjs';
import {Warehouse} from '@model/warehouse';
import {Organization} from '@model/organization';
import {Company} from '@model/company';
import {SafeOmit} from "@model/mapping-utils";
import {Resource} from "@angular/core";

export interface UserBase extends SafeOmit<UserDto, 'companyId' | 'warehouseId' | 'organizationId' | 'bankId'> {
}

/**
 * @deprecated use UserS
 */
export interface User extends UserBase {
  company$: Observable<Company>;
  warehouse$: Observable<Warehouse | undefined>;
  organization$?: Observable<Organization>;
}

/**
 * TODO: remove User, this should be the new User (rename).
 */
export interface UserS extends UserBase {
  company: Resource<Company>;
  warehouse: Resource<Warehouse | undefined>;
  organization: Resource<Organization | undefined>;
}

export function userNotFound(userId: string): User {
  return {
    id: userId,
    name: `${userId} (missing)`,
    company$: EMPTY,
    warehouse$: EMPTY,
    organization$: EMPTY,
    language: '',
    type: '',
    rights: '',
  };
}
