import {Component, computed, inject, Injector, input, model, Signal, WritableSignal} from '@angular/core'
import {Cpas} from '@model/cpas';
import {CpasService} from '@services/cpas.service';
import {UserService} from "@services/user.service";
import {CpasSearch} from "@model/search/cpas-search";
import {Pagination} from "@services/pagination";
import {DEFAULT_ROWS_PER_PAGE, PaginationService} from '@services/pagination.service';
import {Page} from "@typedefs/page";
import {DialogService, DynamicDialogRef} from "primeng/dynamicdialog";
import {TableLazyLoadEvent, TableModule} from "primeng/table";
import {RippleModule} from "primeng/ripple";
import {PrimeTemplate} from "primeng/api";
import {InputTextModule} from "primeng/inputtext";
import {FormsModule} from "@angular/forms";
import {derivedAsync} from "ngxtension/derived-async";
import {CpasEditComponent} from "@components/cpas/cpas-edit/cpas-edit.component";
import {ButtonModule} from "primeng/button";
import {joinWhenPresent} from "@util/string-utils";
import {ColumnLabels} from "@components/columnLabels";
import {ChipModule} from "primeng/chip";
import {ChipsModule} from "primeng/chips";
import {injectLocalStorage} from "ngxtension/inject-local-storage";
import {MultiSelectModule} from "primeng/multiselect";

enum CpasColumn {
  name = 'name',
  zip = 'zip',
  street = 'street',
  mail = 'mail',
  phone = 'phone',
  mobile = 'mobile',
  contactName = 'contactName',
  contactSurname = 'contactSurname',
  contactTitle = 'contactTitle',
  remarks = 'remarks',
 }


const COLUMN_LABELS: ColumnLabels<CpasColumn> = {
  name: 'Name',
  zip: 'Zip',
  street: 'Street',
  mail: 'Mail',
  phone: 'Phone',
  mobile: 'Mobile',
  contactName: 'Contact Name',
  contactSurname: 'Contact Surname',
  contactTitle: 'Contact Title',
  remarks: 'remarks',
}

const FOODBANK_PREFERENCE_CPASS_LIST_COLUMNS = 'preference_cpass_list_columns';

const ALL_COLUMNS: CpasColumn[] = Object.values(CpasColumn) as CpasColumn[];
const DEFAULT_COLUMNS: CpasColumn[] = [CpasColumn.name, CpasColumn.zip, CpasColumn.mobile, CpasColumn.phone, CpasColumn.mail];


@Component({
  selector: 'foodbank-cpass',
  templateUrl: './cpas-list.component.html',
  styleUrls: ['./cpas-list.component.scss'],
  providers: [DialogService],
  imports: [TableModule, PrimeTemplate, InputTextModule, FormsModule,  ButtonModule, RippleModule, MultiSelectModule,ChipModule, ChipsModule]
})
export class CpasListComponent {

  // selected search filters for this view
  filterNameContains = model('');
  filterZipContains = model('');



  // this view search
  cpasSearch: Signal<CpasSearch>;
  pagination: WritableSignal<Pagination>;

  // results
  cpasPage: Signal<Page<Cpas> | undefined>;

  // internal state
  #dialogRef?: DynamicDialogRef;

  // services
  #cpasService = inject(CpasService);
  #paginationService = inject(PaginationService);
  #userService = inject(UserService);
  #dialogService = inject(DialogService);
  #injector = inject(Injector);
// columns view selection
  showColumnSelector = input(true);
  displayedColumns = injectLocalStorage<CpasColumn[]>(FOODBANK_PREFERENCE_CPASS_LIST_COLUMNS, {storageSync: true, defaultValue: DEFAULT_COLUMNS});

  constructor() {
    const currentUserCompany = this.#userService.getCurrentUserCompany();
    this.cpasSearch = computed(() => ({
      nameContains: this.filterNameContains(),
      zipContains: this.filterZipContains(),
    }));

    this.pagination = this.#paginationService.getDefaultPaginationSignal(DEFAULT_ROWS_PER_PAGE);

    this.cpasPage = derivedAsync(() => this.#cpasService.findCpass$(this.cpasSearch(), this.pagination()));
  }

  handleLazyLoad(event: TableLazyLoadEvent) {
    const pagination = this.#paginationService.getTablePagination(event);
    this.pagination.set(pagination);
  }

  protected readonly DEFAULT_ROWS_PER_PAGE = DEFAULT_ROWS_PER_PAGE;

  identity(cpas: Cpas): Cpas {
    return cpas;
  }



  openEditDialog(cpas: Cpas) {
    this.#dialogRef = this.#dialogService.open(CpasEditComponent, {
      header: 'Edit Cpas',
      width: '40%',
      data: {...cpas}
    });
  }

  getAddress(cpas: Cpas) {

    return joinWhenPresent(" ", [cpas.street, cpas.zip]);
  }
  getColumnLabel(column: CpasColumn): string {
    return COLUMN_LABELS[column];
  }

  protected readonly ALL_COLUMNS = ALL_COLUMNS;
  protected readonly CpasColumn = CpasColumn;

}


