@let receptions = receptionPage.value()?.content || [];

<p-dropdown [(ngModel)]="ngModel"
            [options]="receptions"
            optionLabel="name"
            placeholder="None"
            [required]="required()"
            [filter]="true"
            [showClear]="true"
            dataKey="id"
            appendTo="body">
  <ng-template pTemplate="filter">
    <input type="text" pInputText pAutoFocus
           [autofocus]="true"
           placeholder="search"
           [(ngModel)]="receptionId"
    />
  </ng-template>
  <ng-template let-selectedItem pTemplate="selectedItem">
    @let reception = identity(selectedItem);
    <foodbank-reception ngDefaultControl [ngModel]="reception" />
  </ng-template>
  <ng-template let-item pTemplate="item">
    @let reception = identity(item);
    <foodbank-reception ngDefaultControl [ngModel]="reception" />
  </ng-template>
</p-dropdown>
