import {Component, inject, Injector, model} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {Member} from '@model/member';
import {MemberService} from '@services/member.service';
import {AsyncPipe, NgIf} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {InputTextModule} from 'primeng/inputtext';
import {InputSwitchModule} from 'primeng/inputswitch';
import {Button} from 'primeng/button';
import {RoleSelectionComponent} from "@components/role/selection/role-selection.component";
import {OrganizationSingleSelectionComponent} from "@components/organization/selection/single/organization-single-selection.component";
import {MessageService} from "primeng/api";
import {MessageModule} from "primeng/message";
import {
    WarehouseSingleSelectionComponent
} from "@components/warehouse/selection/single/warehouse-single-selection.component";
import {map, Observable, shareReplay} from "rxjs";
import {Warehouse} from "@model/warehouse";
import {UserService} from "@services/user.service";
import {WarehouseSearch} from "@model/search/warehouse-search";
import {DropdownModule} from "primeng/dropdown";
import {LanguageService} from "@services/language.service";
import {LanguageInfo} from "@model/languageInfo";
import {Language} from "@typedefs/stock-rest";

@Component({
  selector: 'foodbank-member-edit',
  templateUrl: './member-edit.component.html',
  styleUrls: ['./member-edit.component.scss'],
  imports: [NgIf, FormsModule, InputTextModule, RoleSelectionComponent, InputSwitchModule, Button, OrganizationSingleSelectionComponent, MessageModule, AsyncPipe, WarehouseSingleSelectionComponent, DropdownModule]
})
export class MemberEditComponent {

  member = model<Member>();

  ref = inject(DynamicDialogRef);
  config = inject(DynamicDialogConfig<Member>);
  #memberService = inject(MemberService);
  #messageService = inject(MessageService);
  #userService = inject(UserService);
  #languageService = inject(LanguageService);
  languages: LanguageInfo[];
  warehouse$: Observable<Warehouse | undefined>;
  warehouseSearch$: Observable<WarehouseSearch>
  genders: any[];
  selectedLanguage?: String;

  constructor() {
    this.member.set(this.config.data);
    this.warehouse$ = this.config.data?.warehouse$ || this.#userService.getDefaultWarehouse$();
    const currentUserCompany$ = this.#userService.getCurrentUserCompany$().pipe(shareReplay());
    this.warehouseSearch$ = currentUserCompany$.pipe(
      map(company => ({company}))
    );
    this.languages = this.#languageService.getLanguageOptions();
    this.genders = [{label: $localize`:@@PersonTitleMister:Mr.`, value: 1},
      {label: $localize`:@@PersonTitleLady:Mrs.`, value: 2},
      {label: $localize`:@@PersonTitleMiss:Miss`, value: 3}
    ];
    console.log( "editing member:", this.member());
    const language = this.languages.find(language => language.language === this.member()?.language);
    console.log( "languageInfo :", language);
    if (language) {
      this.selectedLanguage = language.language;
    }

  }

  save(member: Member) {

    const language = this.languages.find(language => language.language === this.selectedLanguage);
    if (language) {
        member.language = this.selectedLanguage as Language;
     }

    this.#memberService.updateMember(member)
      .subscribe(member => {
        this.member.set(member);
        this.ref.close(member);
        this.#messageService.add({severity: 'success', summary: 'Success', detail: 'Member updated'});
      });
  }

  close() {
    this.ref.close({});
  }



}
