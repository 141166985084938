import {computed, inject, Injectable, Resource, Signal} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {UserDto} from '@typedefs/stock-rest';
import {map, Observable} from 'rxjs';
import {environment} from '@environments/environment';
import {UserS} from '@model/user';
import {AuthenticationService} from "@services/authentication.service";
import {rxResource} from "@angular/core/rxjs-interop";
import {FoodbankCacheFactory} from "@services/foodabank-cache-factory";
import {copyCommonFields} from "@model/mapping-utils";
import {derivedAsync} from "ngxtension/derived-async";
import {Company} from "@model/company";

@Injectable({
  providedIn: 'root'
})
export class UserSignalService {

  readonly #currentUserS: Resource<UserS>;

  readonly #httpClient = inject(HttpClient);
  readonly #authenticationService = inject(AuthenticationService);
  readonly #foodbankCacheFactory = inject(FoodbankCacheFactory);

  constructor() {
    const ready = derivedAsync(() => this.#authenticationService.isReady$());
    this.#currentUserS = rxResource({
      request: () => ready(),
      loader: () => this.#loadCurrentUserS()
    });
  }

  get currentUserS(): Signal<UserS | undefined> {
    return this.#currentUserS.value;
  }

  get currentUserCompany(): Signal<Company | undefined> {
    return computed(() => this.#currentUserS.value()?.company.value());
  }

  #loadCurrentUserS(): Observable<UserS> {
    return this.#httpClient.get<UserDto>(`${environment.apiUrl}/users/me`).pipe(
      map(userDto => this.#mapToUser(userDto)),
    );
  }

  #mapToUser(userDto: UserDto): UserS {
    const commonFields: UserS | UserDto = copyCommonFields(userDto, ['warehouseId', 'companyId', 'organizationId']);

    const cache = this.#foodbankCacheFactory.getLongLivedCache();

    return {
      ...commonFields,
      company: cache.companyCache.get(userDto.companyId),
      warehouse: cache.warehouseCache.getIfDefined(userDto.warehouseId),
      organization: cache.organizationCache.getIfDefined(userDto.organizationId),
    };
  }

}
