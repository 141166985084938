<div class="flex flex-col align-items-center gap-3 mb-4">
  <label for="fileUpload" class="block text-2xl font-medium">{{ hasData() ? 'Imported data' : 'Upload Excel file' }}</label>
  <ng-container *ngIf="!hasData(); else actions">
    <p-fileUpload
      mode="basic"
      chooseLabel="Choose"
      chooseIcon="pi pi-upload"
      name="demo[]"
      url="https://www.primefaces.org/cdn/api/upload.php"
      maxFileSize="1000000"
      (onUpload)="onUpload($event)"
    />
  </ng-container>
  <ng-template #actions>
    <div class="flex gap-2">
      <p-button icon="pi pi-trash" severity="danger" (onClick)="clearData()" label="Clear data"></p-button>
      <p-button
        [label]="isSaving()
          ? 'Saving... ' + ((saveProgress()?.current || 0) + 1) + '/' + (saveProgress()?.total || 0)
          : 'Save' + (selectedRows().length ? ' (' + selectedRows().length + ' selected)' : '') + (modifiedRows().length ? ' (' + modifiedRows().length + ' unsaved)' : '')"
        icon="pi pi-save"
        [loading]="isSaving()"
        (onClick)="saveMovements()"
        [disabled]="!hasValidRows() || isSaving()">
      </p-button>
    </div>
  </ng-template>
</div>
<div class="flex flex-col gap-2 mb-3" *ngIf="hasData()">
  <div class="text-lg" [class.text-red-500]="problemRows().length > 0">{{ statusMessage() }}</div>
  <div class="flex align-items-center gap-2" *ngIf="problemRows().length > 0">
    <p-checkbox [(ngModel)]="showOnlyProblems" [binary]="true" inputId="problems"></p-checkbox>
    <label for="problems" class="cursor-pointer">Show only rows with problems</label>
  </div>
  <div class="text-sm text-gray-600" *ngIf="selectedRows().length > 0">
    {{ selectedRows().length }} rows selected
  </div>
</div>
<p-table
         loadingIcon="pi pi-spin pi-spinner big-loading-icon"
         [value]="filteredData() || []"
         [sortField]="'rowNumber'"
         [sortOrder]="1"
         [multiSortMeta]="[{field: 'rowNumber', order: 1}]"
         [sortMode]="'multiple'"
         class="p-datatable-gridlines p-datatable-sm"
         [totalRecords]="data().length || 0"
         [showCurrentPageReport]="true"
         [paginator]="true"
         [rows]="10"
         [rowsPerPageOptions]="[10, 25, 50, 100]"
         currentPageReportTemplate="Showing {first} to {last} of {totalRecords} items"
         [rowHover]="true"
         [resizableColumns]="true"
         columnResizeMode="expand"
         [autoLayout]="true"
         [selection]="selectedRows()"
         (selectionChange)="onSelectionChange($event)"
         dataKey="rowNumber"
         >
  <ng-template pTemplate="header">
    <tr>
      <th style="width: 3rem">
        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
      </th>
      <th pSortableColumn="rowNumber">#<p-sortIcon field="rowNumber"></p-sortIcon></th>
      <th pSortableColumn="yearMonth">Year month<p-sortIcon field="yearMonth"></p-sortIcon></th>
      <th pSortableColumn="supplierName">Supplier Name<p-sortIcon field="supplierName"></p-sortIcon></th>
      <th pSortableColumn="supplierId">Supplier Id<p-sortIcon field="supplierId"></p-sortIcon></th>
      <th pSortableColumn="postalCode">Postal code<p-sortIcon field="postalCode"></p-sortIcon></th>
      <th pSortableColumn="city">City<p-sortIcon field="city"></p-sortIcon></th>
      <th pSortableColumn="productCode">Product code<p-sortIcon field="productCode"></p-sortIcon></th>
      <th pSortableColumn="productId">Food id<p-sortIcon field="productId"></p-sortIcon></th>
      <th pSortableColumn="article">Article<p-sortIcon field="article"></p-sortIcon></th>
      <th pSortableColumn="unit">Unit<p-sortIcon field="unit"></p-sortIcon></th>
      <th pSortableColumn="quantity">Quantity<p-sortIcon field="quantity"></p-sortIcon></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-item let-index="rowIndex">
    <tr [class.good]="!item.recMovement.isLoading() && item.recMovement.value()"
        [class.modified]="item.isModified">
      <td>
        <p-tableCheckbox [value]="item"></p-tableCheckbox>
      </td>
      <td>
        <div class="flex align-items-center">
          <ng-container *ngIf="!item.recMovement.isLoading(); else loadingMovement">
            <i [class]="item.recMovement.value() ? 'pi pi-check text-green-500' : 'pi pi-times text-red-500'"
               [pTooltip]="item.recMovement.value() ? 'Movement exists' : 'No movement found'"
               class="mr-2">
            </i>
          </ng-container>
          <ng-template #loadingMovement>
            <i class="pi pi-spin pi-spinner mr-2"></i>
          </ng-template>
          {{ item.rowNumber }}
        </div>
      </td>
      <td>{{ item.yearMonth }}</td>
      <td>{{ item.supplierName }}</td>
      <td pEditableColumn pEditableColumnField="supplierId">
        <p-cellEditor>
          <ng-template pTemplate="input">
            <div class="flex align-items-center">
              <input pInputText type="text" [ngModel]="item.supplierId"
                     (ngModelChange)="onSupplierIdChange(item, $event)"
                     class="p-inputtext-sm w-8rem">
              <i [class]="item.supplierRef.value() ? 'pi pi-check text-green-500' : 'pi pi-times text-red-500'"
                 [pTooltip]="item.supplierRef.value() ? 'Supplier found' : 'Supplier not found'"
                 class="ml-2">
              </i>
              <i *ngIf="!item.supplierRef.value()" class="pi pi-plus cursor-pointer ml-2" pTooltip="Create missing supplier" tooltipPosition="top" (click)="onCreateSupplier(item)"></i>
            </div>
          </ng-template>
          <ng-template pTemplate="output">
            <div class="flex align-items-center editable">
              <ng-container *ngIf="item.supplierRef.value(); else missingSupplierRef">
                <foodbank-supplier [ngModel]="item.supplierRef.value()"
                                 [showId]="true"
                                 ngDefaultControl />
              </ng-container>
              <ng-template #missingSupplierRef>
                <ng-container *ngIf="item.supplierId; else emptySupplierRef">
                  <span>{{ item.supplierId }}</span>
                  <i class="pi pi-times text-red-500 ml-2"
                     pTooltip="Supplier not found">
                  </i>
                </ng-container>
                <ng-template #emptySupplierRef>
                  <span class="italic text-gray-500">empty</span>
                  <i class="pi pi-times text-red-500 ml-2"
                     pTooltip="Supplier not found">
                  </i>
                </ng-template>
              </ng-template>
              <i class="fa-regular fa-pen-to-square ml-2 edit-icon"
                 pTooltip="Click to edit">
              </i>
            </div>
          </ng-template>
        </p-cellEditor>
      </td>
      <td>{{ item.postalCode }}</td>
      <td>{{ item.city }}</td>
      <td>{{ item.productCode }}</td>
      <td pEditableColumn pEditableColumnField="productId">
        <p-cellEditor>
          <ng-template pTemplate="input">
            <div class="flex align-items-center">
              <input pInputText type="text" [ngModel]="item.productId"
                     (ngModelChange)="onFoodIdChange(item, $event)"
                     class="p-inputtext-sm w-8rem">
              <i [class]="item.articleRef.value() ? 'pi pi-check text-green-500' : 'pi pi-times text-red-500'"
                 [pTooltip]="item.articleRef.value() ? 'Article found' : 'Article not found'"
                 class="ml-2">
              </i>
            </div>
          </ng-template>
          <ng-template pTemplate="output">
            <div class="flex align-items-center editable">
              <foodbank-article *ngIf="item.articleRef.value(); else missingArticleRef"
                              [ngModel]="item.articleRef.value()"
                              [showId]="true"
                              ngDefaultControl />
              <ng-template #missingArticleRef>
                <ng-container *ngIf="item.productId; else emptyProductId">
                  <span>{{ item.productId }}</span>
                  <i class="pi pi-times text-red-500 ml-2"
                     pTooltip="Article not found">
                  </i>
                </ng-container>
                <ng-template #emptyProductId>
                  <span class="italic text-gray-500">empty</span>
                  <i class="pi pi-times text-red-500 ml-2"
                     pTooltip="Article not found">
                  </i>
                </ng-template>
              </ng-template>
              <i class="fa-regular fa-pen-to-square ml-2 edit-icon"
                 pTooltip="Click to edit">
              </i>
            </div>
          </ng-template>
        </p-cellEditor>
      </td>
      <td pEditableColumn pEditableColumnField="article">
        <p-cellEditor>
          <ng-template pTemplate="input">
            <input pInputText type="text" [ngModel]="item.article"
                   (ngModelChange)="onArticleChange(item, $event)"
                   class="p-inputtext-sm w-full">
          </ng-template>
          <ng-template pTemplate="output">
            <div class="flex align-items-center editable">
              <span>{{ item.article }}</span>
              <i class="fa-regular fa-pen-to-square ml-2 edit-icon"
                 pTooltip="Click to edit">
              </i>
            </div>
          </ng-template>
        </p-cellEditor>
      </td>
      <td>{{ item.unit }}</td>
      <td pEditableColumn pEditableColumnField="quantity">
        <p-cellEditor>
          <ng-template pTemplate="input">
            <input pInputText type="number" [ngModel]="item.quantity"
                   (ngModelChange)="onQuantityChange(item, $event)"
                   class="p-inputtext-sm w-8rem"
                   min="0">
          </ng-template>
          <ng-template pTemplate="output">
            <div class="editable">
              <foodbank-number [value]="item.quantity" [decimals]="3" />
              <i class="fa-regular fa-pen-to-square ml-2 edit-icon"
                 pTooltip="Click to edit">
              </i>
            </div>
          </ng-template>
        </p-cellEditor>
      </td>
    </tr>
  </ng-template>
</p-table>
