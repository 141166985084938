import {inject, Injectable, Injector} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {map, Observable, shareReplay} from 'rxjs';
import {Page} from '@typedefs/page';
import {environment} from '@environments/environment';
import {Pagination} from './pagination';
import {DonationDto, DonationSearchDto} from '@typedefs/stock-rest';
import {Donation} from "@model/donation";
import {DonationSearch} from "@model/search/donation-search";
import {copyCommonFields} from "@model/mapping-utils";
import {FoodbankCache} from "@services/foodabank-cache";
import {FoodbankCacheFactory} from "@services/foodabank-cache-factory";

@Injectable({
  providedIn: 'root'
})
export class DonationService {

  #httpClient = inject(HttpClient);
  #foodbankCacheFactory = inject(FoodbankCacheFactory);
  #injector = inject(Injector);

  public getDonations$(): Observable<Donation[]> {
    return this.#httpClient.get<Donation[]>(`${environment.apiUrl}/donations`)
      .pipe(shareReplay());
  }

  public getDonation$(id: string, cache = this.#foodbankCacheFactory.create(this.#injector)): Observable<Donation> {
    return this.#httpClient.get<DonationDto>(`${environment.apiUrl}/donations/${id}`)
      .pipe(
        map(donationDto => this.mapToDonation(donationDto, cache)),
        shareReplay(),
      );
  }

  public findDonations$(donationSearch: DonationSearch, injector: Injector, pagination: Pagination, cache = this.#foodbankCacheFactory.create(injector)): Observable<Page<Donation>> {
    const donationSearchDto = this.mapToDonationSearchDto(donationSearch);
    return this.#httpClient.post<Page<DonationDto>>(`${environment.apiUrl}/donations/search`, donationSearchDto, {params: pagination})
      .pipe(map(donationPage => this.loadDonationPage(donationPage, cache)));
  }

  private loadDonationPage(donationDtoPage: Page<DonationDto>, cache: FoodbankCache): Page<Donation> {
    return {
      ...donationDtoPage,
      content: this.loadDonationDetailsList(donationDtoPage.content, cache)
    };
  }

  public loadDonationDetailsList(donationDtos: DonationDto[], cache: FoodbankCache) {
    return donationDtos.map(donation => this.mapToDonation(donation, cache));
  }

  updateDonation(donation: Donation): Observable<Donation> {
    const donationDto = this.mapToDonationDto(donation);
    return this.#httpClient.put<DonationDto>(`${environment.apiUrl}/donations/${donation.id}`, donationDto)
      .pipe(map(donationDto => this.mapToDonation(donationDto, this.#foodbankCacheFactory.create(this.#injector))));
  }

  public mapToDonation(donationDto: DonationDto, cache: FoodbankCache): Donation {
    const commonFields: Donation | DonationDto = copyCommonFields(donationDto, ['companyId','donorId']);
    return {
      ...commonFields,
      company: cache.companyCache.get(donationDto.companyId),
      donor: cache.donorCache.get(donationDto.donorId),
    }
  }

  mapToDonationDto(donation: Donation): DonationDto {
    const donationDto: DonationDto = copyCommonFields(donation, ['company', 'donor']) as DonationDto;

    return {
      ...donationDto,
      companyId: donation.company.value()?.id!,
      donorId: donation.donor.value()?.id!,
    };
  }

  mapToDonationSearchDto(donationSearch: DonationSearch): DonationSearchDto {
    const donationSearchDto: DonationSearch | DonationSearchDto = copyCommonFields(donationSearch, ['company','donor']);
    return {
      ...donationSearchDto,
      companyId: donationSearch.company?.id,
      donorId: donationSearch.donor?.id,
    }
  }

}
