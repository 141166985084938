<p-table #dt
         [value]="(regionPage()?.content) || []"
         responsiveLayout="scroll"
         [rows]="DEFAULT_ROWS_PER_PAGE"
         [totalRecords]="regionPage()?.totalElements || 0"
         [globalFilterFields]="['depot']"
         [paginator]="true"
         [rowsPerPageOptions]="[15,25,50,100]"
         [showCurrentPageReport]="true"
         currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
         [lazy]="true"
         (onLazyLoad)="handleLazyLoad($event)"
         [rowHover]="true"
         [resizableColumns]="true"
         columnResizeMode="expand"
         [autoLayout]="true"
         sortMode="multiple"
         dataKey="id">
  <ng-template pTemplate="caption">
    <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h2 class="m-0">Regions</h2>
      <span class="report-block mt-2 md:mt-0 p-input-icon-left">
          <i class="pi pi-search"></i>
          <input pInputText type="text" placeholder="Search..." class="w-full sm:w-auto"/>
      </span>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr style="vertical-align: bottom">
      <th pSortableColumn="name">
        <span i18n>Name</span>
        <p-sortIcon field="regName" />
      </th>

    </tr>
    <tr>
      <th>
        <input pInputText type="text" [placeholder]="'Search...'" [(ngModel)]="regNameContains"  />
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-region>
    <ng-container *ngIf="identity(region) as region">
      <tr>
        <td>
          {{ region.regName }}
        </td>
      </tr>
    </ng-container>
  </ng-template>
</p-table>


