import {Component, inject, model} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {Donor} from '@model/donor';
import {DonorService} from '@services/donor.service';
import { NgIf} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {InputTextModule} from 'primeng/inputtext';
import {InputSwitchModule} from 'primeng/inputswitch';
import {Button} from 'primeng/button';
import {MessageService} from "primeng/api";
import {MessageModule} from "primeng/message";
import {DropdownModule} from "primeng/dropdown";


@Component({
  selector: 'foodbank-donor-edit',
  templateUrl: './donor-edit.component.html',
  styleUrls: ['./donor-edit.component.scss'],
  imports: [NgIf, FormsModule, InputTextModule,  InputSwitchModule, Button,  MessageModule, DropdownModule]
})
export class DonorEditComponent {

  donor = model<Donor>();

  ref = inject(DynamicDialogRef);
  config = inject(DynamicDialogConfig<Donor>);
  #donorService = inject(DonorService);
  #messageService = inject(MessageService);



  constructor() {
    this.donor.set(this.config.data);
    console.log( "editing donor:", this.donor());
  }

  save(donor: Donor) {


    this.#donorService.updateDonor(donor)
      .subscribe(donor => {
        this.donor.set(donor);
        this.ref.close(donor);
        this.#messageService.add({severity: 'success', summary: 'Success', detail:  `Donor ${donor.familyName} ${donor.firstName} updated`});
      });
  }

  close() {
    this.ref.close({});
  }

}


