import {Component, computed, effect, inject, Injector, input, model, Resource, Signal, WritableSignal} from '@angular/core'
import {Organization} from '@model/organization';
import {OrganizationService} from '@services/organization.service';
import {UserService} from "@services/user.service";
import {OrganizationSearch} from "@model/search/organization-search";
import {Pagination} from "@services/pagination";
import {DEFAULT_ROWS_PER_PAGE, PaginationService} from '@services/pagination.service';
import {Page} from "@typedefs/page";
import {DialogService, DynamicDialogRef} from "primeng/dynamicdialog";
import {TableLazyLoadEvent, TableModule} from "primeng/table";
import {RippleModule} from "primeng/ripple";
import {PrimeTemplate} from "primeng/api";
import {InputTextModule} from "primeng/inputtext";
import {FormsModule} from "@angular/forms";
import {NgIf} from "@angular/common";
import {OrganizationEditComponent} from "@components/organization/organization-edit/organization-edit.component";
import {ButtonModule} from "primeng/button";
import {overrideSignal} from "@util/foodbanks-signal-rxjs-interop";
import {MultiSelectModule} from "primeng/multiselect";
import {joinWhenPresent} from "@util/string-utils";
import {rxResource} from "@angular/core/rxjs-interop";
import {WarehouseComponent} from "@components/warehouse/warehouse.component";

type ColumnConfig<T> = {
  field: keyof Required<T>;
  label: string;
  visible: boolean;
}

type ColumnsConfig<T> = {
  [K in keyof Partial<T>]: ColumnConfig<T>;
}

type OrganizationColumnsConfig = ColumnsConfig<Organization>
const FOODBANK_PREFERENCES_ORGANIZATIONS_LIST_COLUMNS = 'FOODBANK_PREFERENCES_ORGANIZATIONS_LIST_COLUMNS';

@Component({
  selector: 'foodbank-organizations',
  templateUrl: './organization-list.component.html',
  styleUrls: ['./organization-list.component.scss'],
  providers: [DialogService],
  imports: [TableModule, PrimeTemplate, InputTextModule, FormsModule, NgIf, ButtonModule, RippleModule, MultiSelectModule, WarehouseComponent]

})

export class OrganizationListComponent {

  static readonly DEFAULT_COLUMN_CONFIG: OrganizationColumnsConfig = {
    address: {label: 'Address', field: 'address', visible: false},
    phone: {label: 'Phone', field: 'phone', visible: true},
    email: {label: 'Email', field: 'email', visible: true},
    zip: {label: 'Zip', field: 'zip', visible: false},
    city: {label: 'City', field: 'city', visible: false},
    warehouse: {label: 'Warehouse', field: 'warehouse', visible: false},
  };

  // selected search filters for this view
  filterNameContains = model('');
  filterZipContains = model('');
  filterCityContains = model('');

  // this view search
  organizationSearch: Signal<OrganizationSearch>;
  pagination: WritableSignal<Pagination>;

  // results
  organizationPage: Resource<Page<Organization> | undefined>;

  // internal state
  #dialogRef?: DynamicDialogRef;

  // services
  #organizationService = inject(OrganizationService);
  #paginationService = inject(PaginationService);
  #userService = inject(UserService);
  #dialogService = inject(DialogService);
  #injector = inject(Injector);
// columns view selection
  showColumnConfig = input(true);
  allColumnsWithDefaultConfig: Signal<ColumnConfig<Organization>[]>;
  selectedColumns: Signal<ColumnConfig<Organization>[]>;
  columnsConfig: Signal<OrganizationColumnsConfig>;

  constructor() {
    const currentUser = this.#userService.getCurrentUser();
    const currentUserCompany = this.#userService.getCurrentUserCompany();
    const userPreferencesForOrganizationListColumns = this.#userService.getCurrentUserPreferences<OrganizationColumnsConfig>(FOODBANK_PREFERENCES_ORGANIZATIONS_LIST_COLUMNS, OrganizationListComponent.DEFAULT_COLUMN_CONFIG);
    this.allColumnsWithDefaultConfig = computed(() => Object.values(OrganizationListComponent.DEFAULT_COLUMN_CONFIG));
    const organizationListColumnsFromPreferences = computed(() => this.showColumnConfig()
      ? Object.values(userPreferencesForOrganizationListColumns()).filter(column => column.visible)
      : this.allColumnsWithDefaultConfig()
    )
    this.selectedColumns = overrideSignal(organizationListColumnsFromPreferences)

    // update config based on selected columns to display
    this.columnsConfig = computed<OrganizationColumnsConfig>(() => this.allColumnsWithDefaultConfig().reduce((acc, column) => {
      column.visible = this.selectedColumns().some(selectedColumn => selectedColumn.field === column.field);
      return {...acc, [column.field]: column};
    }, {} as OrganizationColumnsConfig));

    effect(() => {
      if (currentUser()) {
        // could probably moved into a function called onChange by multiSelect
        this.#userService.setUserPreferences(currentUser()!, FOODBANK_PREFERENCES_ORGANIZATIONS_LIST_COLUMNS, this.columnsConfig());
      }
    });

    this.organizationSearch = computed(() => ({
      company: currentUserCompany(),
      name: this.filterNameContains(),
      zip: this.filterZipContains(),
      city: this.filterCityContains(),
    }));

    this.pagination = this.#paginationService.getDefaultPaginationSignal(DEFAULT_ROWS_PER_PAGE);

    this.organizationPage = rxResource({
      request: () => ({
        organizationSearch: this.organizationSearch(),
        pagination: this.pagination(),
      }),
      loader: param => this.#organizationService.findOrganizations$(param.request.organizationSearch, param.request.pagination),
      injector: this.#injector,
    });
  }

  handleLazyLoad(event: TableLazyLoadEvent) {
    const pagination = this.#paginationService.getTablePagination(event);
    this.pagination.set(pagination);
  }

  protected readonly DEFAULT_ROWS_PER_PAGE = DEFAULT_ROWS_PER_PAGE;

  identity(organization: Organization): Organization {
    return organization;
  }

  generateTelGsm(organization: Organization) {
    let telgsm = "";
    if (organization.phone && organization.phone != "") {
      telgsm += organization.phone.trim() + " ";
    }
    if (organization.mobile && organization.mobile != "") {
      telgsm += organization.mobile.trim() + " ";
    }
    return telgsm;

  }

  openEditDialog(organization: Organization) {
    this.#dialogRef = this.#dialogService.open(OrganizationEditComponent, {
      header: 'Edit Organization',
      width: '40%',
      data: {...organization}
    });
    this.#dialogRef?.onClose.subscribe(() => {
      this.organizationPage.reload()
    })
  }

  getAddress(organization: Organization) {
    const location = joinWhenPresent(" ", [organization.zip, organization.city]);
    return joinWhenPresent(" ,", [organization.address, location]);
  }
}


