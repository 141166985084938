import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AppLayoutComponent} from '@components/layout/app.layout.component';
import {UserListComponent} from '@components/users/user-list.component';
import {StockListComponent} from '@components/stock/stock-list.component';
import {ArticleListComponent} from '@components/article/article-list.component';
import {SupplierListComponent} from '@components/supplier/supplier-list.component';
import {FeadPlanningSummaryComponent} from '@components/fead-planning/summary/fead-planning-summary.component';
import {FeadPlanningOrganizationComponent} from '@components/fead-planning/organization/fead-planning-organization.component';
import {FeadPlanningArticleComponent} from '@components/fead-planning/article/fead-planning-article.component';
import {FeadPreparationListComponent} from '@components/fead/preparation/list/fead-preparation-list.component';
import {FeadPlanningComponent} from '@components/fead/planning/fead-planning.component';
import {FeadPreparationNewComponent} from '@components/fead/preparation/new/fead-preparation-new.component';
import {ReceptionListComponent} from '@components/reception/reception-list/reception-list.component';
import {FeadPreparationDetailsStandaloneComponent} from '@components/fead/preparation/detail/fead-preparation-details-standalone.component';
import {InterWarehouseReceptionComponent} from '@components/inter-warehouse-reception/inter-warehouse-reception.component';
import {PreparationNewComponent} from '@components/preparation/new/preparation-new.component';
import {TransferRequestListComponent} from "@components/transfer/request/transfer-request-list.component";
import {MovementListComponent} from "@components/movement/movement-list/movement-list.component";
import {StockGroupListComponent} from "@components/stock/group/stock-group-list.component";
import {TransferRequestEditPageComponent} from "@components/transfer/request/transfer-request-page.component";
import {MembersComponent} from "@components/member/member-list.component";
import {RolesComponent} from "@components/role/role-list.component";
import {OrganizationListComponent} from "@components/organization/organization-list.component";
import {BulkFoodImportComponent} from "@components/bulk-food-import/bulk-food-import.component";
import {RegionListComponent} from "@components/region/region-list.component";
import {CpasListComponent} from "@components/cpas/cpas-list.component";
import {DonationListComponent} from "@components/donation/donation-list.component";
import {DonorListComponent} from "@components/donor/donor-list.component";

const routes: Routes = [
  {
    path: '',
    component: AppLayoutComponent
  },
  {
    path: 'users/list',
    component: UserListComponent
  },
  {
    path: 'organizations/list',
    component: OrganizationListComponent
  },
  {
    path: 'regions/list',
    component: RegionListComponent
  },
  {
    path: 'cpass/list',
    component: CpasListComponent
  },
  {
    path: 'fead/planning/summary',
    component: FeadPlanningSummaryComponent
  },
  {
    path: 'fead/planning/organization',
    component: FeadPlanningOrganizationComponent
  },
  {
    path: 'fead/planning/article',
    component: FeadPlanningArticleComponent
  },
  {
    path: 'fead/planning',
    component: FeadPlanningComponent
  },
  {
    path: 'fead/preparation/list',
    component: FeadPreparationListComponent,
    data: {
      fead: true
    }
  },
  {
    path: 'fead/expedition/list',
    component: FeadPreparationListComponent,
    data: {
      expedition: true,
    }
  },
  {
    path: 'fead/preparation/new',
    component: FeadPreparationNewComponent,
  },
  {
    path: 'fead/preparation/:preparationId',
    component: FeadPreparationDetailsStandaloneComponent
  },
  {
    path: 'receptions/manual',
    component: ReceptionListComponent
  },
  {
    path: 'receptions/inter-warehouse',
    component: InterWarehouseReceptionComponent
  },
  {
    path: 'preparation/list',
    component: FeadPreparationListComponent,
    data: {
      fead: false
    }
  },
  {
    path: 'preparation/new',
    component: PreparationNewComponent,
  },
  {
    path: 'stocks/list',
    component: StockListComponent,
    data: {
      bulkMovementTypeIsReadOnly: false,
      canExitBulkMovementMode: true
    }
  },
  {
    path: 'stocks/groups/list',
    component: StockGroupListComponent,
    data: {}
  },
  {
    path: 'stocks/bulk/interwarehouse-transfer',
    component: StockListComponent,
    data: {
      bulkMovementTypeIsReadOnly: true,
      canExitBulkMovementMode: false,
      bulkMovementActive: true,
      bulkMovementKind: 'INTER_WAREHOUSE_TRANSFER',
    }
  },
  {
    path: 'articles/list',
    component: ArticleListComponent,
    data: {
      canExitBulkMovementMode: true
    }
  },
  {
    path: 'articles/bulk/bulkfood',
    component: ArticleListComponent,
    data: {
      canExitBulkMovementMode: false,
      bulkMovementActive: true,
      bulkMovementKind: 'RECEIPT',
      bulkMovementCategory: 'BULK_FOOD'
    }
  },
  {
    path: 'suppliers/list',
    component: SupplierListComponent
  },
  {
    path: 'members/list',
    component: MembersComponent
  },
  {
    path: 'roles/list',
    component: RolesComponent
  },
  {
    path: 'donations/list',
    component: DonationListComponent
  },
  {
    path: 'donors/list',
    component: DonorListComponent
  },
  {
    path: 'movements/list',
    component: MovementListComponent,
    data: {
      filter: true,
      showColumnConfig: true
    }
  },
  {
    path: 'transfers/requests',
    component: TransferRequestListComponent
  },
  {
    path: 'transfers/requests/:id',
    component: TransferRequestEditPageComponent
  },
  {
    path: 'movements/bulk/bulkfoodimport',
    component: BulkFoodImportComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
        bindToComponentInputs: true
      }
    )],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
