import {inject, Injectable, Injector} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {OrganizationDto, OrganizationSearchDto, SupplyWeek} from '@typedefs/stock-rest';
import {BehaviorSubject, map, mergeMap, Observable, shareReplay, take} from 'rxjs';
import {environment} from '@environments/environment';
import {Organization} from '@model/organization';
import {Page} from '@typedefs/page';
import {OrganizationSearch} from '@model/search/organization-search';
import {Pagination} from '@services/pagination';
import {PreparationItemService} from '@services/preparation-item.service';
import {copyCommonFields} from "@model/mapping-utils";
import {FoodbankCacheFactory} from "@services/foodabank-cache-factory";
import {FoodbankCache} from "@services/foodabank-cache";

type OrganizationPagination = Pagination;

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {

  private invalidateCache$ = new BehaviorSubject<void>(undefined);
  cacheById: { [key: number]: Observable<Organization> } = {};

  #httpClient = inject(HttpClient);
  #foodbankCacheFactory = inject(FoodbankCacheFactory);
  #injector = inject(Injector);

  getOrganization$(id: number, cache = this.#foodbankCacheFactory.create(this.#injector)): Observable<Organization> {
    if (!this.cacheById[id]) {
      this.cacheById[id] = this.invalidateCache$.pipe(
        mergeMap(() => this.#httpClient.get<OrganizationDto>(`${environment.apiUrl}/organizations/${id}`)),
        map(organization => this.mapToOrganization(organization, cache)),
        take(1), // this fixes some circular dependency issues when serializing, really strange!!!
        shareReplay(1)
      );
    }

    return this.cacheById[id];
  }

  findOrganizations$(organizationSearch: OrganizationSearch, pagination: OrganizationPagination, cache = this.#foodbankCacheFactory.create(this.#injector)): Observable<Page<Organization>> {
    const organizationSearchDto = this.mapToOrganizationSearchDto(organizationSearch);
    return this.#httpClient.post<Page<OrganizationDto>>(`${environment.apiUrl}/organizations/search`, organizationSearchDto, {params: pagination})
      .pipe(
        map(page => {
          const organizations: Organization[] = page.content.map(organizationDto => this.mapToOrganization(organizationDto, cache));
          return {
            ...page,
            content: organizations
          }
        })
      );
  }

  #isPreferredWeek(preferredSupplyWeek: SupplyWeek, week: string): boolean {
    const [yearStr, weekOfYearStr] = week.split("/");
    const year = parseInt(yearStr);
    const weekOfYear = parseInt(weekOfYearStr);

    if (weekOfYear % 2 == 0 && preferredSupplyWeek === 'WEEKS_EVEN') {
      return true;
    }

    if (weekOfYear % 2 == 1 && preferredSupplyWeek === 'WEEKS_UNEVEN') {
      return true;
    }

    const weekOfMonth = this.#getWeekOfMonth(year, weekOfYear);
    switch (weekOfMonth) {
      case 1:
        return !['WEEK_2', 'WEEK_3', 'WEEK_4', 'WEEK_24', 'WEEK_34', 'WEEK_23'].includes(preferredSupplyWeek);
      case 2:
        return !['WEEK_1', 'WEEK_3', 'WEEK_4', 'WEEK_13', 'WEEK_34', 'WEEK_14'].includes(preferredSupplyWeek);
      case 3:
        return !['WEEK_1', 'WEEK_2', 'WEEK_4', 'WEEK_24', 'WEEK_12', 'WEEK_14'].includes(preferredSupplyWeek);
      case 4:
        return !['WEEK_1', 'WEEK_2', 'WEEK_3', 'WEEK_13', 'WEEK_12', 'WEEK_23'].includes(preferredSupplyWeek);
      case 5:
        return !['WEEKS_EVEN', 'WEEKS_UNEVEN'].includes(preferredSupplyWeek);
      default:
        console.error(`Error while parsing week: unexpected value ${week}`);
        return true;
    }
  }

  #getWeekOfMonth(year: number, weekOfYear: number): number {
    // Create a Date object for the first day of the given year
    const firstDayOfYear = new Date(year, 0, 1);
    // Adjust based on the day of the week of the first day of the year
    const daysToAdjust = (firstDayOfYear.getDay() === 6) ? -6 : 1 - firstDayOfYear.getDay();

    firstDayOfYear.setDate(firstDayOfYear.getDate() + weekOfYear * 7 + daysToAdjust);

    // Get the week number within the month
    return Math.ceil(firstDayOfYear.getDate() / 7);
  }

  mapToOrganizationSearchDto(organizationSearch: OrganizationSearch): OrganizationSearchDto {
    const preparationItemService = this.#injector.get(PreparationItemService);
    return {
      name: organizationSearch.name,
      zip: organizationSearch.zip,
      city: organizationSearch.city,
      active: organizationSearch.active,
      bigBirbCode: organizationSearch.bigBirbCode,
      birbCode: organizationSearch.birbCode,
      warehouseEnabled: organizationSearch.warehouseEnabled,
      feadEnabled: organizationSearch.feadEnabled,
      bankId: organizationSearch?.bank?.id,
      companyId: organizationSearch?.company?.id,
      warehouseId: organizationSearch?.warehouse?.id,
      pickupWarehouseId: organizationSearch?.pickupWarehouse?.id,
      preparationItemSearchDto: organizationSearch.preparationItemSearch && preparationItemService.mapToPreparationItemSearchDto(organizationSearch.preparationItemSearch)
    }
  }

  updateOrganization(organization: Organization, cache = this.#foodbankCacheFactory.create(this.#injector)): Observable<Organization> {
    const organizationDto = this.mapToOrganizationDto(organization);
    return this.#httpClient.put<OrganizationDto>(`${environment.apiUrl}/organizations/${organization.id}`, organizationDto)
      .pipe(map(organizationDto => this.mapToOrganization(organizationDto, cache)));
  }

  mapToOrganization(organizationDto: OrganizationDto, cache: FoodbankCache): Organization {
    const commonFields: Organization | OrganizationDto = copyCommonFields(organizationDto, []);
    return {
      ...commonFields,
      warehouse: cache.warehouseCache.get(organizationDto.warehouseId),
      // company: cache.companyCache.get(organizationDto.companyId),
      preferredWeekForDelivery: (week: string) => this.#isPreferredWeek(organizationDto.preferredSupplyWeek, week)
    }
  }

  mapToOrganizationDto(organization: Organization): OrganizationDto {
    const commonFields: Organization | OrganizationDto = copyCommonFields(organization, ['warehouse'/*, 'company'*/]);
    return {
      ...commonFields,
      // companyId: organization.company.value()?.id!,
      warehouseId: organization.warehouse.value()?.id!,
    };
  }

}
