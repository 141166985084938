import {Component, computed, inject, model, Signal} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {Organization} from '@model/organization';
import {OrganizationService} from '@services/organization.service';
import {FormsModule} from '@angular/forms';
import {TabViewModule} from 'primeng/tabview';
import {InputTextModule} from 'primeng/inputtext';
import {InputSwitchModule} from 'primeng/inputswitch';
import {Button} from 'primeng/button';
import {NgIf} from "@angular/common";
import {UserService} from "@services/user.service";
import {User} from "@model/user";
import {MessageService} from "primeng/api";
import {DropdownModule} from "primeng/dropdown";
import {WarehouseSearch} from "@model/search/warehouse-search";
import {toSignal} from "@angular/core/rxjs-interop";
import {WarehouseSingleSelectionComponent} from "@components/warehouse/selection/single/warehouse-single-selection.component";
import {WarehouseService} from "@services/warehouse.service";

@Component({
  selector: 'foodbank-organization-edit',
  templateUrl: './organization-edit.component.html',
  styleUrls: ['./organization-edit.component.scss'],
  imports: [FormsModule, InputTextModule, InputSwitchModule, Button, NgIf, DropdownModule, TabViewModule, WarehouseSingleSelectionComponent,]
})
export class OrganizationEditComponent {

  organization = model<Organization>();

  ref = inject(DynamicDialogRef);
  config = inject(DynamicDialogConfig<Organization>);
  #organizationService = inject(OrganizationService);
  #warehouseService = inject(WarehouseService);
  #messageService = inject(MessageService);
  #userService = inject(UserService);
  currentUser: Signal<User | undefined>;
  genders: any[];

  warehouseSearch: Signal<WarehouseSearch>;

  constructor() {
    this.organization.set(this.config.data);
    this.currentUser = this.#userService.getCurrentUser();
    const company$ = this.#userService.getCurrentUserCompany$();
    const company = toSignal(company$);

    this.warehouseSearch = computed<WarehouseSearch>(() => ({
        company: company(),
        active: true,
      })
    );
    console.log("Enter edit for Organisation", this.organization());

    this.genders = [{label: $localize`:@@PersonTitleNone:None.`, value: null},
      {label: $localize`:@@PersonTitleMister:Mr.`, value: 'MR'},
      {label: $localize`:@@PersonTitleLady:Mrs.`, value: 'MRS'},
      {label: $localize`:@@PersonTitleMiss:Miss`, value: 'MISS'}
    ];

  }

  save(organization: Organization) {
    console.log("Enter save for Organisation", organization);
    organization.lastUpdateUserName = this.currentUser()!.name;
    this.#organizationService.updateOrganization(organization)
      .subscribe(organization => {
        this.organization.set(organization);
        this.ref.close(organization)
        this.#messageService.add({severity: 'success', summary: 'Success', detail: 'Organization updated'});
      });
  }

  close() {
    this.ref.close({});
  }

}
